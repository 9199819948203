import {
	CLEAR_FEED,
	DELETE_FEED,
	DELETE_FEED_POINT,
	LOAD_FEED,
	LOAD_FEED_ATTRIBUTES,
	LOAD_FEED_LIST,
	RESET_FEED,
	UPDATE_FEED_POINT,
} from 'reduxStore/actions/feedActions';
import { shapeMessage } from 'util/openNotification';

const initialState = {
	loading: false,
	attrLoading: false,
	metrics: [],
	message: null,
	feedAttr: null,
	feedList: null,
	CUDmessage: null,
	CUDloading: false,

	fpLoading: false,
	fpMessage: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOAD_FEED_ATTRIBUTES.REQUEST:
			return {
				...state,
				attrLoading: true,
				message: null,
			};
		case LOAD_FEED.REQUEST:
		case LOAD_FEED_LIST.REQUEST:
			return {
				...state,
				loading: true,
				message: null,
			};

		case LOAD_FEED.SUCCESS:
			state.metrics = action.payload.feed.data;
			const newMetrics = state.metrics;
			return {
				...state,
				metrics: newMetrics,
				loading: false,
				message: null,
			};
		case LOAD_FEED.FAILURE:
		case LOAD_FEED_LIST.FAILURE:
			return {
				...state,
				loading: false,
				message: shapeMessage(action.type, action.payload.error),
			};
		case LOAD_FEED_ATTRIBUTES.FAILURE:
			return {
				...state,
				attrLoading: false,
				message: shapeMessage(action.type, action.payload.error),
			};
		case LOAD_FEED_LIST.SUCCESS:
			return {
				...state,
				feedList: action.payload.feedList,
				loading: false,
				message: null,
			};
		case LOAD_FEED_ATTRIBUTES.SUCCESS:
			return {
				...state,
				feedAttr: action.payload.feedAttr,
				attrLoading: false,
				message: null,
			};

		case DELETE_FEED.REQUEST:
		case CLEAR_FEED.REQUEST:
			return {
				...state,
				CUDloading: true,
				CUDmessage: null,
			};
		case DELETE_FEED.SUCCESS:
		case CLEAR_FEED.SUCCESS:
			return {
				...state,
				CUDloading: false,
				CUDmessage: shapeMessage(action.type),
			};
		case DELETE_FEED.FAILURE:
		case CLEAR_FEED.FAILURE:
			return {
				...state,
				CUDloading: false,
				CUDmessage: shapeMessage(action.type, action.payload.error),
			};

		case DELETE_FEED_POINT.REQUEST:
			return {
				...state,
				fpLoading: true,
			};
		case DELETE_FEED_POINT.SUCCESS:
			return {
				...state,
				fpLoading: false,
				// feedList: action.payload.feedList,
				fpMessage: shapeMessage(action.type),
			};
		case DELETE_FEED_POINT.FAILURE:
			return {
				...state,
				fpLoading: false,
				fpMessage: shapeMessage(action.type, action.payload.error),
			};

		case UPDATE_FEED_POINT.REQUEST:
			return {
				...state,
				fpLoading: true,
			};
		case UPDATE_FEED_POINT.SUCCESS:
			return {
				...state,
				fpLoading: false,
				fpMessage: shapeMessage(action.type),
			};
		case UPDATE_FEED_POINT.FAILURE:
			return {
				...state,
				fpLoading: false,
				fpMessage: shapeMessage(action.type, action.payload.error),
			};
		case RESET_FEED.REQUEST:
			return {
				...state,
			};
		case RESET_FEED.SUCCESS:
			return {
				...state,
				feedList: action.payload.feedData,
				// fpMessage: shapeMessage(action.type),
			};
		case RESET_FEED.FAILURE:
			return {
				...state,
				fpMessage: shapeMessage(action.type, action.payload.error),
			};
		default:
			return state;
	}
};
