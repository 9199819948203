import {
	LOAD_DEVICES,
	LOAD_DEVICE,
	CREATE_DEVICE,
	UPDATE_DEVICE,
	DELETE_DEVICE,
	LOAD_DEVICE_TOKEN,
	MOVE_DEVICE,
	LOAD_DEVICES_BY_GROUP,
	UNGROUP_DEVICE,
	MANAGE_DEVICE_GROUP,
	RESYNC_DEVICE_STATUS,
	ENABLE_DEVICE,
	DISABLE_DEVICE,
} from '../actions/deviceActions';
import { shapeMessage } from 'util/openNotification';

const initialState = {
	devices: [],
	devicesLoading: false,
	devicesMessage: null,

	devicesInGroup: [],

	device: null,
	deviceToken: null,
	loading: false,
	message: null,
	deviceCUDLoading: false,
	syncing: false,

	moveMessage: null,
	moveLoading: false,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOAD_DEVICES.REQUEST:
			return {
				...state,
				devicesLoading: true,
				devicesMessage: null,
			};
		case LOAD_DEVICES.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				devicesLoading: false,
				devicesMessage: null,
			};
		case LOAD_DEVICES.FAILURE:
			return {
				...state,
				devicesLoading: false,
				devicesMessage: shapeMessage(action.type, action.payload.error),
			};
		/*------------------------------------------------------------*/
		case LOAD_DEVICE.REQUEST:
			return {
				...state,
				message: null,
				loading: true,
			};
		case LOAD_DEVICE.SUCCESS:
			return {
				...state,
				device: action.payload.device,
				message: null,
				loading: false,
			};
		case LOAD_DEVICE.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				loading: false,
			};
		/*------------------------------------------------------------*/
		case LOAD_DEVICE_TOKEN.REQUEST:
			return {
				...state,
				message: null,
				loading: true,
			};
		case LOAD_DEVICE_TOKEN.SUCCESS:
			return {
				...state,
				device: action.payload.device.device,
				message: null,
				loading: false,
			};
		case LOAD_DEVICE_TOKEN.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				loading: false,
			};
		/*------------------------------------------------------------*/
		case CREATE_DEVICE.REQUEST:
			return {
				...state,
				message: null,
				deviceCUDLoading: true,
			};
		case CREATE_DEVICE.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				device: action.payload.device,
				message: shapeMessage(action.type),
				deviceCUDLoading: false,
			};
		case CREATE_DEVICE.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				deviceCUDLoading: false,
			};
		/*------------------------------------------------------------*/
		case UPDATE_DEVICE.REQUEST:
			return {
				...state,
				message: null,
				deviceCUDLoading: true,
			};
		case UPDATE_DEVICE.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				devicesInGroup: action.payload.devicesInGroup,
				device: action.payload.device,
				message: shapeMessage(action.type),
				deviceCUDLoading: false,
			};
		case UPDATE_DEVICE.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				deviceCUDLoading: false,
			};
		/*------------------------------------------------------------*/
		case DELETE_DEVICE.REQUEST:
			return {
				...state,
				message: null,
				deviceCUDLoading: true,
			};
		case DELETE_DEVICE.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				device: action.payload.device,
				devicesInGroup: action.payload.devicesInGroup,
				message: shapeMessage(action.type),
				deviceCUDLoading: false,
			};
		case DELETE_DEVICE.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				deviceCUDLoading: false,
			};
		/*------------------------------------------------------------*/
		case MOVE_DEVICE.REQUEST:
			return {
				...state,
				moveMessage: null,
				moveLoading: true,
			};
		case MOVE_DEVICE.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				devicesInGroup: action.payload.devicesInGroup,
				moveMessage: shapeMessage(action.type),
				moveLoading: false,
			};
		case MOVE_DEVICE.FAILURE:
			return {
				...state,
				moveMessage: shapeMessage(action.type, action.payload.error),
				moveLoading: false,
			};
		case UNGROUP_DEVICE.REQUEST:
			return {
				...state,
				moveMessage: null,
				moveLoading: true,
			};
		case UNGROUP_DEVICE.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				devicesInGroup: action.payload.devicesInGroup,
				moveMessage: shapeMessage(action.type),
				moveLoading: false,
			};
		case UNGROUP_DEVICE.FAILURE:
			return {
				...state,
				moveMessage: shapeMessage(action.type, action.payload.error),
				moveLoading: false,
			};
		case MANAGE_DEVICE_GROUP.REQUEST:
			return {
				...state,
				moveMessage: null,
				moveLoading: true,
			};
		case MANAGE_DEVICE_GROUP.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				devicesInGroup: action.payload.devicesInGroup,
				moveMessage: shapeMessage(action.type),
				moveLoading: false,
			};
		case MANAGE_DEVICE_GROUP.FAILURE:
			return {
				...state,
				moveMessage: shapeMessage(action.type, action.payload.error),
				moveLoading: false,
			};
		case LOAD_DEVICES_BY_GROUP.REQUEST:
			return {
				...state,
				devicesMessage: null,
				devicesLoading: true,
			};
		case LOAD_DEVICES_BY_GROUP.SUCCESS:
			return {
				...state,
				devices: action.payload.devices,
				devicesInGroup: action.payload.devicesInGroup,
				devicesMessage: shapeMessage(action.type),
				devicesLoading: false,
			};
		case LOAD_DEVICES_BY_GROUP.FAILURE:
			return {
				...state,
				devicesMessage: shapeMessage(action.type, action.payload.error),
				devicesLoading: false,
			};
		case RESYNC_DEVICE_STATUS.REQUEST:
			return {
				...state,
				syncing: true,
			};
		case RESYNC_DEVICE_STATUS.SUCCESS:
			return {
				...state,
				message: shapeMessage(action.type),
				device: { ...state.device, ...action.payload.device },
				syncing: false,
			};
		case RESYNC_DEVICE_STATUS.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				syncing: false,
			};
		case ENABLE_DEVICE.REQUEST:
			return {
				...state,
			};
		case ENABLE_DEVICE.SUCCESS:
			return {
				...state,
				device: { ...state.device, ...action.payload.device },
				message: shapeMessage(action.type),
			};
		case ENABLE_DEVICE.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
			};
		case DISABLE_DEVICE.REQUEST:
			return {
				...state,
			};
		case DISABLE_DEVICE.SUCCESS:
			return {
				...state,
				device: { ...state.device, ...action.payload.device },
				message: shapeMessage(action.type),
			};
		case DISABLE_DEVICE.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
			};

		default:
			return state;
	}
};
