import gql from 'graphql-tag';

const DEVICE_FRAGMENT = gql`
	fragment deviceInfo on Device {
		alias
		createdtime
		credential {
			secret
		}
		description
		deviceid
		groupid
		groupname
		projectid
		status
		tag
		tags
		enabled
		banned
		hashtag
	}
`;

const DEVICE_ENABLE_STATUS_FRAGMENT = gql`
	fragment deviceEnableStatus on Device {
		enabled
	}
`;

const DEVICE_LIST_STATUS_FRAGMENT = gql`
	fragment deviceStatusInfo on Device {
		deviceid
		status
	}
`;

const DEVICE_WITH_TOKEN_FRAGMENT = gql`
	fragment deviceWithToken on DeviceWithToken {
		alias
		createdtime
		credential {
			secret
		}
		description
		deviceid
		groupid
		groupname
		projectid
		status
		tag
		tags
		hashtag
		enabled
		banned
		token {
			tokencode
			iat
			nbf
			exp
			expireIn
		}
		devicetoken
	}
`;

const DEVICE_STATUS_FRAGMENT = gql`
	fragment result on Result {
		code
		text
	}
`;

const QUERY_DEVICE_LIST = gql`
	query _deviceList($filter: DeviceFilterKey, $limit: Int, $offset: Int) {
		deviceList(filter: $filter, limit: $limit, offset: $offset, orderBy: createdtime) {
			...deviceInfo
		}
	}
	${DEVICE_FRAGMENT}
`;

const QUERY_DEVICE_LIST_STATUS = gql`
	query _deviceListStatus($filter: DeviceFilterKey, $limit: Int, $offset: Int) {
		deviceList(filter: $filter, limit: $limit, offset: $offset, orderBy: createdtime) {
			...deviceStatusInfo
		}
	}
	${DEVICE_LIST_STATUS_FRAGMENT}
`;

const QUERY_DEVICE = gql`
	query _device($deviceid: String!) {
		device(deviceid: $deviceid) {
			...deviceWithToken
		}
	}
	${DEVICE_WITH_TOKEN_FRAGMENT}
`;

const QUERY_DEVICE_WITH_DEVICE_TOKEN = gql`
	query _deviceWithDeviceToken($deviceid: String!) {
		device(deviceid: $deviceid) {
			...deviceWithToken
		}
	}
	${DEVICE_WITH_TOKEN_FRAGMENT}
`;

const CREATE_DEVICE = gql`
	mutation _createDevice($projectid: String!, $deviceid: String, $deviceinfo: DeviceInfo) {
		createDevice(projectid: $projectid, deviceid: $deviceid, deviceinfo: $deviceinfo) {
			...deviceWithToken
		}
	}
	${DEVICE_WITH_TOKEN_FRAGMENT}
`;

const UPDATE_DEVICE = gql`
	mutation _updateDevice($deviceid: String!, $deviceinfo: DeviceInfo) {
		updateDevice(deviceid: $deviceid, deviceinfo: $deviceinfo) {
			...deviceInfo
		}
	}
	${DEVICE_FRAGMENT}
`;

const DELETE_DEVICE = gql`
	mutation _deleteDevice($deviceid: String!) {
		deleteDevice(deviceid: $deviceid) {
			...deviceInfo
		}
	}
	${DEVICE_FRAGMENT}
`;

const GROUP_DEVICE = gql`
	mutation _groupDevice($deviceid: [String]!, $groupid: String!) {
		groupDevice(deviceid: $deviceid, groupid: $groupid) {
			...deviceInfo
		}
	}
	${DEVICE_FRAGMENT}
`;

const UNGROUP_DEVICE = gql`
	mutation _ungroupDevice($deviceid: [String]!) {
		ungroupDevice(deviceid: $deviceid) {
			...deviceInfo
		}
	}
	${DEVICE_FRAGMENT}
`;

const RESYNC_DEVICE_STATUS = gql`
	mutation _resyncDeviceStatus($deviceid: String!) {
		resyncDeviceStatus(deviceid: $deviceid) {
			...result
		}
	}
	${DEVICE_STATUS_FRAGMENT}
`;

const ENABLE_DEVICE = gql`
	mutation _enableDevice($deviceid: String!) {
		enableDevice(deviceid: $deviceid) {
			...deviceEnableStatus
		}
	}
	${DEVICE_ENABLE_STATUS_FRAGMENT}
`;

const DISABLE_DEVICE = gql`
	mutation _disableDevice($deviceid: String!) {
		disableDevice(deviceid: $deviceid) {
			...deviceEnableStatus
		}
	}
	${DEVICE_ENABLE_STATUS_FRAGMENT}
`;

export {
	QUERY_DEVICE_LIST,
	QUERY_DEVICE_LIST_STATUS,
	QUERY_DEVICE,
	QUERY_DEVICE_WITH_DEVICE_TOKEN,
	CREATE_DEVICE,
	UPDATE_DEVICE,
	DELETE_DEVICE,
	GROUP_DEVICE,
	UNGROUP_DEVICE,
	RESYNC_DEVICE_STATUS,
	ENABLE_DEVICE,
	DISABLE_DEVICE,
};
