import gql from 'graphql-tag';

const FLOWAGENT_FRAGMENT = gql`
	fragment flowagent on FlowAgent {
		alias
		instance
		active
		createdtime
		credential {
			secret
		}
		description
		flowagentid
		projectid
		status
	}
`;

const QUERY_FLOWAGENT_LIST = gql`
	query _flowagentList($projectid: String!) {
		flowagentList(filter: { projectid: $projectid }) {
			...flowagent
		}
	}
	${FLOWAGENT_FRAGMENT}
`;

const QUERY_FLOWAGENT = gql`
	query _flowagent($flowagentid: String!) {
		flowagent(flowagentid: $flowagentid) {
			...flowagent
		}
	}
	${FLOWAGENT_FRAGMENT}
`;

const CREATE_FLOWAGENT = gql`
	mutation _createFlowAgent(
		$projectid: String!
		$instance: Int!
		$alias: String
		$description: String
		$status: Boolean
	) {
		createFlowAgent(
			projectid: $projectid
			instance: $instance
			alias: $alias
			description: $description
			status: $status
		) {
			...flowagent
		}
	}
	${FLOWAGENT_FRAGMENT}
`;

const UPDATE_FLOWAGENT = gql`
	mutation _updateFlowAgent($flowagentid: String!, $instance: Int) {
		updateFlowAgent(flowagentid: $flowagentid, instance: $instance) {
			...flowagent
		}
	}
	${FLOWAGENT_FRAGMENT}
`;

const DELETE_FLOWAGENT = gql`
	mutation _deleteFlowAgent($flowagentid: String!) {
		deleteFlowAgent(flowagentid: $flowagentid) {
			...flowagent
		}
	}
	${FLOWAGENT_FRAGMENT}
`;

export {
	FLOWAGENT_FRAGMENT,
	QUERY_FLOWAGENT_LIST,
	QUERY_FLOWAGENT,
	CREATE_FLOWAGENT,
	UPDATE_FLOWAGENT,
	DELETE_FLOWAGENT,
};
