import gql from 'graphql-tag';

const MEMBERSHIP_FRAGMENT = gql`
	fragment membership on Membership {
		createdtime
		level
		projectid
		userid
		username
	}
`;
// fragment membership on Membership {
// 	createdtime
// 	level
// 	projectid
// 	userid
// 	username
// }

const QUERY_MEMBERSHIP_LIST = gql`
	query _membership($projectid: String!) {
		membership(projectid: $projectid) {
			...membership
		}
	}
	${MEMBERSHIP_FRAGMENT}
`;

const CREATE_MEMBERSHIP = gql`
	mutation _createMembership($projectid: String!, $username: String!, $level: UserLevel!) {
		createMembership(projectid: $projectid, username: $username, level: $level) {
			...membership
		}
	}
	${MEMBERSHIP_FRAGMENT}
`;

const UPDATE_MEMBERSHIP = gql`
	mutation _updateMembership($projectid: String!, $username: String!, $level: UserLevel!) {
		updateMembership(projectid: $projectid, username: $username, level: $level) {
			...membership
		}
	}
	${MEMBERSHIP_FRAGMENT}
`;

const DELETE_MEMBERSHIP = gql`
	mutation _deleteMembership($projectid: String!, $username: String!) {
		deleteMembership(projectid: $projectid, username: $username) {
			...membership
		}
	}
	${MEMBERSHIP_FRAGMENT}
`;

const LEAVE_PROJECT = gql`
	mutation _leaveProject($projectid: String!) {
		leaveProject(projectid: $projectid) {
			...membership
		}
	}
	${MEMBERSHIP_FRAGMENT}
`;

export { QUERY_MEMBERSHIP_LIST, CREATE_MEMBERSHIP, UPDATE_MEMBERSHIP, DELETE_MEMBERSHIP, LEAVE_PROJECT };
