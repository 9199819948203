export const SET_APP_LANGUAGE = 'SET_APP_LANGUAGE';
export const SET_VISIBLE_LAYOUT = 'SET_VISIBLE_LAYOUT';
export const SET_PROJECTS_VIEW = 'SET_PROJECTS_VIEW';
export const SET_DEVICES_VIEW = 'SET_DEVICES_VIEW';
export const SET_GROUPS_VIEW = 'SET_GROUPS_VIEW';
export const SET_HOOKS_VIEW = 'SET_HOOKS_VIEW';

export const setLanguage = language => ({
	type: SET_APP_LANGUAGE,
	language,
});

export const setVisibleLayout = layoutVisible => ({
	type: SET_VISIBLE_LAYOUT,
	layoutVisible,
});

export const setProjectsView = projectsMode => ({
	type: SET_PROJECTS_VIEW,
	projectsMode,
});

export const setDevicesView = devicesMode => ({
	type: SET_DEVICES_VIEW,
	devicesMode,
});

export const setGroupsView = groupsMode => ({
	type: SET_GROUPS_VIEW,
	groupsMode,
});

export const setHookView = hooksMode => ({
	type: SET_HOOKS_VIEW,
	hooksMode,
});
