import { createTypes, createAction } from 'util/createActionHelper';

const LOAD_FREEBOARDS = createTypes('get', 'Freeboards');
const LOAD_FREEBOARD = createTypes('get', 'Freeboard');
const CREATE_FREEBOARD = createTypes('create', 'Freeboard');
const UPDATE_FREEBOARD = createTypes('update', 'Freeboard');
const DELETE_FREEBOARD = createTypes('delete', 'Freeboard');

const loadFreeboards = {
	request: (projectId) => createAction(LOAD_FREEBOARDS.REQUEST, { projectId }),
	success: (freeboards) => createAction(LOAD_FREEBOARDS.SUCCESS, { freeboards }),
	failure: (error) => createAction(LOAD_FREEBOARDS.FAILURE, { error }),
};
const loadFreeboard = {
	request: (freeboardid) => createAction(LOAD_FREEBOARD.REQUEST, { freeboardid }),
	success: (freeboard) => createAction(LOAD_FREEBOARD.SUCCESS, { freeboard }),
	failure: (error) => createAction(LOAD_FREEBOARD.FAILURE, { error }),
};
const createFreeboard = {
	request: (projectid, freeboard) => createAction(CREATE_FREEBOARD.REQUEST, { projectid, freeboard }),
	success: (freeboards) => createAction(CREATE_FREEBOARD.SUCCESS, { freeboards }),
	failure: (error) => createAction(CREATE_FREEBOARD.FAILURE, { error }),
};
const updateFreeboard = {
	request: (freeboard) => createAction(UPDATE_FREEBOARD.REQUEST, { freeboard }),
	success: (freeboards) => createAction(UPDATE_FREEBOARD.SUCCESS, { freeboards }),
	failure: (error) => createAction(UPDATE_FREEBOARD.FAILURE, { error }),
};
const deleteFreeboard = {
	request: (freeboardid) => createAction(DELETE_FREEBOARD.REQUEST, { freeboardid }),
	success: (freeboards) => createAction(DELETE_FREEBOARD.SUCCESS, { freeboards }),
	failure: (error) => createAction(DELETE_FREEBOARD.FAILURE, { error }),
};

export {
	LOAD_FREEBOARDS,
	loadFreeboards,
	LOAD_FREEBOARD,
	loadFreeboard,
	CREATE_FREEBOARD,
	createFreeboard,
	DELETE_FREEBOARD,
	deleteFreeboard,
	UPDATE_FREEBOARD,
	updateFreeboard,
};
