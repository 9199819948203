import { all, put, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/hookActions';
import { apolloQuery, apolloMutation } from '../../util/apollo';
import { QUERY_HOOKS, QUERY_HOOK, CREATE_HOOK, UPDATE_HOOK, DELETE_HOOK } from '../../util/apollo/nexpieGraphQL/hook';

function* loadHooks(action) {
	try {
		const { projectId } = action.payload;
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_HOOKS, { projectid: projectId }));

		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadHooks.success(data.hook));
		}
	} catch (error) {
		yield put(actions.loadHooks.failure(error));
	}
}

function* loadHook(action) {
	try {
		const { projectId, hookId } = action.payload;
		const { data, errors } = yield call(
			async () => await apolloQuery(QUERY_HOOK, { projectid: projectId, hookid: hookId })
		);

		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadHook.success(data.hook[0]));
		}
	} catch (error) {
		yield put(actions.loadHook.failure(error));
	}
}

function* createHook(action) {
	const { hook } = action.payload;
	try {
		const mutationRes = yield call(
			async () =>
				await apolloMutation(CREATE_HOOK, {
					projectid: hook.projectid,
					name: hook.name,
					description: hook.description,
					type: hook.type,
					enabled: hook.enabled,
				})
		);

		const { data, errors } = yield call(async () => await apolloQuery(QUERY_HOOKS, { projectid: hook.projectid }));

		if (mutationRes.errors || errors) {
			throw mutationRes.errors || errors;
		} else {
			if (mutationRes.data.createHook) {
				yield put(actions.createHook.success(data.hook, mutationRes.data.createHook));
			} else {
				yield put(actions.createHook.failure({ message: 'The hook name has been used, please try again.' }));
			}
		}
	} catch (error) {
		yield put(actions.createHook.failure(error));
	}
}

function* updateHook(action) {
	try {
		const { projectId, hookId, hook } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(UPDATE_HOOK, {
					projectid: projectId,
					hookid: hookId,
					name: hook.name,
					description: hook.description,
					type: hook.type,
					enabled: hook.enabled,
					param: hook.param,
				})
		);

		const { data, errors } = yield call(async () => await apolloQuery(QUERY_HOOKS, { projectid: projectId }));

		if (mutationRes.errors || errors) {
			throw mutationRes.errors || errors;
		} else {
			yield put(actions.updateHook.success(data.hook, mutationRes.data.updateHook));
		}
	} catch (error) {
		yield put(actions.updateHook.failure(error));
	}
}

function* updateHookParam(action) {
	try {
		const { projectId, hookId, name, enabled, param } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(UPDATE_HOOK, {
					projectid: projectId,
					hookid: hookId,
					name,
					enabled,
					param,
				})
		);

		const { data, errors } = yield call(
			async () => await apolloQuery(QUERY_HOOK, { projectid: projectId, hookid: hookId })
		);

		if (mutationRes.errors || errors) {
			throw mutationRes.errors || errors;
		} else {
			yield put(actions.updateHookParam.success(data.hook[0]));
		}
	} catch (error) {
		yield put(actions.updateHookParam.failure(error));
	}
}

function* deleteHook(action) {
	try {
		const { projectId, hookId } = action.payload;
		const mutationRes = yield call(
			async () => await apolloMutation(DELETE_HOOK, { projectid: projectId, hookid: hookId })
		);
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_HOOKS, { projectid: projectId }));
		if (errors && errors[0].code !== 404) throw errors;

		if (mutationRes.errors) {
			throw mutationRes.errors;
		} else {
			yield put(actions.deleteHook.success(data.hook, mutationRes.data.deleteHook));
		}
	} catch (error) {
		yield put(actions.deleteHook.failure(error));
	}
}

export default function* watchHook() {
	yield all([
		takeLatest(actions.LOAD_HOOK.REQUEST, loadHook),
		takeLatest(actions.LOAD_HOOKS.REQUEST, loadHooks),
		takeLatest(actions.CREATE_HOOK.REQUEST, createHook),
		takeLatest(actions.UPDATE_HOOK.REQUEST, updateHook),
		takeLatest(actions.UPDATE_HOOK_PARAM.REQUEST, updateHookParam),
		takeLatest(actions.DELETE_HOOK.REQUEST, deleteHook),
	]);
}
