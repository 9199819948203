/* eslint-disable no-unused-vars */
import { all, put, call, takeLatest } from 'redux-saga/effects';
// import { push } from 'connected-react-router';
// import axios from 'axios';
import * as actions from '../actions/deviceGroupActions';
import { apolloQuery, apolloMutation } from '../../util/apollo';
import {
	QUERY_DEVICE_GROUP_LIST,
	CREATE_DEVICE_GROUP,
	UPDATE_DEVICE_GROUP,
	DELETE_DEVICE_GROUP,
} from '../../util/apollo/nexpieGraphQL/deviceGroup';
import { QUERY_DEVICE_LIST } from 'util/apollo/nexpieGraphQL/device';
import { QUERY_PROJECT } from 'util/apollo/nexpieGraphQL/project';

function* loadDeviceGroups(action) {
	try {
		const { projectId } = action.payload;
		const groupsQuery = yield call(async () => await apolloQuery(QUERY_DEVICE_GROUP_LIST, { projectid: projectId }));

		//-----device list
		const project = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: projectId }));
		if (project.errors) throw project.errors;

		const total = project.data.project.numberdevice;

		let devices = [];
		let offset = 0;
		while (offset < total) {
			const limit = offset + 100;
			const { data, errors } = yield call(
				async () => await apolloQuery(QUERY_DEVICE_LIST, { filter: { projectid: projectId }, limit , offset })
			);

			if (errors) {
				throw errors;
			} else {
				const newDevices = devices.concat(data.deviceList);
				devices = newDevices;
			}
			offset = limit;
		}

		if (groupsQuery.errors) {
			throw groupsQuery.errors;
		} else {
			const groupsList = groupsQuery.data.groupList;
			const groupsListWithNum = groupsList.map((group) => {
				const numberOfDevices = devices.filter((device) => device.groupid === group.groupid).length;
				return { ...group, numberOfDevices };
			});
			yield put(actions.loadDeviceGroups.success(groupsListWithNum));
		}
	} catch (error) {
		yield put(actions.loadDeviceGroups.failure(error));
	}
}

function* loadDeviceGroup(action) {
	try {
		const { projectId, deviceGroupId } = action.payload;
		const { data, errors } = yield call(
			async () => await apolloQuery(QUERY_DEVICE_LIST, { filter: { projectid: projectId, groupid: deviceGroupId } })
		);

		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadDeviceGroup.success(data.deviceList));
		}
	} catch (error) {
		yield put(actions.loadDeviceGroup.failure(error));
	}
}

function* createDeviceGroup(action) {
	try {
		const { deviceGroup } = action.payload;

		const mutationRes = yield call(
			async () =>
				await apolloMutation(CREATE_DEVICE_GROUP, {
					projectid: deviceGroup.projectid,
					groupname: deviceGroup.groupname,
					description: deviceGroup.description,
					tag: deviceGroup.tag,
					hashtag: deviceGroup.hashtag,
					tags: deviceGroup.tags
				})
		);

		const { data, errors } = yield call(
			async () => await apolloQuery(QUERY_DEVICE_GROUP_LIST, { projectid: deviceGroup.projectid })
		);

		if (mutationRes.errors || errors) {
			throw mutationRes.errors || errors;
		} else {
			const groupsList = data.groupList;
			const updatedGroupsList = groupsList.map((group) => {
				const numberOfDevices = data.deviceList.filter((device) => device.groupid === group.groupid).length;
				return { ...group, numberOfDevices };
			});

			yield put(actions.createDeviceGroup.success(updatedGroupsList, mutationRes.data.createGroup));
		}
	} catch (error) {
		yield put(actions.createDeviceGroup.failure(error));
	}
}

function* updateDeviceGroup(action) {
	try {
		const { deviceGroupId, deviceGroup } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(UPDATE_DEVICE_GROUP, {
					groupid: deviceGroupId,
					groupname: deviceGroup.groupname,
					description: deviceGroup.description,
					tag: deviceGroup.tag,
					hashtag: deviceGroup.hashtag,
					tags: deviceGroup.tags
				})
		);

		const { data, errors } = yield call(
			async () => await apolloQuery(QUERY_DEVICE_GROUP_LIST, { projectid: deviceGroup.projectid })
		);

		if (mutationRes.errors || errors) {
			throw mutationRes.errors || errors;
		} else {
			const groupsList = data.groupList;
			const updatedGroupsList = groupsList.map((group) => {
				const numberOfDevices = data.deviceList.filter((device) => device.groupid === group.groupid).length;
				return { ...group, numberOfDevices };
			});

			yield put(actions.updateDeviceGroup.success(updatedGroupsList, mutationRes.data.updateGroup));
		}
	} catch (error) {
		yield put(actions.updateDeviceGroup.failure(error));
	}
}

function* deleteDeviceGroup(action) {
	try {
		const { projectId, deviceGroupId } = action.payload;
		const mutationRes = yield call(async () => await apolloMutation(DELETE_DEVICE_GROUP, { groupid: deviceGroupId }));

		const { data, errors } = yield call(
			async () => await apolloQuery(QUERY_DEVICE_GROUP_LIST, { projectid: projectId })
		);

		if (mutationRes.errors || errors) {
			throw mutationRes.errors || errors;
		} else {
			const groupsList = data.groupList;
			const updatedGroupsList = groupsList.map((group) => {
				const numberOfDevices = data.deviceList.filter((device) => device.groupid === group.groupid).length;
				return { ...group, numberOfDevices };
			});

			if (mutationRes.data.deleteGroup !== null) {
				yield put(actions.deleteDeviceGroup.success(updatedGroupsList, mutationRes.data.deleteGroup));
			} else {
				yield put(actions.deleteDeviceGroup.failure({ message: 'something wrong' }));
			}
		}
	} catch (error) {
		yield put(actions.deleteDeviceGroup.failure(error));
	}
}

export default function* watchDeviceGroups() {
	yield all([
		takeLatest(actions.LOAD_DEVICE_GROUPS.REQUEST, loadDeviceGroups),
		takeLatest(actions.LOAD_DEVICE_GROUP.REQUEST, loadDeviceGroup),
		takeLatest(actions.CREATE_DEVICE_GROUP.REQUEST, createDeviceGroup),
		takeLatest(actions.UPDATE_DEVICE_GROUP.REQUEST, updateDeviceGroup),
		takeLatest(actions.DELETE_DEVICE_GROUP.REQUEST, deleteDeviceGroup),
	]);
}
