import { all, put, call, takeEvery, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/JSONEditorDataAction';
import { apolloQuery, apolloMutation } from 'util/apollo';
import removeEmptyFromObj from 'util/removeEmptyFromObj';

import {
	QUERY_JSONEDITOR_DATA,
	QUERY_SHADOW,
	QUERY_SCHEMA,
	UPDATE_SHADOW,
	UPDATE_SCHEMA,
	CLEAR_SHADOW,
	CLEAR_SCHEMA,
} from 'util/apollo/nexpieGraphQL/JSONEditorData';

function* loadJSONData(action) {
	try {
		const { deviceId } = action.payload;
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_JSONEDITOR_DATA, { deviceid: deviceId }));

		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadJSONData.success(data));
		}
	} catch (error) {
		yield put(actions.loadJSONData.failure(error));
	}
}

function* updateShadow(action) {
	try {
		const { deviceId, updateData } = action.payload;
		const mutationRes = yield call(
			async () => await apolloMutation(UPDATE_SHADOW, { deviceid: deviceId, data: updateData })
		);

		const { data, errors } = yield call(async () => await apolloQuery(QUERY_SHADOW, { deviceid: deviceId }));

		if (mutationRes.errors || errors) {
			throw mutationRes.errors || errors;
		} else {
			yield put(actions.updateShadow.success(removeEmptyFromObj(data.shadow)));
		}
	} catch (error) {
		yield put(actions.updateShadow.failure(error));
	}
}

function* updateSchema(action) {
	const { deviceId, updateData } = action.payload;
	const { data, errors } = yield call(async () => await apolloQuery(QUERY_SCHEMA, { deviceid: deviceId }));

	try {
		const mutationRes = yield call(
			async () => await apolloMutation(UPDATE_SCHEMA, { deviceid: deviceId, value: updateData })
		);

		if (mutationRes.errors) {
			throw mutationRes.errors;
		} else {
			yield put(actions.updateSchema.success(mutationRes.data.updateSchema));
		}
	} catch (error) {
		yield put(actions.updateSchema.failure(error, data.schema));
	}
}

function* clearShadow(action) {
	const { deviceid } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloMutation(CLEAR_SHADOW, { deviceid }));
		if (errors) throw errors;

		yield put(actions.clearShadow.success(data.writeShadow));
	} catch (error) {
		yield put(actions.clearShadow.failure(error));
	}
}
function* clearSchema(action) {
	const { deviceid } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloMutation(CLEAR_SCHEMA, { deviceid }));
		if (errors) throw errors;

		yield put(actions.clearSchema.success(data.updateSchema));
	} catch (error) {
		yield put(actions.clearSchema.failure(error));
	}
}

export default function* watchJSONData() {
	yield all([
		takeLatest(actions.LOAD_JSONDATA.REQUEST, loadJSONData),
		takeEvery(actions.UPDATE_SHADOW.REQUEST, updateShadow),
		takeEvery(actions.UPDATE_SCHEMA.REQUEST, updateSchema),

		takeEvery(actions.CLEAR_SHADOW.REQUEST, clearShadow),
		takeEvery(actions.CLEAR_SCHEMA.REQUEST, clearSchema),
	]);
}
