import { createTypes, createAction } from 'util/createActionHelper';

const LOAD_BILLING_LOG = createTypes('get', 'billing_log');
const LOAD_PROJECT_LOG = createTypes('get', 'project_log');
const LOAD_DEVICE_LOG = createTypes('get', 'device_log');

const loadBillingLog = {
	request: (logData) => createAction(LOAD_BILLING_LOG.REQUEST, { logData }),
	success: billingLog => createAction(LOAD_BILLING_LOG.SUCCESS, { billingLog }),
	failure: error => createAction(LOAD_BILLING_LOG.FAILURE, { error }),
};

const loadProjectLog = {
	request: (logData) => createAction(LOAD_PROJECT_LOG.REQUEST, { logData }),
	success: projectLog => createAction(LOAD_PROJECT_LOG.SUCCESS, { projectLog }),
	failure: error => createAction(LOAD_PROJECT_LOG.FAILURE, { error }),
};

const loadDeviceLog = {
	request: (logData) => createAction(LOAD_DEVICE_LOG.REQUEST, { logData }),
	success: deviceLog => createAction(LOAD_DEVICE_LOG.SUCCESS, { deviceLog }),
	failure: error => createAction(LOAD_DEVICE_LOG.FAILURE, { error }),
}

export { LOAD_BILLING_LOG, loadBillingLog, LOAD_PROJECT_LOG, loadProjectLog, LOAD_DEVICE_LOG, loadDeviceLog };
