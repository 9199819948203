import gql from 'graphql-tag';

const BILLING_LOG_FRAGMENT = gql`
	fragment billingLog on BillingLog {
		time
		billingaccountid
		type
		level
		event
		projects
		data
		annotation
		note
	}
`;

const QUERY_BILLING_LOG = gql`
	query (
		$billingaccountid: String!
		$begintime: String!
		$endtime: String!
		$filter: BillingLogFilterKey
		$direction: SortDirection
		$limit: Int
		$offset: Int
	) {
		BillingLog(
			billingaccountid: $billingaccountid
			begintime: $begintime
			endtime: $endtime
			filter: $filter
			direction: $direction
			limit: $limit
			offset: $offset
		) {
			...billingLog
		}
	}
	${BILLING_LOG_FRAGMENT}
`;

export { QUERY_BILLING_LOG };
