import { createTypes, createAction } from 'util/createActionHelper';

const LOAD_FEED = createTypes('get', 'Feed');
const LOAD_FEED_LIST = createTypes('get', 'FeedList');
const LOAD_FEED_ATTRIBUTES = createTypes('get', 'FeedAttr');
const DELETE_FEED = createTypes('delete', 'Feed');
const CLEAR_FEED = createTypes('clear', 'Feed');
const DELETE_FEED_POINT = createTypes('delete', 'Feed point');
const UPDATE_FEED_POINT = createTypes('update', 'Feed point');
const RESET_FEED = createTypes('reset', 'Feed');

const loadFeed = {
	request: (startTime, endTime, deviceid, downsampling_unit) =>
		createAction(LOAD_FEED.REQUEST, {
			startTime,
			endTime,
			deviceid,
			downsampling_unit,
		}),
	success: (feed) => createAction(LOAD_FEED.SUCCESS, { feed }),
	failure: () => createAction(LOAD_FEED.FAILURE),
};

const loadFeedList = {
	request: (feedData) =>
		createAction(LOAD_FEED_LIST.REQUEST, {
			feedData,
		}),
	success: (feedList) => createAction(LOAD_FEED_LIST.SUCCESS, { feedList }),
	failure: () => createAction(LOAD_FEED_LIST.FAILURE),
};

const loadFeedAttributes = {
	request: (feedid) =>
		createAction(LOAD_FEED_ATTRIBUTES.REQUEST, {
			feedid,
		}),
	success: (feedAttr) => createAction(LOAD_FEED_ATTRIBUTES.SUCCESS, { feedAttr }),
	failure: () => createAction(LOAD_FEED_ATTRIBUTES.FAILURE),
};

const deleteFeed = {
	request: (feedData) =>
		createAction(DELETE_FEED.REQUEST, {
			feedData,
		}),
	success: (feedData) => createAction(DELETE_FEED.SUCCESS, { feedData }),
	failure: () => createAction(DELETE_FEED.FAILURE),
};

const clearFeed = {
	request: (feedid) =>
		createAction(CLEAR_FEED.REQUEST, {
			feedid,
		}),
	success: (feedData) => createAction(CLEAR_FEED.SUCCESS, { feedData }),
	failure: () => createAction(CLEAR_FEED.FAILURE),
};

const deleteFeedPoint = {
	request: (id, attr, start, end) => createAction(DELETE_FEED_POINT.REQUEST, { id, attr, start, end }),
	success: (feedData) => createAction(DELETE_FEED_POINT.SUCCESS, { feedData }),
	failure: (error) => createAction(DELETE_FEED_POINT.FAILURE, { error }),
};
const updateFeedPoint = {
	request: (feedid, data, timestamp, ttl) => createAction(UPDATE_FEED_POINT.REQUEST, { feedid, data, timestamp, ttl }),
	success: (feedData) => createAction(UPDATE_FEED_POINT.SUCCESS, { feedData }),
	failure: (error) => createAction(UPDATE_FEED_POINT.FAILURE, { error }),
};
const resetFeed = {
	request: (id, attr) => createAction(RESET_FEED.REQUEST, { id, attr }),
	success: (feedData) => createAction(RESET_FEED.SUCCESS, { feedData }),
	failure: (error) => createAction(RESET_FEED.FAILURE, { error }),
};

export {
	LOAD_FEED,
	loadFeed,
	LOAD_FEED_LIST,
	loadFeedList,
	LOAD_FEED_ATTRIBUTES,
	loadFeedAttributes,
	deleteFeed,
	DELETE_FEED,
	clearFeed,
	CLEAR_FEED,
	deleteFeedPoint,
	DELETE_FEED_POINT,
	updateFeedPoint,
	UPDATE_FEED_POINT,
	resetFeed,
	RESET_FEED,
};
