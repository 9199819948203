import 'react-app-polyfill/ie11';
import 'dayjs/locale/en';
import React from 'react';
import dotenv from 'dotenv';
import ReactDOM from 'react-dom';
// import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import relativeTime from 'dayjs/plugin/relativeTime';
import App from './App';
import dayjs from 'dayjs';
// var localizedFormat = require('dayjs/plugin/localizedFormat');
// dayjs.extend(localizedFormat);

ReactDOM.render(<App />, document.getElementById('root'));

dotenv.config();
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

const user_tz = dayjs.tz.guess();
dayjs.tz.setDefault(user_tz);
