import { createTypes, createAction } from 'util/createActionHelper';

const LOAD_DASHBOARDS = createTypes('get', 'Dashboards');
const LOAD_DASHBOARD = createTypes('get', 'Dashboard');
const CREATE_DASHBOARD = createTypes('create', 'Dashboard');
const CREATE_SESSION = createTypes('create', 'Dashboard Session');
const UPDATE_DASHBOARD = createTypes('update', 'Dashboard');
const UPDATE_SESSION = createTypes('update', 'Dashboard Session');
const DELETE_DASHBOARD = createTypes('delete', 'Dashboard');
const DELETE_SESSION = createTypes('delete', 'Dashboard Session');

const GET_PUBLIC_SHARED = createTypes('get', 'Public Shared Info');
const UPDATE_PUBLIC_SHARED = createTypes('update', 'Public Shared Info');
const REGENERATE_PUBLIC_SHARED = createTypes('regenerate', 'Public Shared Info');

const GET_PRIVATE_SHARED = createTypes('get', 'Private Shared Info');
const INVITE_TO_DASH = createTypes('invite', 'user');
const UPDATE_SHARE_LVL = createTypes('update', 'share level');
const REMOVE_SHARED = createTypes('remove', 'share');

const loadDashboards = {
	request: (projectId) => createAction(LOAD_DASHBOARDS.REQUEST, { projectId }),
	success: (dashboardSessions, dashboards) => createAction(LOAD_DASHBOARDS.SUCCESS, { dashboardSessions, dashboards }),
	failure: (error) => createAction(LOAD_DASHBOARDS.FAILURE, { error }),
};

const loadDashboard = {
	request: (dashboardId) => createAction(LOAD_DASHBOARD.REQUEST, { dashboardId }),
	success: (dashboard) => createAction(LOAD_DASHBOARD.SUCCESS, { dashboard }),
	failure: (error) => createAction(LOAD_DASHBOARD.FAILURE, { error }),
};

const createDashboard = {
	request: (dashboard) => createAction(CREATE_DASHBOARD.REQUEST, { dashboard }),
	success: (dashboardSessions, dashboards, dashboard) =>
		createAction(CREATE_DASHBOARD.SUCCESS, { dashboardSessions, dashboards, dashboard }),
	failure: (error) => createAction(CREATE_DASHBOARD.FAILURE, { error }),
};

const updateDashboard = {
	request: (dashboardId, dashboard) => createAction(UPDATE_DASHBOARD.REQUEST, { dashboardId, dashboard }),
	success: (dashboardSessions, dashboards, dashboard) =>
		createAction(UPDATE_DASHBOARD.SUCCESS, { dashboardSessions, dashboards, dashboard }),
	failure: (error) => createAction(UPDATE_DASHBOARD.FAILURE, { error }),
};

const deleteDashboard = {
	request: (projectId, dashboardId) => createAction(DELETE_DASHBOARD.REQUEST, { projectId, dashboardId }),
	success: (dashboardSessions, dashboards, dashboard) =>
		createAction(DELETE_DASHBOARD.SUCCESS, { dashboardSessions, dashboards, dashboard }),
	failure: (error) => createAction(DELETE_DASHBOARD.FAILURE, { error }),
};

const createSession = {
	request: (session) => createAction(CREATE_SESSION.REQUEST, { session }),
	success: (dashboardSessions, dashboards, session) =>
		createAction(CREATE_SESSION.SUCCESS, { dashboardSessions, dashboards, session }),
	failure: (error) => createAction(CREATE_SESSION.FAILURE, { error }),
};

const updateSession = {
	request: (sessionId, session) => createAction(UPDATE_SESSION.REQUEST, { sessionId, session }),
	success: (dashboardSessions, dashboards, session) =>
		createAction(UPDATE_SESSION.SUCCESS, { dashboardSessions, dashboards, session }),
	failure: (error) => createAction(UPDATE_SESSION.FAILURE, { error }),
};

const deleteSession = {
	request: (projectId, sessionId) => createAction(DELETE_SESSION.REQUEST, { projectId, sessionId }),
	success: (dashboardSessions, dashboards, session) =>
		createAction(DELETE_SESSION.SUCCESS, { dashboardSessions, dashboards, session }),
	failure: (error) => createAction(DELETE_SESSION.FAILURE, { error }),
};

// ----- Public Shared -----
const loadPublicShared = {
	request: (dashid) => createAction(GET_PUBLIC_SHARED.REQUEST, { dashid }),
	success: (publicShared) => createAction(GET_PUBLIC_SHARED.SUCCESS, { publicShared }),
	failure: (error) => createAction(GET_PUBLIC_SHARED.FAILURE, { error }),
};
const updatePublicShared = {
	request: (dashid, config) => createAction(UPDATE_PUBLIC_SHARED.REQUEST, { dashid, config }),
	success: (publicShared) => createAction(UPDATE_PUBLIC_SHARED.SUCCESS, { publicShared }),
	failure: (error) => createAction(UPDATE_PUBLIC_SHARED.FAILURE, { error }),
};
const regeneratePublicShared = {
	request: (dashid) => createAction(REGENERATE_PUBLIC_SHARED.REQUEST, { dashid }),
	success: (publicShared) => createAction(REGENERATE_PUBLIC_SHARED.SUCCESS, { publicShared }),
	failure: (error) => createAction(REGENERATE_PUBLIC_SHARED.FAILURE, { error }),
};

// ----- Private Shared -----
const loadPrivateShared = {
	request: (dashid) => createAction(GET_PRIVATE_SHARED.REQUEST, { dashid }),
	success: (privateShared) => createAction(GET_PRIVATE_SHARED.SUCCESS, { privateShared }),
	failure: (error) => createAction(GET_PRIVATE_SHARED.FAILURE, { error }),
};
const inviteToDash = {
	request: (dashid, username, level) => createAction(INVITE_TO_DASH.REQUEST, { dashid, username, level }),
	success: (privateShared) => createAction(INVITE_TO_DASH.SUCCESS, { privateShared }),
	failure: (error) => createAction(INVITE_TO_DASH.FAILURE, { error }),
};
const updateShareLvl = {
	request: (dashid, username, level) => createAction(UPDATE_SHARE_LVL.REQUEST, { dashid, username, level }),
	success: (privateShared) => createAction(UPDATE_SHARE_LVL.SUCCESS, { privateShared }),
	failure: (error) => createAction(UPDATE_SHARE_LVL.FAILURE, { error }),
};
const removeShared = {
	request: (dashid, username) => createAction(REMOVE_SHARED.REQUEST, { dashid, username }),
	success: (privateShared) => createAction(REMOVE_SHARED.SUCCESS, { privateShared }),
	failure: (error) => createAction(REMOVE_SHARED.FAILURE, { error }),
};

export {
	LOAD_DASHBOARDS,
	loadDashboards,
	LOAD_DASHBOARD,
	loadDashboard,
	CREATE_DASHBOARD,
	createDashboard,
	UPDATE_DASHBOARD,
	updateDashboard,
	DELETE_DASHBOARD,
	deleteDashboard,
	CREATE_SESSION,
	createSession,
	UPDATE_SESSION,
	updateSession,
	DELETE_SESSION,
	deleteSession,

	// ----- Public Shared -----
	GET_PUBLIC_SHARED,
	loadPublicShared,
	UPDATE_PUBLIC_SHARED,
	updatePublicShared,
	REGENERATE_PUBLIC_SHARED,
	regeneratePublicShared,
	// ----- Private Shared -----
	GET_PRIVATE_SHARED,
	loadPrivateShared,
	INVITE_TO_DASH,
	inviteToDash,
	UPDATE_SHARE_LVL,
	updateShareLvl,
	REMOVE_SHARED,
	removeShared,
};
