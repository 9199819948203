export const parseMemberLevel = (level) => {
    switch (level) {
        case "GUEST":
            return 1;
            
        case "VIEWER":
            return 2;
            
        case "EDITOR":
            return 3;
            
        case "MASTER":
            return 4;
            
        case "OWNER":
            return 5;
        default:
            return 5;
    }

}

export const parseMemberLevelTxt = (lvl) => {
    switch (lvl) {
        case 1:
            return 'GUEST';
        case 2:
            return 'VIEWER';
        case 3:
            return 'EDITOR';
        case 4:
            return 'MASTER';
        case 5:
            return 'OWNER';
        default:
            return "OWNER";
    }

}

export const parseBillingMemberLevel = (level) => {
    switch (level) {
        case 1:
            return "VIEWER";
            
        case 2:
            return "MASTER";
            
        case 3:
            return "OWNER";
            
        default:
            return level;
    }

}
