/* eslint-disable no-unused-vars */
import {
	LOAD_HOOKS,
	LOAD_HOOK,
	CREATE_HOOK,
	UPDATE_HOOK,
	UPDATE_HOOK_PARAM,
	DELETE_HOOK,
} from '../actions/hookActions';
import { shapeMessage } from 'util/openNotification';

const initialState = {
	hooks: [], //
	// hooksLoading: false,
	// hooksMessage: null,
	/*-------------------------------------*/
	hook: {
		projectid: null,
		hookid: null,
		description: null,
		name: null,
		type: null,
		enable: null,
		param: '',
	}, // query
	hooksLoading: false,
	message: null,
	loading: false,
	hookCUDLoading: false,
	hookMessage: null,
	/*------------------------------*/
	hookParamLoading: false,
	hookParamMessage: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOAD_HOOKS.REQUEST:
			return {
				...state,
				hooksLoading: true,
				message: null,
			};
		case LOAD_HOOKS.SUCCESS:
			return {
				...state,
				hooks: action.payload.hooks,
				hooksLoading: false,
				message: null,
			};
		case LOAD_HOOKS.FAILURE:
			return {
				...state,
				hooksLoading: false,
				hooks: [],
				hook: null,
				message: shapeMessage(action.type, action.payload.error),
			};

		/*------------------------------ LOAD_HOOK -------------------------*/

		case LOAD_HOOK.REQUEST:
			return {
				...state,
				loading: true,
				message: null,
			};
		case LOAD_HOOK.SUCCESS:
			return {
				...state,
				hook: action.payload.hook,
				loading: false,
				message: null,
			};
		case LOAD_HOOK.FAILURE:
			return {
				...state,
				loading: false,
				hook: null,
				message: shapeMessage(action.type, action.payload.error),
			};

		/*------------------------------ CREATE_HOOK -------------------------*/

		case CREATE_HOOK.REQUEST:
			return {
				...state,
				message: null,
				hookCUDLoading: true,
			};
		case CREATE_HOOK.SUCCESS:
			return {
				...state,
				hooks: action.payload.hooks,
				hook: action.payload.hook,
				message: shapeMessage(action.type),
				hookCUDLoading: false,
			};
		case CREATE_HOOK.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				hookCUDLoading: false,
			};

		/*------------------------------ UPDATE_HOOK -------------------------*/

		case UPDATE_HOOK.REQUEST:
			return {
				...state,
				hookMessage: null,
				hookCUDLoading: true,
			};
		case UPDATE_HOOK.SUCCESS:
			return {
				...state,
				hooks: action.payload.hooks,
				hook: action.payload.hook,
				hookMessage: shapeMessage(action.type),
				hookCUDLoading: false,
			};
		case UPDATE_HOOK.FAILURE:
			return {
				...state,
				hookMessage: shapeMessage(action.type, action.payload.error),
				hookCUDLoading: false,
			};

		/*------------------------------ UPDATE_HOOK_PARAM -------------------------*/

		case UPDATE_HOOK_PARAM.REQUEST:
			return {
				...state,
				hookParamMessage: null,
				hookParamLoading: true,
			};
		case UPDATE_HOOK_PARAM.SUCCESS:
			return {
				...state,
				hook: action.payload.hook,
				hookParamMessage: shapeMessage(action.type),
				hookParamLoading: false,
			};
		case UPDATE_HOOK_PARAM.FAILURE:
			return {
				...state,
				hookParamMessage: shapeMessage(action.type, action.payload.error),
				hookParamLoading: false,
			};

		/*------------------------------ DELETE_HOOK -------------------------*/

		case DELETE_HOOK.REQUEST:
			return {
				...state,
				hookMessage: null,
				hookCUDLoading: true,
			};
		case DELETE_HOOK.SUCCESS:
			return {
				...state,
				hooks: action.payload.hooks,
				hook: action.payload.hook,
				hookMessage: shapeMessage(action.type),
				hookCUDLoading: false,
			};
		case DELETE_HOOK.FAILURE:
			return {
				...state,
				hookMessage: shapeMessage(action.type, action.payload.error),
				hookCUDLoading: false,
			};

		default:
			return state;
	}
};
