/* eslint-disable no-unused-vars */
import { all, put, select, call, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/panelActions';
import { apolloQuery, apolloMutation } from 'util/apollo';
import {
	QUERY_PROJECT_PANEL,
	ENABLE_PROJECT_PANEL,
	DISABLE_PROJECT_PANEL,
	RESYNC_PANEL_MEMBERSHIP,
	CREATE_PANEL_DASHBOARD,
	UPDATE_PANEL_DASHBOARD,
	DELETE_PANEL_DASHBOARD,
} from 'util/apollo/nexpieGraphQL/panel';

function* loadProjectPanel(action) {
	try {
		const { projectid } = action.payload;
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PROJECT_PANEL, { projectid: projectid }));

		if (errors) {
			throw errors;
		}

		yield put(actions.loadProjectPanel.success(data.projectPanel[0]));
	} catch (error) {
		yield put(actions.loadProjectPanel.failure(error));
	}
}
function* enableProjectPanel(action) {
	try {
		const { projectid } = action.payload;
		const { data, errors } = yield call(
			async () => await apolloMutation(ENABLE_PROJECT_PANEL, { projectid: projectid })
		);
		const queryPanel = yield call(async () => await apolloQuery(QUERY_PROJECT_PANEL, { projectid: projectid }));

		if (errors) {
			throw errors;
		}

		yield put(actions.enableProjectPanel.success(queryPanel.data.projectPanel[0] || data.enableProjectPanel));
	} catch (error) {
		yield put(actions.enableProjectPanel.failure(error));
	}
}
function* disableProjectPanel(action) {
	try {
		const { projectid } = action.payload;
		const { data, errors } = yield call(
			async () => await apolloMutation(DISABLE_PROJECT_PANEL, { projectid: projectid })
		);

		if (errors) {
			throw errors;
		}

		yield put(actions.disableProjectPanel.success(data.disableProjectPanel));
	} catch (error) {
		yield put(actions.disableProjectPanel.failure(error));
	}
}
function* resyncPanelMember(action) {
	try {
		const { projectid } = action.payload;
		const { data, errors } = yield call(
			async () => await apolloMutation(RESYNC_PANEL_MEMBERSHIP, { projectid: projectid })
		);

		if (errors) {
			throw errors;
		}

		yield put(actions.resyncPanelMembership.success());
	} catch (error) {
		yield put(actions.resyncPanelMembership.failure(error));
	}
}

function* createDashboard(action) {
	try {
		const { projectid, name } = action.payload;

		const { data, errors } = yield call(
			async () => await apolloMutation(CREATE_PANEL_DASHBOARD, { projectid: projectid, name: name })
		);
		if (errors) throw errors;

		let prevPanel = yield select(({ panelReducer }) => panelReducer.panel)
		let newDashboard = prevPanel.dashboard;
		newDashboard.push(data.createPanelDashboard)
		const newPanel = {...prevPanel, dashboard: newDashboard}

		yield put(actions.createPanelDashboard.success(newPanel));
	} catch (error) {
		yield put(actions.createPanelDashboard.failure(error));
	}
}

function* updateDashboard(action) {
	try {
		const { projectid, dashboardid, name } = action.payload;

		const { data, errors } = yield call(
			async () => await apolloMutation(UPDATE_PANEL_DASHBOARD, { projectid: projectid, id: dashboardid, name: name })
		);

		if (errors) throw errors;

		let prevPanel = yield select(({ panelReducer }) => panelReducer.panel)
		let newDashboard = prevPanel.dashboard.map(x => x.id === data.updatePanelDashboard.id ? data.updatePanelDashboard : x);
		const newPanel = {...prevPanel, dashboard: newDashboard}
		
		yield put(actions.updatePanelDashboard.success(newPanel));
	} catch (error) {
		yield put(actions.updatePanelDashboard.failure(error));
	}
}

function* deleteDashboard(action) {
	try {
		const { projectid, dashboardid } = action.payload;

		const { data, errors } = yield call(
			async () => await apolloMutation(DELETE_PANEL_DASHBOARD, { projectid: projectid, id: dashboardid })
		);
		if (errors) throw errors;

		let prevPanel = yield select(({ panelReducer }) => panelReducer.panel)
		let newDashboard = prevPanel.dashboard.filter(x => x.id !== data.deletePanelDashboard.id);
		const newPanel = {...prevPanel, dashboard: newDashboard}

		yield put(actions.deletePanelDashboard.success(newPanel));
	} catch (error) {
		yield put(actions.deletePanelDashboard.failure(error));
	}
}

export default function* watchPanel() {
	yield all([
		takeLatest(actions.LOAD_PROJECT_PANEL.REQUEST, loadProjectPanel),
		takeLatest(actions.ENABLE_PANEL.REQUEST, enableProjectPanel),
		takeLatest(actions.DISABLE_PANEL.REQUEST, disableProjectPanel),
		takeLatest(actions.RESYNC_PANEL_MEMBER.REQUEST, resyncPanelMember),
		takeLatest(actions.CREATE_PANEL_DASHBOARD.REQUEST, createDashboard),
		takeLatest(actions.UPDATE_PANEL_DASHBOARD.REQUEST, updateDashboard),
		takeLatest(actions.DELETE_PANEL_DASHBOARD.REQUEST, deleteDashboard),
	]);
}
