import {
	LOAD_JSONDATA,
	UPDATE_SHADOW,
	UPDATE_SCHEMA,
	CLEAR_SCHEMA,
	CLEAR_SHADOW,
} from '../actions/JSONEditorDataAction';
import { shapeMessage } from 'util/openNotification';

const initialState = {
	shadow: {},
	schema: {},
	trigger: {},
	loading: false,
	message: null,
	JSONDataCUDLoading: false,

	clearingData: false,
	clearingMsg: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOAD_JSONDATA.REQUEST:
			return {
				...state,
				loading: true,
				message: null,
			};
		case LOAD_JSONDATA.SUCCESS:
			return {
				...state,
				shadow: action.payload.jsonData.shadow,
				schema: action.payload.jsonData.schema,
				trigger: action.payload.jsonData.trigger,
				loading: false,
				// message: shapeMessage(action.type),
			};
		case LOAD_JSONDATA.FAILURE:
			return {
				...state,
				// message: shapeMessage(action.type, action.payload.error),
				loading: false,
			};
		/*-------------------------------------------------*/
		case UPDATE_SHADOW.REQUEST:
			return {
				...state,
				JSONDataCUDLoading: true,
				message: null,
			};
		case UPDATE_SHADOW.SUCCESS:
			return {
				...state,
				shadow: action.payload.shadow,
				message: shapeMessage(action.type),
				JSONDataCUDLoading: false,
			};
		case UPDATE_SHADOW.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				JSONDataCUDLoading: false,
			};
		/*-------------------------------------------------*/

		case UPDATE_SCHEMA.REQUEST:
			return {
				...state,
				JSONDataCUDLoading: true,
				message: null,
			};
		case UPDATE_SCHEMA.SUCCESS:
			return {
				...state,
				schema: action.payload.schema,
				message: shapeMessage(action.type),
				JSONDataCUDLoading: false,
			};
		case UPDATE_SCHEMA.FAILURE:
			return {
				...state,
				schema: action.payload.schema,
				message: shapeMessage(action.type, action.payload.error),
				JSONDataCUDLoading: false,
			};
		/*-------------------------------------------------*/

		// clear data request
		case CLEAR_SHADOW.REQUEST:
		case CLEAR_SCHEMA.REQUEST:
			return {
				...state,
				clearingMsg: null,
				clearingData: true,
			};

		// ----- Shadow -----
		// clear success
		case CLEAR_SHADOW.SUCCESS:
			return {
				...state,
				clearingMsg: shapeMessage(action.type),
				clearingData: false,
				shadow: action.payload.shadow,
			};
		// clear failed
		case CLEAR_SHADOW.FAILURE:
			return {
				...state,
				clearingMsg: shapeMessage(action.type, action.payload.error),
				clearingData: false,
			};

		// ----- Schema -----
		// clear success
		case CLEAR_SCHEMA.SUCCESS:
			return {
				...state,
				clearingMsg: shapeMessage(action.type),
				clearingData: false,
				schema: action.payload.schema,
			};
		// clear failed
		case CLEAR_SCHEMA.FAILURE:
			return {
				...state,
				clearingMsg: shapeMessage(action.type, action.payload.error),
				clearingData: false,
			};

		default:
			return state;
	}
};
