import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import appSettingReducer from './appSettingReducer';
import projectReducer from './projectReducer';
import deviceReducer from './deviceReducer';
import deviceGroupReducer from './deviceGroupReducer';
import dashboardReducer from './dashboardReducer';
import flowagentReducer from './flowagentReducer';
import userReducer from './userReducer';
import membershipReducer from './membershipReducer';
import JSONEditorDataReducer from './JSONEditorDataReducer';
import authReducer from './authReducer';
import utilitiesReducer from './utilitiesReducer';
import hookReducer from './hookReducer';
import billingAccountReducer from './billingAccountReducer';
import feedReducer from './feedReducer';
import panelReducer from './panelReducer';
import logReducer from './logReducer';
import triggerReducer from './triggerReducer';
import freeboardReducer from './freeboardReducer';
import freeboardNewReducer from './freeboardNewReducer';
import dataTagReducer from './dataTagReducer';

const createRootReducer = (history) =>
	combineReducers({
		router: connectRouter(history),
		appSettingReducer,
		utilitiesReducer,
		authReducer,
		projectReducer,
		deviceReducer,
		JSONEditorDataReducer,
		deviceGroupReducer,
		dashboardReducer,
		userReducer,
		flowagentReducer,
		membershipReducer,
		hookReducer,
		billingAccountReducer,
		feedReducer,
		panelReducer,
		logReducer,
		triggerReducer,
		freeboardReducer,
		freeboardNewReducer,
		dataTagReducer,
	});
export default createRootReducer;
