/* eslint-disable no-unused-vars */
import { all, put, call, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import * as actions from '../actions/membershipActions';
import { apolloQuery, apolloMutation } from '../../util/apollo';
import {
	QUERY_MEMBERSHIP_LIST,
	CREATE_MEMBERSHIP,
	UPDATE_MEMBERSHIP,
	DELETE_MEMBERSHIP,
	LEAVE_PROJECT,
} from '../../util/apollo/nexpieGraphQL/membership';
import { updateUser } from 'reduxStore/actions/userActions';
import { parseMemberLevel, parseMemberLevelTxt } from 'util/ParseData';

function* loadMemberships(action) {
	try {
		const { projectId } = action.payload;
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_MEMBERSHIP_LIST, { projectid: projectId }));

		if (errors) {
			throw errors;
		} else {
			const memberships = data.membership.map((member) => ({ ...member, level: parseMemberLevel(member.level) }));
			yield put(actions.loadMemberships.success(memberships));

			const prevUser = yield select(({ userReducer }) => userReducer.user);
			const user = data.membership.find((x) => x.userid === prevUser?.userid);
			const newUser = { ...prevUser, level: parseMemberLevel(user?.level) || null };
			if (prevUser?.level !== newUser?.level) yield put(updateUser.success(newUser));
		}
	} catch (error) {
		yield put(actions.loadMemberships.failure(error));
	}
}

function* createMembership(action) {
	try {
		const { membership } = action.payload;

		const { data, errors } = yield call(
			async () =>
				await apolloMutation(CREATE_MEMBERSHIP, {
					projectid: membership.projectid,
					username: membership.username,
					level: membership.level.toUpperCase(),
				})
		);

		if (errors) {
			throw errors;
		} else {
			const prevMembers = yield select(({ membershipReducer }) => membershipReducer.memberships);
			const newMembers = [
				...prevMembers,
				{ ...data.createMembership, level: parseMemberLevel(data.createMembership.level) || null },
			];

			yield put(actions.createMembership.success(newMembers));
		}
	} catch (error) {
		yield put(actions.createMembership.failure(error));
	}
}

function* updateMembership(action) {
	try {
		const { membership } = action.payload;
		const { data, errors } = yield call(
			async () =>
				await apolloMutation(UPDATE_MEMBERSHIP, {
					projectid: membership.projectid,
					username: membership.username,
					level: membership.level.toUpperCase(),
				})
		);
		if (errors) {
			throw errors;
		} else {
			const prevMembers = yield select(({ membershipReducer }) => membershipReducer.memberships);
			const updatedMembers = prevMembers.map((member) => {
				return member.userid === data.updateMembership.userid
					? { ...data.updateMembership, level: parseMemberLevel(data.updateMembership.level) }
					: member;
			});
			yield put(actions.updateMembership.success(updatedMembers));
		}
	} catch (error) {
		yield put(actions.updateMembership.failure(error));
	}
}

function* deleteMembership(action) {
	try {
		const { projectId, username } = action.payload;
		const { data, errors } = yield call(
			async () =>
				await apolloMutation(DELETE_MEMBERSHIP, {
					projectid: projectId,
					username: username,
				})
		);
		if (errors) {
			throw errors;
		} else {
			yield put(actions.deleteMembership.success(data.deleteMembership));
		}
	} catch (error) {
		yield put(actions.deleteMembership.failure(error));
	}
}

function* leaveProject(action) {
	try {
		const { projectId } = action.payload;
		const { data, errors } = yield call(
			async () =>
				await apolloMutation(LEAVE_PROJECT, {
					projectid: projectId,
				})
		);
		if (errors) {
			throw errors;
		} else {
			if (data.leaveProject) {
				yield put(actions.leaveProject.success(data.leaveProject));
				yield put(push('/'));
			} else {
				yield put(actions.leaveProject.failure({ message: 'Something wrong' }));
			}
		}
	} catch (error) {
		yield put(actions.leaveProject.failure(error));
	}
}

export default function* watchMemberships() {
	yield all([
		takeLeading(actions.LOAD_MEMBERSHIPS.REQUEST, loadMemberships),
		takeLatest(actions.CREATE_MEMBERSHIP.REQUEST, createMembership),
		takeLatest(actions.UPDATE_MEMBERSHIP.REQUEST, updateMembership),
		takeLatest(actions.DELETE_MEMBERSHIP.REQUEST, deleteMembership),
		takeLatest(actions.LEAVE_PROJECT.REQUEST, leaveProject),
	]);
}
