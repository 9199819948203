import { all, call, spawn } from 'redux-saga/effects';

import watchUsers from './userSagas';
import watchProjects from './projectSagas';
import watchDevices from './deviceSagas';
import watchDeviceGroups from './deviceGroupSagas';
import watchDashboards from './dashboardSagas';
import watchMemberships from './membershipSagas';
import watchJSONData from './JSONEditorDataSagas';
import { watchSignOut, appInitialize } from './authSagas';
import watchSearchOrFilter from './utilitiesSagas';
import watchHook from './hookSagas';
import watchBillingAccount from './billingAccountSagas';
import watchFeed from './feedSagas';
import watchPanel from './panelSagas';
import watchLog from './logSagas';
import watchTrigger from './triggerSagas';
import watchFreeboards from './freeboardSagas';
import watchFreeboardNew from './freeboardNewSagas';
import watchDataTag from './dataTagSagas';

export default function* rootSagas() {
	yield all([
		call(appInitialize),
		spawn(watchUsers),
		spawn(watchProjects),
		spawn(watchDevices),
		spawn(watchDeviceGroups),
		spawn(watchDashboards),
		spawn(watchMemberships),
		spawn(watchJSONData),
		spawn(watchSignOut),
		spawn(watchSearchOrFilter),
		spawn(watchHook),
		spawn(watchBillingAccount),
		spawn(watchFeed),
		spawn(watchPanel),
		spawn(watchLog),
		spawn(watchTrigger),
		spawn(watchFreeboards),
		spawn(watchFreeboardNew),
		spawn(watchDataTag),
	]);
}
