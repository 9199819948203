const removeEmpty = obj => {
	var isArray = obj instanceof Array;
	for (var k in obj) {
		if (obj[k] === null) isArray ? obj.splice(k, 1) : delete obj[k];
		else if (typeof obj[k] === 'object') removeEmpty(obj[k]);
		if (isArray && obj.length === k) removeEmpty(obj);
	}
	return obj;
};

export default removeEmpty;
