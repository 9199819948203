import { createTypes, createAction } from 'util/createActionHelper';

const LOAD_BILLING_ACCOUNT = createTypes('get', 'billing_account');
const LOAD_BILLING_SUBSCRIPTION_LIST = createTypes('get', 'billing_subscription_list');
const LOAD_BILLING_ACCOUNT_LIST = createTypes('list', 'billing_account');
const LOAD_PROJECT_BILLING_ACCOUNT = createTypes('get', 'project_billing_account');
const GET_NON_STARTED_BILLING = createTypes('get', 'non-started billing');
const START_BILLING_SUBSCRIPTION = createTypes('start', 'billing subscription');

const loadBillingSubscriptionList = {
	request: (billingidList) => createAction(LOAD_BILLING_SUBSCRIPTION_LIST.REQUEST, { billingidList }),
	success: (billingSubscriptionList) =>
		createAction(LOAD_BILLING_SUBSCRIPTION_LIST.SUCCESS, { billingSubscriptionList }),
	failure: (error) => createAction(LOAD_BILLING_SUBSCRIPTION_LIST.FAILURE, { error }),
};

const loadBillingAccount = {
	request: (billingid) => createAction(LOAD_BILLING_ACCOUNT.REQUEST, { billingid }),
	success: (billingAccountData) => createAction(LOAD_BILLING_ACCOUNT.SUCCESS, { billingAccountData }),
	failure: (error) => createAction(LOAD_BILLING_ACCOUNT.FAILURE, { error }),
};

const loadBillingAccountList = {
	request: () => createAction(LOAD_BILLING_ACCOUNT_LIST.REQUEST),
	success: (billingAccountList) => createAction(LOAD_BILLING_ACCOUNT_LIST.SUCCESS, { billingAccountList }),
	failure: (error) => createAction(LOAD_BILLING_ACCOUNT_LIST.FAILURE, { error }),
};

const loadProjectBillingAccount = {
	request: (projectid) => createAction(LOAD_PROJECT_BILLING_ACCOUNT.REQUEST, { projectid }),
	success: (billingAccountData) => createAction(LOAD_PROJECT_BILLING_ACCOUNT.SUCCESS, { billingAccountData }),
	failure: (error) => createAction(LOAD_PROJECT_BILLING_ACCOUNT.FAILURE, { error }),
};

const loadNonStartedBilling = {
	request: (billingid) => createAction(GET_NON_STARTED_BILLING.REQUEST, { billingid }),
	success: (nonStartedBillings) => createAction(GET_NON_STARTED_BILLING.SUCCESS, { nonStartedBillings }),
	failure: (error) => createAction(GET_NON_STARTED_BILLING.FAILURE, { error }),
};
const startBillingSubscription = {
	request: (billingid) => createAction(START_BILLING_SUBSCRIPTION.REQUEST, { billingid }),
	success: (nonStartedBillings) => createAction(START_BILLING_SUBSCRIPTION.SUCCESS, { nonStartedBillings }),
	failure: (error) => createAction(START_BILLING_SUBSCRIPTION.FAILURE, { error }),
};

export {
	LOAD_BILLING_ACCOUNT,
	loadBillingAccount,
	LOAD_BILLING_ACCOUNT_LIST,
	loadBillingAccountList,
	LOAD_PROJECT_BILLING_ACCOUNT,
	loadProjectBillingAccount,
	GET_NON_STARTED_BILLING,
	loadNonStartedBilling,
	START_BILLING_SUBSCRIPTION,
	startBillingSubscription,
	LOAD_BILLING_SUBSCRIPTION_LIST,
	loadBillingSubscriptionList,
};
