import React, { Component } from 'react';
import { Result, Button } from 'antd';

class ErrorBoundary extends Component {
	state = { hasError: false };

	static getDerivedStateFromError(error) {
		return { hasError: true };
	}

	render() {
		if (this.state.hasError) {
			return (
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
					<Result
						status="error"
						title="There are some problems with your operation."
						extra={
							<a href="/">
								<Button type="primary">Back Home</Button>
							</a>
						}
					/>
				</div>
			);
		}

		return this.props.children;
	}
}

export default ErrorBoundary;
