import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { apolloMutation, apolloQuery } from 'util/apollo';
import {
	CREATE_TRIGGER,
	DELETE_TRIGGER,
	UPDATE_TRIGGER,
	DISABLE_ALL_TRIGGERS,
	DISABLE_TRIGGER,
	ENABLE_ALL_TRIGGERS,
	ENABLE_TRIGGER,
	QUERY_TRIGGER,
} from 'util/apollo/nexpieGraphQL/trigger';
import * as actions from 'reduxStore/actions/triggerActions';
import { DELETE_ALL_TRIGGER } from 'util/apollo/nexpieGraphQL/trigger';

function* loadTriggers(action) {
	const { deviceid, type } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_TRIGGER, { deviceid, type }));
		if (errors) throw errors;

		yield put(actions.loadTriggers.success(data.trigger));
	} catch (error) {
		yield put(actions.loadTriggers.failure(error));
	}
}
function* loadTrigger(action) {
	const { deviceid, triggerid, type } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_TRIGGER, { deviceid, triggerid, type }));
		if (errors) throw errors;

		yield put(actions.loadTrigger.success(data.trigger));
	} catch (error) {
		yield put(actions.loadTrigger.failure(error));
	}
}
function* toggleAllTriggers(action) {
	const { deviceid, status } = action.payload;
	try {
		const { data, errors } = yield call(
			async () => await apolloMutation(status ? ENABLE_ALL_TRIGGERS : DISABLE_ALL_TRIGGERS, { deviceid })
		);
		if (errors) throw errors;

		if (data) {
			const prevTriggers = yield select(({ triggerReducer }) => triggerReducer.triggers);
			const newTriggers = prevTriggers.map((tg) => {
				return { ...tg, enabled: status };
			});

			yield put(actions.toggleAllTriggers.success(newTriggers));
		}
	} catch (error) {
		yield put(actions.toggleAllTriggers.failure(error));
	}
}
function* toggleTrigger(action) {
	const { deviceid, triggerid, status } = action.payload;
	try {
		const { data, errors } = yield call(
			async () => await apolloMutation(status ? ENABLE_TRIGGER : DISABLE_TRIGGER, { deviceid, triggerid })
		);
		if (errors) throw errors;

		if (data) {
			const prevTriggers = yield select(({ triggerReducer }) => triggerReducer.triggers);
			const newTriggers = prevTriggers.map((tg) =>
				tg.triggerid === triggerid ? { ...tg, enabled: status } : { ...tg }
			);

			yield put(actions.toggleTrigger.success(newTriggers));
		}
	} catch (error) {
		yield put(actions.toggleTrigger.failure(error));
	}
}

function* deleteTrigger(action) {
	const { deviceid, triggerid } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloMutation(DELETE_TRIGGER, { deviceid, triggerid }));
		if (errors) throw errors;

		if (data) {
			const prevTriggers = yield select(({ triggerReducer }) => triggerReducer.triggers);
			const newTriggers = prevTriggers.filter((tg) => tg.triggerid !== triggerid);

			yield put(actions.deleteTrigger.success(newTriggers));
		}
	} catch (error) {
		yield put(actions.deleteTrigger.failure(error));
	}
}
function* createTrigger(action) {
	const { deviceid, trigger } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloMutation(CREATE_TRIGGER, { deviceid, ...trigger }));
		if (errors) throw errors;

		if (data) {
			const prevTriggers = yield select(({ triggerReducer }) => triggerReducer.triggers);
			const newTriggers = [...prevTriggers, data.createTrigger[0]];

			yield put(actions.createTrigger.success(newTriggers));
		}
	} catch (error) {
		yield put(actions.createTrigger.failure(error));
	}
}
function* updateTrigger(action) {
	const { deviceid, trigger } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloMutation(UPDATE_TRIGGER, { deviceid, ...trigger }));
		if (errors) throw errors;

		if (data) {
			const prevTriggers = yield select(({ triggerReducer }) => triggerReducer.triggers);
			const newTriggers = prevTriggers.map((tg) =>
				tg.triggerid === data.updateTrigger.triggerid ? data.updateTrigger : tg
			);

			yield put(actions.updateTrigger.success(newTriggers));
		}
	} catch (error) {
		yield put(actions.updateTrigger.failure(error));
	}
}
function* clearTrigger(action) {
	const { deviceid } = action.payload;
	try {
		const { errors } = yield call(async () => await apolloMutation(DELETE_ALL_TRIGGER, { deviceid }));
		if (errors) throw errors;

		yield put(actions.clearTrigger.success([]));
	} catch (error) {
		yield put(actions.clearTrigger.failure(error));
	}
}

export default function* watchTrigger() {
	yield all([
		takeLatest(actions.LOAD_TRIGGERS.REQUEST, loadTriggers),
		takeLatest(actions.LOAD_TRIGGER.REQUEST, loadTrigger),
		takeEvery(actions.TOGGLE_ALL_TRIGGERS.REQUEST, toggleAllTriggers),
		takeEvery(actions.TOGGLE_TRIGGER.REQUEST, toggleTrigger),
		takeEvery(actions.DELETE_TRIGGER.REQUEST, deleteTrigger),
		takeEvery(actions.CREATE_TRIGGER.REQUEST, createTrigger),
		takeEvery(actions.UPDATE_TRIGGER.REQUEST, updateTrigger),
		takeEvery(actions.CLEAR_TRIGGER.REQUEST, clearTrigger),
	]);
}
