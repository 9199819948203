import gql from 'graphql-tag';

const FEED_FRAGMENT = gql`
	fragment feed on Feed {
		feedid
		attributes
	}
`;

const FEED_DATA_FRAGMENT = gql`
	fragment feedData on FeedData {
		feedid
		data
	}
`;

const FEED_UPDATE_FRAGMENT = gql`
	fragment feedUpdate on FeedUpdate {
		feedid
		data
		timestamp
	}
`;
const FEED_POINT_FRAGMENT = gql`
	fragment result on Result {
		code
		text
	}
`;

const QUERY_FEED_ATTRIBUTES = gql`
	query ($feedid: String!) {
		feed(feedid: $feedid) {
			...feed
		}
	}
	${FEED_FRAGMENT}
`;

const QUERY_FEED_LIST = gql`
	query (
		$feedid: String!
		$attributes: [String]
		$starttime: Timestamp
		$endtime: Timestamp
		$pointlimit: Int
		$sampling: String
		$aggregator: String
	) {
		feedData(
			feedid: $feedid
			attributes: $attributes
			starttime: $starttime
			endtime: $endtime
			pointlimit: $pointlimit
			sampling: $sampling
			aggregator: $aggregator
		) {
			...feedData
		}
	}
	${FEED_DATA_FRAGMENT}
`;

const WRITE_FEED_DATA = gql`
	mutation ($feedid: String!, $data: JSON!, $timestamp: Timestamp) {
		writeFeedData(feedid: $feedid, data: $data, timestamp: $timestamp) {
			code
			text
		}
	}
`;

const DELETE_FEED_DATA = gql`
	mutation ($feedid: String!, $attributes: [String], $starttime: Timestamp, $endtime: Timestamp) {
		deleteFeedData(feedid: $feedid, attributes: $attributes, starttime: $starttime, endtime: $endtime) {
			...feedData
		}
	}
	${FEED_DATA_FRAGMENT}
`;

const SUBSCRIPTION_FEED_UPDATE = gql`
	subscription ($feedid: String!) {
		feedUpdated(feedid: $feedid) {
			...feedUpdate
		}
	}
	${FEED_UPDATE_FRAGMENT}
`;

const CLEAR_FEED = gql`
	mutation ($feedid: String!) {
		clearFeed(feedid: $feedid) {
			...feedData
		}
	}
	${FEED_DATA_FRAGMENT}
`;

const DELETE_FEED_POINT = gql`
	mutation DeleteFeedPoint($feedid: String!, $attributes: [String], $starttime: Timestamp, $endtime: Timestamp) {
		deleteFeedPoint(feedid: $feedid, attributes: $attributes, starttime: $starttime, endtime: $endtime) {
			...feedData
		}
	}
	${FEED_DATA_FRAGMENT}
`;
const WRITE_FEED_POINT = gql`
	mutation WriteFeedPoint($feedid: String!, $data: JSON!, $ttl: Float, $timestamp: Timestamp) {
		writeFeedPoint(feedid: $feedid, data: $data, ttl: $ttl, timestamp: $timestamp) {
			...result
		}
	}
	${FEED_POINT_FRAGMENT}
`;
const RESET_FEED_DATA = gql`
	mutation ResetFeedData($feedid: String!, $attributes: [String]) {
		resetFeedData(feedid: $feedid, attributes: $attributes) {
			...feedData
		}
	}
	${FEED_DATA_FRAGMENT}
`;

export {
	QUERY_FEED_ATTRIBUTES,
	QUERY_FEED_LIST,
	WRITE_FEED_DATA,
	DELETE_FEED_DATA,
	SUBSCRIPTION_FEED_UPDATE,
	CLEAR_FEED,
	DELETE_FEED_POINT,
	WRITE_FEED_POINT,
	RESET_FEED_DATA,
};
