import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import * as actions from 'reduxStore/actions/dataTagActions';
import { apolloMutation, apolloQuery } from 'util/apollo';
import {
	CREATE_DATATAG,
	DELETE_DATATAG,
	DISABLE_DATATAG,
	ENABLE_DATATAG,
	QUERY_DATATAG,
	UPDATE_DATATAG,
} from 'util/apollo/nexpieGraphQL/dataTag';

function* getDataTags(action) {
	try {
		const { objectid } = action.payload;

		const { data, errors } = yield call(async () => await apolloQuery(QUERY_DATATAG, { objectid }));
		if (errors) {
			throw errors;
		} else {
			yield put(actions.getDataTag.success(data.dataTagWithValue));
		}
	} catch (error) {
		yield put(actions.getDataTag.failure(error));
	}
}
function* createDataTag(action) {
	try {
		const { objectid, config } = action.payload;
		const { data, errors } = yield call(
			async () => await apolloMutation(CREATE_DATATAG, { objectid, objtype: 'DEVICE', ...config })
		);

		const prevDataTags = yield select(({ dataTagReducer }) => dataTagReducer.dataTags);

		if (errors) {
			throw errors;
		} else {
			const newData = [data.createDataTag, ...prevDataTags];

			yield put(actions.createDataTag.success(newData));
		}
	} catch (error) {
		yield put(actions.createDataTag.failure(error));
	}
}
function* updateDataTag(action) {
	try {
		const { config } = action.payload;

		const { data, errors } = yield call(async () => await apolloMutation(UPDATE_DATATAG, { ...config }));
		if (errors) throw errors;

		const prevDataTags = yield select(({ dataTagReducer }) => dataTagReducer.dataTags);
		const newData = prevDataTags.map((tag) => (tag.tagid === data.updateDataTag.tagid ? data.updateDataTag : tag));

		yield put(actions.updateDataTag.success(newData));
	} catch (error) {
		yield put(actions.updateDataTag.failure(error));
	}
}
function* deleteDataTag(action) {
	try {
		const { objectid, tagid } = action.payload;

		const { data, errors } = yield call(async () => await apolloMutation(DELETE_DATATAG, { objectid, tagid }));
		if (errors) throw errors;

		let newData = [];
		if (objectid === '') {
			const prevDataTags = yield select(({ dataTagReducer }) => dataTagReducer.dataTags);
			newData = prevDataTags.filter((i) => i.tagid !== data.deleteDataTag[0].tagid);
		}

		yield put(actions.deleteDataTag.success(newData));
	} catch (error) {
		yield put(actions.deleteDataTag.failure(error));
	}
}

function* toggleDatatag(action) {
	try {
		const { tagid, checked } = action.payload;

		let newTag;
		if (checked) {
			const { data, errors } = yield call(async () => await apolloMutation(ENABLE_DATATAG, { tagid }));
			if (errors) throw errors;

			newTag = data.enableDataTag?.[0];
		} else {
			const { data, errors } = yield call(async () => await apolloMutation(DISABLE_DATATAG, { tagid }));
			if (errors) throw errors;

			newTag = data.disableDataTag?.[0];
		}

		const prevDataTags = yield select(({ dataTagReducer }) => dataTagReducer.dataTags);
		const newData = prevDataTags.map((tag) => (tag.tagid === newTag.tagid ? newTag : tag));

		yield put(actions.toggleDataTag.success(newData));
	} catch (error) {
		yield put(actions.toggleDataTag.failure(error));
	}
}

export default function* watchDataTag() {
	yield all([
		takeLatest(actions.GET_DATA_TAG.REQUEST, getDataTags),
		takeLatest(actions.CREATE_DATA_TAG.REQUEST, createDataTag),
		takeLatest(actions.UPDATE_DATA_TAG.REQUEST, updateDataTag),
		takeLatest(actions.DELETE_DATA_TAG.REQUEST, deleteDataTag),
		takeLatest(actions.TOGGLE_DATATAG.REQUEST, toggleDatatag),
	]);
}
