import 'normalize.css';
import 'styles/styles.scss';
import React, { useEffect } from 'react';
import { useClearCache } from 'react-clear-cache';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { ApolloProvider } from 'react-apollo';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { store } from 'reduxStore/configureStore';
import history from 'util/history';
import { client } from 'util/apollo';
import IntlAppProvider from 'util/react-intl';
import { CookiesProvider } from 'react-cookie';
import AppLoading from 'pages/public/AppLoading';
import ErrorBoundary from 'pages/public/ErrorBoundaryPage';

const AppRouter = React.lazy(() => import('./router'));

const App = () => {
	const { isLatestVersion, emptyCacheStorage } = useClearCache();

	useEffect(() => {
		!isLatestVersion && emptyCacheStorage();
	}, [isLatestVersion, emptyCacheStorage]);

	return (
		<Provider store={store}>
			<ApolloProvider client={client}>
				<ConnectedRouter history={history}>
					{/* <Router> */}
					<CookiesProvider>
						<HelmetProvider>
							<Helmet>
								<link
									rel="shortcut icon"
									href={`${process.env.PUBLIC_URL}/${window._env_.PLATFORM_NAME}_logo_icon.png`}
								/>
								<title>{window._env_.PLATFORM_NAME.toUpperCase() + ' User Dashboard'}</title>
							</Helmet>
							<ErrorBoundary>
								<IntlAppProvider>
									<AppLoading>
										<AppRouter />
									</AppLoading>
								</IntlAppProvider>
							</ErrorBoundary>
						</HelmetProvider>
					</CookiesProvider>
					{/* </Router> */}
				</ConnectedRouter>
			</ApolloProvider>
		</Provider>
	);
};

export default App;
