import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import axios from 'axios';
import * as actions from 'reduxStore/actions/feedActions';
import cookie from 'util/cookie';
import dayjs from 'dayjs';
import { apolloMutation, apolloQuery } from 'util/apollo';
import {
	CLEAR_FEED,
	DELETE_FEED_DATA,
	DELETE_FEED_POINT,
	QUERY_FEED_ATTRIBUTES,
	QUERY_FEED_LIST,
	RESET_FEED_DATA,
	WRITE_FEED_POINT,
} from 'util/apollo/nexpieGraphQL/feed';

function* loadFeed(action) {
	try {
		const { startTime, endTime, deviceid, downsampling_unit } = action.payload;
		const START = dayjs(startTime).startOf().valueOf();
		const END = dayjs(endTime).endOf().valueOf();

		const body = {
			start_absolute: START,
			end_absolute: END,
			metrics: [
				{
					name: deviceid,
					group_by: [{ name: 'tag', tags: ['attr'] }],
					aggregators: [{ name: 'avg', sampling: { value: 1, unit: downsampling_unit } }],
				},
			],
		};

		const access_token = cookie.get('access_token');
		const res = yield call(axios.post, window._env_.FEED_URL, body, {
			headers: { Authorization: `Bearer ${access_token}` },
		});

		const formattedData = res.data.queries[0].results.map((item) => ({
			name: item.tags.attr,
			data: item.values,
		}));

		yield put(actions.loadFeed.success({ data: formattedData }));
	} catch (error) {
		yield put(actions.loadFeed.failure(error));
	}
}

function* loadFeedList(action) {
	try {
		const { feedid, attributes, starttime, endtime, sampling } = action.payload.feedData;
		const START = dayjs(starttime).valueOf();
		const END = endtime ? dayjs(endtime).valueOf() : dayjs().valueOf();

		const body = {
			start_absolute: START,
			end_absolute: END,
			metrics: [
				{
					name: feedid,
					group_by: [{ name: 'tag', tags: ['attr'] }],
					tags: { attr: attributes },
					aggregators: [{ name: 'avg', sampling: { value: 1, unit: sampling } }],
				},
			],
		};

		const access_token = cookie.get('access_token');
		const res = yield call(axios.post, window._env_.FEED_URL, body, {
			headers: { Authorization: `Bearer ${access_token}` },
		});

		const formattedData = res.data.queries.flatMap((query) =>
			query.results.map((result) => ({
				attr: result.tags.attr[0],
				tagid: result.name,
				values: result.values.map(([timestamp, value]) => [timestamp, value]),
			}))
		);

		yield put(actions.loadFeedList.success(formattedData));
	} catch (error) {
		yield put(actions.loadFeedList.failure(error));
	}
}

function* loadFeedAttributes(action) {
	try {
		const { feedid } = action.payload;
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_FEED_ATTRIBUTES, { feedid }));
		if (errors) throw errors;
		yield put(actions.loadFeedAttributes.success(data.feed.attributes || null));
	} catch (error) {
		yield put(actions.loadFeedAttributes.failure(error));
	}
}

function* deleteFeed(action) {
	try {
		const { feedid, attributes, starttime, endtime } = action.payload.feedData;
		const { data, errors } = yield call(
			async () => await apolloMutation(DELETE_FEED_DATA, { feedid, attributes, starttime, endtime })
		);
		if (errors) throw errors;
		yield put(actions.deleteFeed.success(data.feedData));
	} catch (error) {
		yield put(actions.deleteFeed.failure(error));
	}
}

function* clearFeed(action) {
	try {
		const { feedid } = action.payload;

		const { data, errors } = yield call(async () => await apolloMutation(CLEAR_FEED, { feedid }));
		if (errors) {
			throw errors;
		} else {
			yield put(actions.clearFeed.success(data.feedData));
		}
	} catch (error) {
		yield put(actions.clearFeed.failure(error));
	}
}

function* deleteFeedPoint(action) {
	try {
		const { id, attr, start, end } = action.payload;

		const { data, errors } = yield call(
			async () =>
				await apolloMutation(DELETE_FEED_POINT, {
					feedid: id,
					attributes: [attr],
					starttime: start,
					endtime: end ? end : start,
				})
		);
		if (errors) throw errors;

		if (data.deleteFeedPoint.data === 'Done') {
			yield put(actions.deleteFeedPoint.success());
		}
	} catch (error) {
		yield put(actions.deleteFeedPoint.failure(error));
	}
}

function* updateFeedPoint(action) {
	try {
		const { feedid, data, timestamp, ttl } = action.payload;

		const { errors } = yield call(async () => await apolloMutation(WRITE_FEED_POINT, { feedid, data, timestamp, ttl }));
		if (errors) throw errors;

		yield put(actions.updateFeedPoint.success());
	} catch (error) {
		yield put(actions.updateFeedPoint.failure(error));
	}
}

function* resetFeed(action) {
	try {
		const { id, attr } = action.payload;
		const { data, errors } = yield call(
			async () => await apolloMutation(RESET_FEED_DATA, { feedid: id, attributes: [attr] })
		);
		if (errors) throw errors;

		if (data.resetFeedData.data === 'Done') {
			const prevFeedList = yield select(({ feedReducer }) => feedReducer.feedList);
			const newFeedList = prevFeedList.map((item) => {
				if (item.attr === attr) {
					return {
						...item,
						values: [],
					};
				}

				return item;
			});

			yield put(actions.resetFeed.success(newFeedList));
		}
	} catch (error) {
		yield put(actions.resetFeed.failure(error));
	}
}

export default function* watchFeed() {
	yield all([
		takeLatest(actions.LOAD_FEED.REQUEST, loadFeed),
		takeLatest(actions.LOAD_FEED_LIST.REQUEST, loadFeedList),
		takeLatest(actions.LOAD_FEED_ATTRIBUTES.REQUEST, loadFeedAttributes),
		takeLatest(actions.DELETE_FEED.REQUEST, deleteFeed),
		takeLatest(actions.CLEAR_FEED.REQUEST, clearFeed),
		takeLatest(actions.DELETE_FEED_POINT.REQUEST, deleteFeedPoint),
		takeLatest(actions.UPDATE_FEED_POINT.REQUEST, updateFeedPoint),
		takeLatest(actions.RESET_FEED.REQUEST, resetFeed),
	]);
}
