import { createTypes, createAction } from 'util/createActionHelper';

const LOAD_JSONDATA = createTypes('get', 'jsondata');
const UPDATE_SHADOW = createTypes('update', 'shadow');
const UPDATE_SCHEMA = createTypes('update', 'schema');

const CLEAR_SHADOW = createTypes('clear', 'shadow');
const CLEAR_SCHEMA = createTypes('clear', 'schema');

const loadJSONData = {
	request: (deviceId) => createAction(LOAD_JSONDATA.REQUEST, { deviceId }),
	success: (jsonData) => createAction(LOAD_JSONDATA.SUCCESS, { jsonData }),
	failure: (error) => createAction(LOAD_JSONDATA.FAILURE, { error }),
};

const updateShadow = {
	request: (deviceId, updateData) => createAction(UPDATE_SHADOW.REQUEST, { deviceId, updateData }),
	success: (shadow) => createAction(UPDATE_SHADOW.SUCCESS, { shadow }),
	failure: (error) => createAction(UPDATE_SHADOW.FAILURE, { error }),
};

const updateSchema = {
	request: (deviceId, updateData) => createAction(UPDATE_SCHEMA.REQUEST, { deviceId, updateData }),
	success: (schema) => createAction(UPDATE_SCHEMA.SUCCESS, { schema }),
	failure: (error, schema) => createAction(UPDATE_SCHEMA.FAILURE, { error, schema }),
};

const clearShadow = {
	request: (deviceid) => createAction(CLEAR_SHADOW.REQUEST, { deviceid }),
	success: (shadow) => createAction(CLEAR_SHADOW.SUCCESS, { shadow }),
	failure: (error) => createAction(CLEAR_SHADOW.FAILURE, { error }),
};
const clearSchema = {
	request: (deviceid) => createAction(CLEAR_SCHEMA.REQUEST, { deviceid }),
	success: (schema) => createAction(CLEAR_SCHEMA.SUCCESS, { schema }),
	failure: (error) => createAction(CLEAR_SCHEMA.FAILURE, { error }),
};

export {
	LOAD_JSONDATA,
	loadJSONData,
	UPDATE_SHADOW,
	updateShadow,
	UPDATE_SCHEMA,
	updateSchema,
	CLEAR_SHADOW,
	clearShadow,
	CLEAR_SCHEMA,
	clearSchema,
};
