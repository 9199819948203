import {
	LOAD_DASHBOARDS,
	LOAD_DASHBOARD,
	CREATE_DASHBOARD,
	UPDATE_DASHBOARD,
	DELETE_DASHBOARD,
	CREATE_SESSION,
	UPDATE_SESSION,
	DELETE_SESSION,
	GET_PUBLIC_SHARED,
	UPDATE_PUBLIC_SHARED,
	REGENERATE_PUBLIC_SHARED,
	GET_PRIVATE_SHARED,
	INVITE_TO_DASH,
	UPDATE_SHARE_LVL,
	REMOVE_SHARED,
} from '../actions/dashboardActions';
import { shapeMessage } from 'util/openNotification';

const initialState = {
	dashboardSessions: [],
	dashboards: [],
	dashboardsMessage: null,
	dashboardsLoading: false,

	session: null,
	dashboard: null,
	loading: false,
	message: null,
	CUDloading: false,
	isCreated: false,

	// shared
	publicSharedLoading: false,
	publicShared: null,
	publicSharedErr: null,
	privateSharedLoading: false,
	inviting: false,
	removing: false,
	privateShared: [],
	privateSharedErr: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOAD_DASHBOARDS.REQUEST:
			return {
				...state,
				dashboardsLoading: true,
				isCreated: false,
			};
		case LOAD_DASHBOARDS.SUCCESS:
			return {
				...state,
				dashboardSessions: action.payload.dashboardSessions,
				dashboards: action.payload.dashboards,
				dashboardsMessage: null,
				dashboardsLoading: false,
				isCreated: false,
			};
		case LOAD_DASHBOARDS.FAILURE:
			return {
				...state,
				dashboardsMessage: shapeMessage(action.type, action.payload.error),
				dashboardsLoading: false,
				isCreated: false,
			};
		case LOAD_DASHBOARD.REQUEST:
			return {
				...state,
				loading: true,
				isCreated: false,
			};
		case LOAD_DASHBOARD.SUCCESS:
			return {
				...state,
				dashboard: action.payload.dashboard,
				message: shapeMessage(action.type),
				loading: false,
				isCreated: false,
			};
		case LOAD_DASHBOARD.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				loading: false,
				isCreated: false,
			};
		case CREATE_DASHBOARD.REQUEST:
			return {
				...state,
				CUDloading: true,
				isCreated: false,
			};
		case CREATE_DASHBOARD.SUCCESS:
			return {
				...state,
				dashboard: action.payload.dashboard,
				dashboards: action.payload.dashboards,
				dashboardSessions: action.payload.dashboardSessions,
				message: shapeMessage(action.type),
				CUDloading: false,
				isCreated: false,
			};
		case CREATE_DASHBOARD.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				CUDloading: false,
				isCreated: false,
			};
		case UPDATE_DASHBOARD.REQUEST:
			return {
				...state,
				CUDloading: true,
				isCreated: false,
			};
		case UPDATE_DASHBOARD.SUCCESS:
			return {
				...state,
				message: shapeMessage(action.type),
				dashboard: action.payload.dashboard,
				dashboards: action.payload.dashboards,
				dashboardSessions: action.payload.dashboardSessions,
				CUDloading: false,
				isCreated: false,
			};
		case UPDATE_DASHBOARD.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				CUDloading: false,
				isCreated: false,
			};
		case DELETE_DASHBOARD.REQUEST:
			return {
				...state,
				CUDloading: true,
				isCreated: false,
			};
		case DELETE_DASHBOARD.SUCCESS:
			return {
				...state,
				dashboards: action.payload.dashboards,
				dashboard: action.payload.dashboard,
				dashboardSessions: action.payload.dashboardSessions,
				message: shapeMessage(action.type),
				CUDloading: false,
				isCreated: false,
			};
		case DELETE_DASHBOARD.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				CUDloading: false,
				isCreated: false,
			};
		case CREATE_SESSION.REQUEST:
			return {
				...state,
				CUDloading: true,
				isCreated: false,
			};
		case CREATE_SESSION.SUCCESS:
			return {
				...state,
				session: action.payload.session,
				dashboards: action.payload.dashboards,
				dashboardSessions: action.payload.dashboardSessions,
				message: shapeMessage(action.type),
				CUDloading: false,
				isCreated: action.payload.session.dashboardgroupid,
			};
		case CREATE_SESSION.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				CUDloading: false,
				isCreated: false,
			};
		case UPDATE_SESSION.REQUEST:
			return {
				...state,
				CUDloading: true,
				isCreated: false,
			};
		case UPDATE_SESSION.SUCCESS:
			return {
				...state,
				message: shapeMessage(action.type),
				session: action.payload.session,
				dashboards: action.payload.dashboards,
				dashboardSessions: action.payload.dashboardSessions,
				CUDloading: false,
				isCreated: false,
			};
		case UPDATE_SESSION.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				CUDloading: false,
				isCreated: false,
			};
		case DELETE_SESSION.REQUEST:
			return {
				...state,
				CUDloading: true,
				isCreated: false,
			};
		case DELETE_SESSION.SUCCESS:
			return {
				...state,
				dashboards: action.payload.dashboards,
				dashboardSessions: action.payload.dashboardSessions,
				session: action.payload.session,
				message: shapeMessage(action.type),
				CUDloading: false,
				isCreated: false,
			};
		case DELETE_SESSION.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				CUDloading: false,
				isCreated: false,
			};

		// SHARED ACTIONS
		case GET_PUBLIC_SHARED.REQUEST:
		case UPDATE_PUBLIC_SHARED.REQUEST:
		case REGENERATE_PUBLIC_SHARED.REQUEST:
			return {
				...state,
				publicSharedLoading: true,
				publicSharedErr: null,
			};
		case GET_PUBLIC_SHARED.SUCCESS:
		case UPDATE_PUBLIC_SHARED.SUCCESS:
		case REGENERATE_PUBLIC_SHARED.SUCCESS:
			return {
				...state,
				publicSharedLoading: false,
				publicShared: action.payload.publicShared,
				publicSharedErr: null,
			};
		case GET_PUBLIC_SHARED.FAILURE:
		case UPDATE_PUBLIC_SHARED.FAILURE:
			return {
				...state,
				publicSharedLoading: false,
				publicShared: null,
				publicSharedErr: shapeMessage(action.type, action.payload.error),
			};
		case REGENERATE_PUBLIC_SHARED.FAILURE:
			return {
				...state,
				publicSharedLoading: false,
				publicSharedErr: shapeMessage(action.type, action.payload.error),
			};

		case GET_PRIVATE_SHARED.REQUEST:
			return {
				...state,
				privateSharedLoading: true,
				privateSharedErr: null,
			};
		case GET_PRIVATE_SHARED.SUCCESS:
			return {
				...state,
				privateSharedLoading: false,
				privateShared: action.payload.privateShared,
				privateSharedErr: null,
			};
		case GET_PRIVATE_SHARED.FAILURE:
			return {
				...state,
				privateSharedLoading: false,
				privateShared: [],
				privateSharedErr: shapeMessage(action.type, action.payload.error),
			};
		case INVITE_TO_DASH.REQUEST: {
			return {
				...state,
				inviting: true,
			};
		}
		case INVITE_TO_DASH.SUCCESS:
			return {
				...state,
				inviting: false,
				privateShared: action.payload.privateShared,
			};
		case INVITE_TO_DASH.FAILURE:
			return {
				...state,
				inviting: false,
				privateSharedErr: shapeMessage(action.type, action.payload.error),
			};
		case UPDATE_SHARE_LVL.SUCCESS:
			return { ...state, privateShared: action.payload.privateShared };
		case UPDATE_SHARE_LVL.FAILURE:
			return {
				...state,
				privateSharedErr: shapeMessage(action.type, action.payload.error),
			};
		case REMOVE_SHARED.REQUEST: {
			return { ...state, removing: true };
		}
		case REMOVE_SHARED.SUCCESS:
			return {
				...state,
				removing: false,
				privateShared: action.payload.privateShared,
			};
		case REMOVE_SHARED.FAILURE:
			return {
				...state,
				removing: false,
				privateSharedErr: shapeMessage(action.type, action.payload.error),
			};
		default:
			return state;
	}
};
