import {
	SET_APP_LANGUAGE,
	SET_VISIBLE_LAYOUT,
	SET_PROJECTS_VIEW,
	SET_DEVICES_VIEW,
	SET_GROUPS_VIEW,
	SET_HOOKS_VIEW,
} from '../actions/appSettingActions';

const initState = {
	language: 'en',
	layoutVisible: true,
	projectsMode: localStorage.getItem('projectsMode') || 'card',
	devicesMode: localStorage.getItem('devicesMode') || 'table',
	groupsMode: localStorage.getItem('groupsMode') || 'card',
	hooksMode: localStorage.getItem('hooksMode') || 'card',
};

const appSettingReducer = (state = initState, action = {}) => {
	switch (action.type) {
		case SET_APP_LANGUAGE:
			return { ...state, language: action.language };
		case SET_VISIBLE_LAYOUT:
			return { ...state, layoutVisible: action.layoutVisible };
		case SET_PROJECTS_VIEW:
			return { ...state, projectsMode: action.projectsMode };
		case SET_DEVICES_VIEW:
			return { ...state, devicesMode: action.devicesMode };
		case SET_GROUPS_VIEW:
			return { ...state, groupsMode: action.groupsMode };
		case SET_HOOKS_VIEW:
			return { ...state, hooksMode: action.hooksMode };
		default:
			return state;
	}
};

export default appSettingReducer;
