/* eslint-disable no-unused-vars */
import { all, put, call, fork, select, take, delay, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/userActions';
import { apolloQuery, apolloMutation } from 'util/apollo';
import { QUERY_USER, UPDATE_USER } from 'util/apollo/nexpieGraphQL/user';
import cookies from 'util/cookie';
import { decodeToken } from 'jsontokens';
import axios from 'axios';
import dayjs from 'dayjs';
import { signOut, AUTHENTICATED, UNAUTHENTICATED } from '../actions/authActions';
import { push } from 'connected-react-router';
import cookie from 'util/cookie';

function* takeOneAndBlock(pattern, worker, ...args) {
	const task = yield fork(function* () {
		while (true) {
			const action = yield take(pattern);
			yield call(worker, ...args, action);
		}
	});
	return task;
}

function* loadUser(action) {
	try {
		yield put({ type: AUTHENTICATED });
		const { access_token, expires } = action.payload;
		const res = decodeToken(access_token);
		const user_id = res?.payload?.ctx?.userid || res?.payload?.ctx?.owner;

		if (expires) {
			// Subtract 10 minutes from the actual expiration date so that the token can be refreshed before it expires.
			const MIN = 10;
			const EXP = dayjs.unix(Number(expires) - MIN * 60);
			yield call(() => cookies.set('user_id', user_id, { path: '/', expires: new Date(EXP) }));
		}
		if (access_token) {
			yield call(() => cookies.set('access_token', access_token, { path: '/' }));
		}

		const { data, errors } = yield call(async () => await apolloQuery(QUERY_USER, { userid: user_id }));

		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadUser.success(data.userByUserID));
			const prevLocation = yield select(({ router }) => router.location);
			const routePath = prevLocation.hash ? '/' : prevLocation.pathname ? prevLocation.pathname : '/';
			yield put(push(routePath));
		}
	} catch (error) {
		yield put(actions.loadUser.failure(error));
		yield delay(300);
		if (error?.[0]?.message === 'Invalid access token') {
			yield put({ type: UNAUTHENTICATED });
		}
	}
}

function* refreshUser(action) {
	try {
		const { old_access_token } = action.payload;

		// using an outdated access token to update access token
		const refreshResponse = yield call(
			async () =>
				await axios.post(`${window._env_.OAUTH_URI}/refreshtokenByAccesstoken`, { access_token: old_access_token })
		);

		if (!refreshResponse.data) throw new Error('Auto Refresh token failed, Please try again manually.');

		const new_access_token = refreshResponse.data.accesstoken;
		const new_expires = refreshResponse.data.exp;
		// Subtract 10 minutes from the actual expiration date so that the token can be refreshed before it expires.
		const MIN = 10;
		const EXP = dayjs.unix(Number(new_expires) - MIN * 60);
		const res = decodeToken(new_access_token);
		const user_id = res?.payload?.ctx?.owner;

		yield call(() => cookies.set('access_token', new_access_token, { path: '/' }));
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_USER, { userid: user_id }));

		if (errors) {
			throw errors;
		} else {
			yield call(() => cookies.set('user_id', user_id, { path: '/', expires: new Date(EXP) }));

			yield put(actions.refreshUser.success(data.userByUserID));
			yield put({ type: AUTHENTICATED });
			yield delay(300);
			const prevLocation = yield select(({ router }) => router.location);
			const routePath = prevLocation.pathname ? prevLocation.pathname : '/';
			yield put(push(routePath));
		}
	} catch (error) {
		yield put(actions.refreshUser.failure(error));
		yield delay(300);
		if (error?.[0]?.message === 'Invalid access token') {
			yield put({ type: UNAUTHENTICATED });
		}
	}
}

function* updateUser(action) {
	try {
		const { updateData } = action.payload;
		const { data, errors } = yield call(async () => await apolloMutation(UPDATE_USER, updateData));
		if (errors) {
			throw errors;
		} else {
			yield put(actions.updateUser.success(data.updateUserProfile));
		}
	} catch (error) {
		yield put(actions.updateUser.failure(error));
	}
}

const clearCookie = () => {
	const allCookieName = Object.keys(cookie.getAll());
	allCookieName.map((name) => cookie.remove(name, { path: '/' }));
};
function* deleteUser(action) {
	try {
		const { username, password } = action.payload;

		// call api
		const terminateaccount = yield call(
			async () =>
				await axios.post(`${window._env_.OAUTH_URI}/terminateaccount`, { grant_type: 'password', username, password })
		);

		if (!terminateaccount.data) throw new Error('Delete user failed, Please try again.');

		// sign out
		yield clearCookie();
		yield sessionStorage.clear();
		window.location = `${window._env_.OAUTH_URI}/logout?redirect_uri=${
			window.location.protocol +
			'//' +
			window.location.hostname +
			(window.location.port ? ':' + window.location.port : '')
		}`;
		yield delay(500);
		yield put({ type: UNAUTHENTICATED });
	} catch (error) {
		yield put(actions.deleteUser.failure(new Error('Delete user failed, Please try again.')));
	}
}

export default function* watchUsers() {
	yield all([
		takeOneAndBlock(actions.LOAD_USER.REQUEST, loadUser),
		takeOneAndBlock(actions.REFRESH_USER.REQUEST, refreshUser),
		takeLatest(actions.UPDATE_USER.REQUEST, updateUser),
		takeLatest(actions.DELETE_USER.REQUEST, deleteUser),
	]);
}
