import gql from 'graphql-tag';

const DATATAG_FRAGMENT = gql`
	fragment datatag on DataTag {
		tagid
		objectid
		objtype
		datapath
		datatype
		attr
		name
		unit
		ttl
		description
		enabled
		expression
		value
		lastvaluetime
		updatedtime
		createdtime
	}
`;

const QUERY_DATATAG = gql`
	query _getDataTag($objectid: String) {
		dataTagWithValue(objectid: $objectid) {
			...datatag
		}
	}
	${DATATAG_FRAGMENT}
`;

const CREATE_DATATAG = gql`
	mutation createDataTag(
		$objectid: String!
		$objtype: String!
		$datapath: String!
		$datatype: String!
		$attr: String
		$name: String
		$unit: String
		$ttl: Float!
		$description: String
		$enabled: Boolean
		$expression: String
	) {
		createDataTag(
			objectid: $objectid
			objtype: $objtype
			datapath: $datapath
			datatype: $datatype
			attr: $attr
			name: $name
			unit: $unit
			ttl: $ttl
			description: $description
			enabled: $enabled
			expression: $expression
		) {
			...datatag
		}
	}
	${DATATAG_FRAGMENT}
`;

const UPDATE_DATATAG = gql`
	mutation updateDataTag(
		$tagid: String!
		$datapath: String!
		$datatype: String!
		$attr: String
		$name: String
		$unit: String
		$ttl: Float
		$description: String
		$expression: String
	) {
		updateDataTag(
			tagid: $tagid
			datapath: $datapath
			datatype: $datatype
			attr: $attr
			name: $name
			unit: $unit
			ttl: $ttl
			description: $description
			expression: $expression
		) {
			...datatag
		}
	}
	${DATATAG_FRAGMENT}
`;

const DELETE_DATATAG = gql`
	mutation deleteDataTag($tagid: String, $objectid: String) {
		deleteDataTag(tagid: $tagid, objectid: $objectid) {
			...datatag
		}
	}
	${DATATAG_FRAGMENT}
`;

const ENABLE_DATATAG = gql`
	mutation enableDataTag($tagid: String, $objectid: String) {
		enableDataTag(tagid: $tagid, objectid: $objectid) {
			...datatag
		}
	}
	${DATATAG_FRAGMENT}
`;

const DISABLE_DATATAG = gql`
	mutation disableDataTag($tagid: String, $objectid: String) {
		disableDataTag(tagid: $tagid, objectid: $objectid) {
			...datatag
		}
	}
	${DATATAG_FRAGMENT}
`;

export { QUERY_DATATAG, CREATE_DATATAG, UPDATE_DATATAG, DELETE_DATATAG, ENABLE_DATATAG, DISABLE_DATATAG };
