import { createTypes, createAction } from 'util/createActionHelper';

const LOAD_HOOKS = createTypes('get', 'Hooks');
const LOAD_HOOK = createTypes('get', 'Hook');
const CREATE_HOOK = createTypes('create', 'Hook');
const UPDATE_HOOK = createTypes('update', 'Hook');
const UPDATE_HOOK_PARAM = createTypes('update', 'Hook_Parameter');

const DELETE_HOOK = createTypes('delete', 'Hook');

const loadHooks = {
	request: projectId => createAction(LOAD_HOOKS.REQUEST, { projectId }),
	success: hooks => createAction(LOAD_HOOKS.SUCCESS, { hooks }),
	failure: error => createAction(LOAD_HOOKS.FAILURE, { error }),
};

const loadHook = {
	request: (projectId, hookId) => createAction(LOAD_HOOK.REQUEST, { projectId, hookId }),
	success: hook => createAction(LOAD_HOOK.SUCCESS, { hook }),
	failure: error => createAction(LOAD_HOOK.FAILURE, { error }),
};

const createHook = {
	request: hook => createAction(CREATE_HOOK.REQUEST, { hook }),
	success: (hooks, hook) => createAction(CREATE_HOOK.SUCCESS, { hooks, hook }),
	failure: error => createAction(CREATE_HOOK.FAILURE, { error }),
};

const updateHook = {
	request: (projectId, hookId, hook) => createAction(UPDATE_HOOK.REQUEST, { projectId, hookId, hook }),
	success: (hooks, hook) => createAction(UPDATE_HOOK.SUCCESS, { hooks, hook }),
	failure: error => createAction(UPDATE_HOOK.FAILURE, { error }),
};

const updateHookParam = {
	request: (projectId, hookId, name, enabled, param) =>
		createAction(UPDATE_HOOK_PARAM.REQUEST, { projectId, hookId, name, enabled, param }),
	success: hook => createAction(UPDATE_HOOK_PARAM.SUCCESS, { hook }),
	failure: error => createAction(UPDATE_HOOK_PARAM.FAILURE, { error }),
};

const deleteHook = {
	request: (projectId, hookId) => createAction(DELETE_HOOK.REQUEST, { projectId, hookId }),
	success: (hooks, hook) => createAction(DELETE_HOOK.SUCCESS, { hooks, hook }),
	failure: error => createAction(DELETE_HOOK.FAILURE, { error }),
};

export {
	LOAD_HOOKS,
	loadHooks,
	LOAD_HOOK,
	loadHook,
	CREATE_HOOK,
	createHook,
	UPDATE_HOOK,
	updateHook,
	UPDATE_HOOK_PARAM,
	updateHookParam,
	DELETE_HOOK,
	deleteHook,
};
