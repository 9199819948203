import { createTypes, createAction } from 'util/createActionHelper';

// ----- TYPES -----
const LOAD_DASH = createTypes('load', 'dash');
const LOAD_DASH_LIST = createTypes('load', 'dash list');
const LOAD_DASH_TOKEN = createTypes('load', 'dash token list');
const LOAD_DASH_RESOURCE = createTypes('load', 'dash resource');
const CREATE_DASH = createTypes('create', 'dash');
const UPDATE_DASH = createTypes('update', 'dash');
const DELETE_DASH = createTypes('delete', 'dash');
const CREATE_DASH_TOKEN = createTypes('create', 'dash token');
const CREATE_DASH_RESOURCE = createTypes('create', 'dash resource');
const UPDATE_DASH_RESOURCE = createTypes('update', 'dash resource');
const DELETE_DASH_RESOURCE = createTypes('delete', 'dash resource');
const DELETE_ALL_DASH_RESOURCE = createTypes('delete', 'all dash resource');

const CLEAR_DASH_MSG = 'CLEAR_DASH_MSG';

// ----- ACTIONS -----
const loadDash = {
	request: (dashid) => createAction(LOAD_DASH.REQUEST, { dashid }),
	success: (dash) => createAction(LOAD_DASH.SUCCESS, { dash }),
	failure: (error) => createAction(LOAD_DASH.FAILURE, { error }),
};
const loadDashList = {
	request: (projectid) => createAction(LOAD_DASH_LIST.REQUEST, { projectid }),
	success: (dashList) => createAction(LOAD_DASH_LIST.SUCCESS, { dashList }),
	failure: (error) => createAction(LOAD_DASH_LIST.FAILURE, { error }),
};
const createDash = {
	request: (projectid, dashinfo) => createAction(CREATE_DASH.REQUEST, { projectid, dashinfo }),
	success: (dashList) => createAction(CREATE_DASH.SUCCESS, { dashList }),
	failure: (error) => createAction(CREATE_DASH.FAILURE, { error }),
};
const updateDash = {
	request: (dashinfo) => createAction(UPDATE_DASH.REQUEST, { dashinfo }),
	success: (dashList) => createAction(UPDATE_DASH.SUCCESS, { dashList }),
	failure: (error) => createAction(UPDATE_DASH.FAILURE, { error }),
};
const deleteDash = {
	request: (dashid) => createAction(DELETE_DASH.REQUEST, { dashid }),
	success: (dashList) => createAction(DELETE_DASH.SUCCESS, { dashList }),
	failure: (error) => createAction(DELETE_DASH.FAILURE, { error }),
};

// dash token
const loadDashToken = {
	request: (dashid) => createAction(LOAD_DASH_TOKEN.REQUEST, { dashid }),
	success: (dashTokenList) => createAction(LOAD_DASH_TOKEN.SUCCESS, { dashTokenList }),
	failure: (error) => createAction(LOAD_DASH_TOKEN.FAILURE, { error }),
};
const createDashToken = {
	request: (dashid, level) => createAction(CREATE_DASH_TOKEN.REQUEST, { dashid, level }),
	success: (dashTokenList) => createAction(CREATE_DASH_TOKEN.SUCCESS, { dashTokenList }),
	failure: (error) => createAction(CREATE_DASH_TOKEN.FAILURE, { error }),
};

// dash resource
const loadDashResource = {
	request: (projectid, dashid) => createAction(LOAD_DASH_RESOURCE.REQUEST, { projectid, dashid }),
	success: (deviceList, dashResource) => createAction(LOAD_DASH_RESOURCE.SUCCESS, { deviceList, dashResource }),
	failure: (error) => createAction(LOAD_DASH_RESOURCE.FAILURE, { error }),
};
const createDashResource = {
	request: (dashid, objectid, alias, privilages) =>
		createAction(CREATE_DASH_RESOURCE.REQUEST, { dashid, objectid, alias, privilages }),
	success: (dashResource) => createAction(CREATE_DASH_RESOURCE.SUCCESS, { dashResource }),
	failure: (error) => createAction(CREATE_DASH_RESOURCE.FAILURE, { error }),
};
const updateDashResource = {
	request: (dashid, objectid, alias, privilages) =>
		createAction(UPDATE_DASH_RESOURCE.REQUEST, { dashid, objectid, alias, privilages }),
	success: (dashResource) => createAction(UPDATE_DASH_RESOURCE.SUCCESS, { dashResource }),
	failure: (error) => createAction(UPDATE_DASH_RESOURCE.FAILURE, { error }),
};
const deleteDashResource = {
	request: (dashid, objectid) => createAction(DELETE_DASH_RESOURCE.REQUEST, { dashid, objectid }),
	success: (dashResource) => createAction(DELETE_DASH_RESOURCE.SUCCESS, { dashResource }),
	failure: (error) => createAction(DELETE_DASH_RESOURCE.FAILURE, { error }),
};
const deleteAllDashResource = {
	request: (dashid) => createAction(DELETE_ALL_DASH_RESOURCE.REQUEST, { dashid }),
	success: (dashResource) => createAction(DELETE_ALL_DASH_RESOURCE.SUCCESS, { dashResource }),
	failure: (error) => createAction(DELETE_ALL_DASH_RESOURCE.FAILURE, { error }),
};

const clearDashMsg = () => ({ type: CLEAR_DASH_MSG });

export {
	LOAD_DASH,
	loadDash,
	LOAD_DASH_LIST,
	loadDashList,
	CREATE_DASH,
	createDash,
	UPDATE_DASH,
	updateDash,
	DELETE_DASH,
	deleteDash,
	LOAD_DASH_TOKEN,
	loadDashToken,
	CREATE_DASH_TOKEN,
	createDashToken,
	LOAD_DASH_RESOURCE,
	loadDashResource,
	CREATE_DASH_RESOURCE,
	createDashResource,
	UPDATE_DASH_RESOURCE,
	updateDashResource,
	DELETE_DASH_RESOURCE,
	deleteDashResource,
	DELETE_ALL_DASH_RESOURCE,
	deleteAllDashResource,
	CLEAR_DASH_MSG,
	clearDashMsg,
};
