import gql from 'graphql-tag';

const SHADOW_FRAGMENT = gql`
	fragment shadow on Shadow {
		deviceid
		data
		rev
		modified
	}
`;

const SCHEMA_FRAGMENT = gql`
	fragment schema on Schema {
		deviceid
		value
	}
`;

const TRIGGER_FRAGMENT = gql`
	fragment trigger on Trigger {
		triggerid
		triggername
		type
		event
		condition
		action
		context {
			key
			value
		}
		enabled
	}
`;

const QUERY_JSONEDITOR_DATA = gql`
	query _JSONEditorData($deviceid: String!) {
		shadow(deviceid: $deviceid) {
			...shadow
		}
		schema(deviceid: $deviceid) {
			...schema
		}
		trigger(deviceid: $deviceid) {
			...trigger
		}
	}
	${SHADOW_FRAGMENT}
	${SCHEMA_FRAGMENT}
	${TRIGGER_FRAGMENT}
`;

const QUERY_SHADOW = gql`
	query _shadow($deviceid: String!) {
		shadow(deviceid: $deviceid) {
			...shadow
		}
	}
	${SHADOW_FRAGMENT}
`;

const QUERY_SCHEMA = gql`
	query _schema($deviceid: String!) {
		schema(deviceid: $deviceid) {
			...schema
		}
	}

	${SCHEMA_FRAGMENT}
`;

const QUERY_TRIGGER = gql`
	query _trigger($deviceid: String!) {
		trigger(deviceid: $deviceid) {
			...trigger
		}
	}
	${TRIGGER_FRAGMENT}
`;

const SUBSCRIPTION_SHADOW = gql`
	subscription _shadowUpdated($deviceid: String!) {
		shadowUpdated(deviceid: $deviceid) {
			...shadow
		}
	}
	${SHADOW_FRAGMENT}
`;

const UPDATE_SHADOW = gql`
	mutation _writeShadow($deviceid: String!, $data: JSON) {
		writeShadow(deviceid: $deviceid, data: $data) {
			...shadow
		}
	}
	${SHADOW_FRAGMENT}
`;

const UPDATE_SCHEMA = gql`
	mutation _updateSchema($deviceid: String!, $value: JSON) {
		updateSchema(deviceid: $deviceid, value: $value) {
			...schema
		}
	}
	${SCHEMA_FRAGMENT}
`;
const CLEAR_SHADOW = gql`
	mutation clearShadow($deviceid: String!) {
		writeShadow(deviceid: $deviceid, data: {}, mode: REPLACE) {
			...shadow
		}
	}
	${SHADOW_FRAGMENT}
`;
const CLEAR_SCHEMA = gql`
	mutation clearSchema($deviceid: String!) {
		updateSchema(deviceid: $deviceid, value: {}) {
			...schema
		}
	}
	${SCHEMA_FRAGMENT}
`;

export {
	QUERY_JSONEDITOR_DATA,
	QUERY_SHADOW,
	QUERY_SCHEMA,
	QUERY_TRIGGER,
	SUBSCRIPTION_SHADOW,
	UPDATE_SHADOW,
	UPDATE_SCHEMA,
	CLEAR_SHADOW,
	CLEAR_SCHEMA,
};
