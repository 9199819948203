import gql from 'graphql-tag';

const PANEL_FRAGMENT = gql`
	fragment panel on Panel {
		id
		type
		projectid
		userid
		name
		description
		dashboard {
			id
			name
			urlpath
		}
		enabled
	}
`;

const DASHBOARD_FRAGMENT = gql`
	fragment dashboard on PanelDashboard {
		id
		name
		urlpath
	}
`;

const QUERY_PROJECT_PANEL = gql`
	query _projectPanel($projectid: String!) {
		projectPanel(projectid: $projectid) {
			...panel
		}
	}
	${PANEL_FRAGMENT}
`;

const ENABLE_PROJECT_PANEL = gql`
	mutation _enableProjectPanel($projectid: String!) {
		enableProjectPanel(projectid: $projectid) {
			...panel
		}
	}
	${PANEL_FRAGMENT}
`;

const DISABLE_PROJECT_PANEL = gql`
	mutation _disableProjectPanel($projectid: String!) {
		disableProjectPanel(projectid: $projectid) {
			...panel
		}
	}
	${PANEL_FRAGMENT}
`;

const RESYNC_PANEL_MEMBERSHIP = gql`
	mutation _resycPanelMembership($projectid: String!) {
		resycPanelMembership(projectid: $projectid) {
			...panel
		}
	}
	${PANEL_FRAGMENT}
`;

const CREATE_PANEL_DASHBOARD = gql`
	mutation _createPanelDashboard($projectid: String, $name: String) {
		createPanelDashboard(projectid: $projectid, name: $name) {
			...dashboard
		}
	}
	${DASHBOARD_FRAGMENT}
`;

const UPDATE_PANEL_DASHBOARD = gql`
	mutation _updatePanelDashboard($projectid: String, $id: Int, $name: String) {
		updatePanelDashboard(projectid: $projectid, id: $id, name: $name) {
			...dashboard
		}
	}
	${DASHBOARD_FRAGMENT}
`;

const DELETE_PANEL_DASHBOARD = gql`
	mutation _deletePanelDashboard($projectid: String, $id: Int) {
		deletePanelDashboard(projectid: $projectid, id: $id) {
			...dashboard
		}
	}
	${DASHBOARD_FRAGMENT}
`;

export {
	QUERY_PROJECT_PANEL,
	ENABLE_PROJECT_PANEL,
	DISABLE_PROJECT_PANEL,
	RESYNC_PANEL_MEMBERSHIP,
	CREATE_PANEL_DASHBOARD,
	UPDATE_PANEL_DASHBOARD,
	DELETE_PANEL_DASHBOARD,
};
