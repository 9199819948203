import React from 'react';
import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import messages_en from 'translations/en.json';
import messages_th from 'translations/th.json';

const messages = { en: messages_en, th: messages_th };

const IntlAppProvider = ({ children, language }) => (
	<IntlProvider defaultLocale="en" locale={language} key={language} messages={messages[language]}>
		{children}
	</IntlProvider>
);

const mapStateToProps = ({ appSettingReducer }) => ({
	language: appSettingReducer.language,
});

export default connect(mapStateToProps)(IntlAppProvider);
