import { createTypes, createAction } from 'util/createActionHelper';

// TYPES
const LOAD_TRIGGERS = createTypes('load', 'triggers');
const LOAD_TRIGGER = createTypes('load', 'trigger');
const TOGGLE_ALL_TRIGGERS = createTypes('toggle', 'all_triggers');
const TOGGLE_TRIGGER = createTypes('toggle', 'trigger');
const DELETE_TRIGGER = createTypes('delete', 'trigger');
const CREATE_TRIGGER = createTypes('create', 'trigger');
const UPDATE_TRIGGER = createTypes('update', 'trigger');
const CLEAR_TRIGGER = createTypes('clear', 'trigger');

// ACTIONS
const loadTriggers = {
	request: (deviceid, type) => createAction(LOAD_TRIGGERS.REQUEST, { deviceid, type }),
	success: (triggers) => createAction(LOAD_TRIGGERS.SUCCESS, { triggers }),
	failure: (error) => createAction(LOAD_TRIGGERS.FAILURE, { error }),
};
const loadTrigger = {
	request: (deviceid, triggerid, type) => createAction(LOAD_TRIGGER.REQUEST, { deviceid, triggerid, type }),
	success: (trigger) => createAction(LOAD_TRIGGER.SUCCESS, { trigger }),
	failure: (error) => createAction(LOAD_TRIGGER.FAILURE, { error }),
};
const toggleAllTriggers = {
	request: (deviceid, status) => createAction(TOGGLE_ALL_TRIGGERS.REQUEST, { deviceid, status }),
	success: (triggers) => createAction(TOGGLE_ALL_TRIGGERS.SUCCESS, { triggers }),
	failure: (error) => createAction(TOGGLE_ALL_TRIGGERS.FAILURE, { error }),
};
const toggleTrigger = {
	request: (deviceid, triggerid, status) => createAction(TOGGLE_TRIGGER.REQUEST, { deviceid, triggerid, status }),
	success: (triggers) => createAction(TOGGLE_TRIGGER.SUCCESS, { triggers }),
	failure: (error) => createAction(TOGGLE_TRIGGER.FAILURE, { error }),
};
const deleteTrigger = {
	request: (deviceid, triggerid) => createAction(DELETE_TRIGGER.REQUEST, { deviceid, triggerid }),
	success: (triggers) => createAction(DELETE_TRIGGER.SUCCESS, { triggers }),
	failure: (error) => createAction(DELETE_TRIGGER.FAILURE, { error }),
};
const createTrigger = {
	request: (deviceid, trigger) => createAction(CREATE_TRIGGER.REQUEST, { deviceid, trigger }),
	success: (triggers) => createAction(CREATE_TRIGGER.SUCCESS, { triggers }),
	failure: (error) => createAction(CREATE_TRIGGER.FAILURE, { error }),
};
const updateTrigger = {
	request: (deviceid, trigger) => createAction(UPDATE_TRIGGER.REQUEST, { deviceid, trigger }),
	success: (triggers) => createAction(UPDATE_TRIGGER.SUCCESS, { triggers }),
	failure: (error) => createAction(UPDATE_TRIGGER.FAILURE, { error }),
};
const clearTrigger = {
	request: (deviceid) => createAction(CLEAR_TRIGGER.REQUEST, { deviceid }),
	success: (triggers) => createAction(CLEAR_TRIGGER.SUCCESS, { triggers }),
	failure: (error) => createAction(CLEAR_TRIGGER.FAILURE, { error }),
};

export {
	LOAD_TRIGGERS,
	loadTriggers,
	LOAD_TRIGGER,
	loadTrigger,
	TOGGLE_ALL_TRIGGERS,
	toggleAllTriggers,
	TOGGLE_TRIGGER,
	toggleTrigger,
	DELETE_TRIGGER,
	deleteTrigger,
	CREATE_TRIGGER,
	createTrigger,
	UPDATE_TRIGGER,
	updateTrigger,
	CLEAR_TRIGGER,
	clearTrigger,
};
