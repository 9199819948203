import gql from 'graphql-tag';

const USER_FRAGMENT = gql`
	fragment user on User {
		username
		email
		profile {
			fullname
			phonenumber
			organization
		}
		userid
	}
`;

const QUERY_USER = gql`
	query _userByUserID($userid: String!) {
		userByUserID(userid: $userid) {
			...user
		}
	}
	${USER_FRAGMENT}
`;

const UPDATE_USER = gql`
	mutation _updateUserProfile($fullname: String) {
		updateUserProfile(fullname: $fullname) {
			...user
		}
	}
	${USER_FRAGMENT}
`;

export { QUERY_USER, UPDATE_USER };
