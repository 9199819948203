import {
	GET_NON_STARTED_BILLING,
	LOAD_BILLING_ACCOUNT,
	LOAD_BILLING_ACCOUNT_LIST,
	LOAD_PROJECT_BILLING_ACCOUNT,
	START_BILLING_SUBSCRIPTION,
	LOAD_BILLING_SUBSCRIPTION_LIST,
} from '../actions/billingAccountActions';
import { shapeMessage } from 'util/openNotification';

const initialState = {
	billingSubscriptionList: [],
	billingAccountList: [],
	billingAccountData: null,
	loadingBilling: false,
	loading: false,
	message: null,
	projectBillingAccount: null,
	loadingList: false,
	loadingBillingSubscriptionList: false,
	nonStartedBillings: [],
	starting: false,
	msg: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOAD_BILLING_SUBSCRIPTION_LIST.REQUEST:
			return {
				...state,
				loadingBillingSubscriptionList: true,
				accountMessage: null,
			};
		case LOAD_BILLING_SUBSCRIPTION_LIST.SUCCESS:
			return {
				...state,
				billingSubscriptionList: action.payload.billingSubscriptionList,
				loadingBillingSubscriptionList: false,
			};
		case LOAD_BILLING_SUBSCRIPTION_LIST.FAILURE:
			return {
				...state,
				billingSubscriptionList: [],
				accountMessage: shapeMessage(action.type, action.payload.error),
				loadingBillingSubscriptionList: false,
			};
		case LOAD_BILLING_ACCOUNT.REQUEST:
			return {
				...state,
				loadingBilling: true,
				accountMessage: null,
			};
		case LOAD_BILLING_ACCOUNT.SUCCESS:
			return {
				...state,
				billingAccountData: action.payload.billingAccountData,
				accountMessage: shapeMessage(action.type),
				loadingBilling: false,
			};
		case LOAD_BILLING_ACCOUNT.FAILURE:
			return {
				...state,
				accountMessage: shapeMessage(action.type, action.payload.error),
				billingAccountData: false,
				loadingBilling: false,
			};

		// Project billing account
		case LOAD_PROJECT_BILLING_ACCOUNT.REQUEST:
			return {
				...state,
				loading: true,
				projectBillingAccount: null,
			};
		case LOAD_PROJECT_BILLING_ACCOUNT.SUCCESS:
			return {
				...state,
				projectBillingAccount: action.payload.billingAccountData,
				message: shapeMessage(action.type),
				loading: false,
			};
		case LOAD_PROJECT_BILLING_ACCOUNT.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				projectBillingAccount: null,
				loading: false,
			};

		//  Billing account list
		case LOAD_BILLING_ACCOUNT_LIST.REQUEST:
			return {
				...state,
				loadingList: true,
				billingAccountList: [],
			};
		case LOAD_BILLING_ACCOUNT_LIST.SUCCESS:
			return {
				...state,
				billingAccountList: action.payload.billingAccountList,
				message: shapeMessage(action.type),
				loadingList: false,
			};
		case LOAD_BILLING_ACCOUNT_LIST.FAILURE:
			return {
				...state,
				message: shapeMessage(action.type, action.payload.error),
				billingAccountList: [],
				loadingList: false,
			};

		case GET_NON_STARTED_BILLING.SUCCESS:
			return {
				...state,
				nonStartedBillings: action.payload.nonStartedBillings,
			};
		case GET_NON_STARTED_BILLING.FAILURE:
			return {
				...state,
				msg: shapeMessage(action.type, action.payload.error),
				nonStartedBillings: [],
			};
		case START_BILLING_SUBSCRIPTION.REQUEST:
			return {
				...state,
				starting: true,
				msg: null,
			};
		case START_BILLING_SUBSCRIPTION.SUCCESS:
			return {
				...state,
				starting: false,
				nonStartedBillings: action.payload.nonStartedBillings,
				msg: shapeMessage(action.type),
			};
		case START_BILLING_SUBSCRIPTION.FAILURE:
			return {
				...state,
				starting: false,
				msg: shapeMessage(action.type, action.payload.error),
			};

		default:
			return state;
	}
};
