/* eslint-disable no-unused-vars */
import { all, put, call, takeLatest } from 'redux-saga/effects';
import { push, replace } from 'connected-react-router';
import * as actions from '../actions/projectActions';
import { apolloQuery, apolloMutation } from '../../util/apollo';
import {
	QUERY_PROJECT_LIST,
	QUERY_PROJECT,
	CREATE_PROJECT,
	UPDATE_PROJECT,
	DELETE_PROJECT,
} from '../../util/apollo/nexpieGraphQL/project';
import { QUERY_DEVICE_LIST_STATUS } from '../../util/apollo/nexpieGraphQL/device';
import { QUERY_MEMBERSHIP_LIST } from '../../util/apollo/nexpieGraphQL/membership';
import { parseMemberLevel } from 'util/ParseData';

function* loadProjects() {
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PROJECT_LIST));
		// const { data, errors } = mock

		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadProjects.success(data.projectList));
		}
	} catch (error) {
		yield put(actions.loadProjects.failure(error));
	}
}

function* loadProject(action) {
	try {
		const { projectId } = action.payload;
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: projectId }));
		if (!data.project) throw new Error('Invalid Projectid');
		if (errors) throw errors;

		yield put(actions.loadProject.success(data.project));
	} catch (error) {
		yield put(actions.loadProject.failure(error));
	}
}

function* createProject(action) {
	try {
		const { project } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(CREATE_PROJECT, {
					projectname: project.projectname,
					billingaccountid: project.billingaccountid,
					description: project.description,
					tag: project.tag,
					hashtag: project.hashtag,
					tags: project.tags,
				})
		);

		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PROJECT_LIST));

		if (mutationRes.errors || errors) {
			throw mutationRes.errors || errors;
		} else {
			// const prevProjects = yield select(({ projectReducer }) => projectReducer.projects);
			// const newProjects = [...prevProjects, data.createProject];

			yield put(actions.createProject.success(data.projectList, mutationRes.data.createProject));
			yield put(replace(`/p/${mutationRes.data.createProject.projectid}`));
		}
	} catch (error) {
		yield put(actions.createProject.failure(error));
	}
}

function* updateProject(action) {
	try {
		const { projectId, project } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(UPDATE_PROJECT, {
					projectid: projectId,
					projectname: project.projectname,
					description: project.description,
					tag: project.tag,
					hashtag: project.hashtag,
					tags: project.tags,
				})
		);

		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PROJECT_LIST));

		if (mutationRes.errors || errors) {
			throw mutationRes.errors || errors;
		}

		yield put(actions.updateProject.success(data.projectList, mutationRes.data.updateProject));
	} catch (error) {
		yield put(actions.updateProject.failure(error));
	}
}

function* deleteProject(action) {
	try {
		const { projectId } = action.payload;
		const mutationRes = yield call(async () => await apolloMutation(DELETE_PROJECT, { projectid: projectId }));

		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PROJECT_LIST));

		if (mutationRes.errors || errors) {
			throw mutationRes.errors || errors;
		} else {
			if (mutationRes?.data?.deleteProject?.projectid === null) {
				yield put(actions.deleteProject.failure({ message: 'Something wrong' }));
			} else {
				yield put(actions.deleteProject.success(data.projectList));
				yield put(push('/'));
			}
		}
	} catch (error) {
		yield put(actions.deleteProject.failure(error));
	}
}

export default function* watchProjects() {
	yield all([
		takeLatest(actions.LOAD_PROJECTS.REQUEST, loadProjects),
		takeLatest(actions.LOAD_PROJECT.REQUEST, loadProject),
		takeLatest(actions.CREATE_PROJECT.REQUEST, createProject),
		takeLatest(actions.UPDATE_PROJECT.REQUEST, updateProject),
		takeLatest(actions.DELETE_PROJECT.REQUEST, deleteProject),
	]);
}
