import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import * as actions from '../actions/dashboardActions';
import { apolloQuery, apolloMutation } from '../../util/apollo';
import {
	QUERY_DASHBOARD_LIST,
	QUERY_DASHBOARD,
	CREATE_DASHBOARD,
	UPDATE_DASHBOARD,
	DELETE_DASHBOARD,
	QUERY_PUBLIC_SHARED,
	CREATE_PUBLIC_SHARED,
	UPDATE_PUBLIC_SHARED,
	DELETE_PUBLIC_SHARED,
	QUERY_PRIVATE_SHARED,
	CREATE_USER_SHARE,
	UPDATE_USER_SHARE,
	DELETE_USER_SHARE,
} from '../../util/apollo/nexpieGraphQL/dashboard';
import {
	QUERY_DASHBOARD_GROUP_LIST,
	CREATE_DASHBOARD_GROUP,
	UPDATE_DASHBOARD_GROUP,
	DELETE_DASHBOARD_GROUP,
} from '../../util/apollo/nexpieGraphQL/dashboardGroup';
import { QUERY_FLOWAGENT_LIST } from '../../util/apollo/nexpieGraphQL/flowagent';

function* loadDashboards(action) {
	try {
		const { projectId } = action.payload;

		const dashboards = yield call(async () => await apolloQuery(QUERY_DASHBOARD_LIST, { projectid: projectId }));

		const dashboard_sessions = yield call(
			async () => await apolloQuery(QUERY_DASHBOARD_GROUP_LIST, { projectid: projectId })
		);

		const flowagents = yield call(async () => await apolloQuery(QUERY_FLOWAGENT_LIST, { projectid: projectId }));

		if (dashboards.errors || dashboard_sessions.errors || flowagents.errors) {
			throw dashboards.errors || dashboard_sessions.errors || flowagents.errors;
		} else {
			dashboard_sessions.data.dashboardGroupList.map((session) => {
				const flowagent = flowagents.data.flowagentList.find(
					(flowagent) => flowagent.flowagentid === session.flowagentid
				);
				session.instance = flowagent.instance;
				session.active = flowagent.active;
				return null;
			});
			const newDashboardSessions = dashboard_sessions.data.dashboardGroupList;
			const newDashboards = dashboards.data.dashboardList;
			yield put(actions.loadDashboards.success(newDashboardSessions, newDashboards));
		}
	} catch (error) {
		yield put(actions.loadDashboards.failure(error));
	}
}

function* loadDashboard(action) {
	try {
		const { dashboardId } = action.payload;
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_DASHBOARD, { dashboardid: dashboardId }));

		if (errors) {
			yield put(actions.loadDashboard.failure(errors[0]));
		} else {
			yield put(actions.loadDashboard.success(data.dashboard));
		}
	} catch (error) {
		yield put(actions.loadDashboard.failure(error));
	}
}

function* createDashboard(action) {
	try {
		const { dashboard } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(CREATE_DASHBOARD, {
					projectid: dashboard.projectid,
					name: dashboard.name,
					type: dashboard.type,
					shared: dashboard.shared,
					description: dashboard.description,
					dashboardgroupid: dashboard.dashboardgroupid,
				})
		);
		const dashboard_sessions = yield call(
			async () => await apolloQuery(QUERY_DASHBOARD_GROUP_LIST, { projectid: dashboard.projectid })
		);
		const dashboards = yield call(
			async () => await apolloQuery(QUERY_DASHBOARD_LIST, { projectid: dashboard.projectid })
		);
		const flowagents = yield call(
			async () => await apolloQuery(QUERY_FLOWAGENT_LIST, { projectid: dashboard.projectid })
		);

		if (mutationRes.errors || dashboards.errors || flowagents.errors || dashboard_sessions.errors) {
			yield put(
				actions.createDashboard.failure(
					mutationRes.errors || dashboards.errors || flowagents.errors || dashboard_sessions.errors
				)
			);
		} else {
			if (mutationRes.data.createDashboard) {
				dashboard_sessions.data.dashboardGroupList.map((session) => {
					const flowagent = flowagents.data.flowagentList.find(
						(flowagent) => flowagent.flowagentid === session.flowagentid
					);
					session.instance = flowagent.instance;
					session.active = flowagent.active;
					return null;
				});
				const newDashboardGroups = dashboard_sessions.data.dashboardGroupList;
				const newDashboards = dashboards.data.dashboardList;

				yield put(actions.createDashboard.success(newDashboardGroups, newDashboards, mutationRes.data.createDashboard));
			} else {
				throw mutationRes.errors;
			}
		}
	} catch (error) {
		yield put(actions.createDashboard.failure(error));
	}
}

function* updateDashboard(action) {
	try {
		const { dashboardId, dashboard } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(UPDATE_DASHBOARD, {
					dashboardid: dashboardId,
					name: dashboard.name,
					type: dashboard.type,
					shared: dashboard.shared,
					description: dashboard.description,
				})
		);

		const dashboard_sessions = yield call(
			async () => await apolloQuery(QUERY_DASHBOARD_GROUP_LIST, { projectid: dashboard.projectid })
		);
		const dashboards = yield call(
			async () => await apolloQuery(QUERY_DASHBOARD_LIST, { projectid: dashboard.projectid })
		);
		const flowagents = yield call(
			async () => await apolloQuery(QUERY_FLOWAGENT_LIST, { projectid: dashboard.projectid })
		);

		if (mutationRes.errors || dashboard_sessions.errors || dashboards.errors || flowagents.errors) {
			yield put(
				actions.updateDashboard.failure(
					mutationRes.errors || dashboards.errors || flowagents.errors || dashboard_sessions.errors
				)
			);
		} else {
			if (mutationRes.data.updateDashboard) {
				dashboard_sessions.data.dashboardGroupList.map((session) => {
					const flowagent = flowagents.data.flowagentList.find(
						(flowagent) => flowagent.flowagentid === session.flowagentid
					);
					session.instance = flowagent.instance;
					session.active = flowagent.active;
					return null;
				});
				const newDashboardGroups = dashboard_sessions.data.dashboardGroupList;
				const newDashboards = dashboards.data.dashboardList;
				const newDashboard = newDashboards.find(
					(dashboard) => dashboard.dashboardid === mutationRes.data.updateDashboard.dashboardid
				);
				yield put(actions.updateDashboard.success(newDashboardGroups, newDashboards, newDashboard));
			} else {
				throw mutationRes.errors;
			}
		}
	} catch (error) {
		yield put(actions.updateDashboard.failure(error));
	}
}

function* deleteDashboard(action) {
	try {
		const { dashboardId, projectId } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(DELETE_DASHBOARD, {
					dashboardid: dashboardId,
				})
		);

		const dashboard_sessions = yield call(
			async () => await apolloQuery(QUERY_DASHBOARD_GROUP_LIST, { projectid: projectId })
		);
		const dashboards = yield call(async () => await apolloQuery(QUERY_DASHBOARD_LIST, { projectid: projectId }));
		const flowagents = yield call(async () => await apolloQuery(QUERY_FLOWAGENT_LIST, { projectid: projectId }));

		if (mutationRes.errors || dashboards.errors || flowagents.errors || dashboard_sessions.errors) {
			yield put(
				actions.deleteDashboard.failure(
					mutationRes.errors || dashboards.errors || flowagents.errors || dashboard_sessions.errors
				)
			);
		} else {
			if (mutationRes.data.deleteDashboard) {
				dashboard_sessions.data.dashboardGroupList.map((session) => {
					const flowagent = flowagents.data.flowagentList.find(
						(flowagent) => flowagent.flowagentid === session.flowagentid
					);
					session.instance = flowagent.instance;
					session.active = flowagent.active;
					return null;
				});
				const newDashboardGroups = dashboard_sessions.data.dashboardGroupList;
				const newDashboards = dashboards.data.dashboardList;

				yield put(actions.deleteDashboard.success(newDashboardGroups, newDashboards, mutationRes.data.deleteDashboard));
			} else {
				throw mutationRes.errors;
			}
		}
	} catch (error) {
		yield put(actions.deleteDashboard.failure(error));
	}
}

function* createSession(action) {
	try {
		const { session } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(CREATE_DASHBOARD_GROUP, {
					projectid: session.projectid,
					name: session.name,
					instance: session.instance,
					description: session.description,
				})
		);

		const dashboard_sessions = yield call(
			async () => await apolloQuery(QUERY_DASHBOARD_GROUP_LIST, { projectid: session.projectid })
		);

		const dashboards = yield call(
			async () => await apolloQuery(QUERY_DASHBOARD_LIST, { projectid: session.projectid })
		);
		const flowagents = yield call(
			async () => await apolloQuery(QUERY_FLOWAGENT_LIST, { projectid: session.projectid })
		);

		if (mutationRes.errors || dashboards.errors || flowagents.errors || dashboard_sessions.errors) {
			yield put(
				actions.createSession.failure(
					mutationRes.errors || dashboards.errors || flowagents.errors || dashboard_sessions.errors
				)
			);
		} else {
			if (mutationRes.data.createDashboardGroup) {
				dashboard_sessions.data.dashboardGroupList.map((session) => {
					const flowagent = flowagents.data.flowagentList.find(
						(flowagent) => flowagent.flowagentid === session.flowagentid
					);
					session.instance = flowagent.instance;
					session.active = flowagent.active;
					return null;
				});
				const newDashboardGroups = dashboard_sessions.data.dashboardGroupList;
				const newDashboards = dashboards.data.dashboardList;

				yield put(
					actions.createSession.success(newDashboardGroups, newDashboards, mutationRes.data.createDashboardGroup)
				);
			} else {
				throw mutationRes.errors;
			}
		}
	} catch (error) {
		yield put(actions.createSession.failure(error));
	}
}

function* updateSession(action) {
	try {
		const { sessionId, session } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(UPDATE_DASHBOARD_GROUP, {
					dashboardgroupid: sessionId,
					name: session.name,
					description: session.description,
					instance: session.instance,
				})
		);

		const dashboard_sessions = yield call(
			async () => await apolloQuery(QUERY_DASHBOARD_GROUP_LIST, { projectid: session.projectid })
		);
		const dashboards = yield call(
			async () => await apolloQuery(QUERY_DASHBOARD_LIST, { projectid: session.projectid })
		);
		const flowagents = yield call(
			async () => await apolloQuery(QUERY_FLOWAGENT_LIST, { projectid: session.projectid })
		);

		if (mutationRes.errors || dashboards.errors || flowagents.errors || dashboard_sessions.errors) {
			yield put(
				actions.updateSession.failure(
					mutationRes.errors || dashboards.errors || flowagents.errors || dashboard_sessions.errors
				)
			);
		} else {
			if (mutationRes.data.updateDashboardGroup) {
				dashboard_sessions.data.dashboardGroupList.map((session) => {
					const flowagent = flowagents.data.flowagentList.find(
						(flowagent) => flowagent.flowagentid === session.flowagentid
					);
					session.instance = flowagent.instance;
					session.active = flowagent.active;
					return null;
				});
				const newDashboardGroups = dashboard_sessions.data.dashboardGroupList;
				const newDashboards = dashboards.data.dashboardList;

				const updateDashboardGroup = newDashboardGroups.find(
					(session) => session.dashboardgroupid === mutationRes.data.updateDashboardGroup.dashboardgroupid
				);

				yield put(actions.updateSession.success(newDashboardGroups, newDashboards, updateDashboardGroup));
			} else {
				throw mutationRes.errors;
			}
		}
	} catch (error) {
		yield put(actions.updateSession.failure(error));
	}
}

function* deleteSession(action) {
	try {
		const { sessionId, projectId } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(DELETE_DASHBOARD_GROUP, {
					dashboardgroupid: sessionId,
				})
		);
		const dashboard_sessions = yield call(
			async () => await apolloQuery(QUERY_DASHBOARD_GROUP_LIST, { projectid: projectId })
		);
		const dashboards = yield call(async () => await apolloQuery(QUERY_DASHBOARD_LIST, { projectid: projectId }));
		const flowagents = yield call(async () => await apolloQuery(QUERY_FLOWAGENT_LIST, { projectid: projectId }));

		if (mutationRes.errors || dashboards.errors || flowagents.errors || dashboard_sessions.errors) {
			yield put(
				actions.deleteSession.failure(
					mutationRes.errors || dashboards.errors || flowagents.errors || dashboard_sessions.errors
				)
			);
		} else {
			if (mutationRes.data.deleteDashboardGroup) {
				dashboard_sessions.data.dashboardGroupList.map((session) => {
					const flowagent = flowagents.data.flowagentList.find(
						(flowagent) => flowagent.flowagentid === session.flowagentid
					);
					session.instance = flowagent.instance;
					session.active = flowagent.active;
					return null;
				});
				const newDashboardGroups = dashboard_sessions.data.dashboardGroupList;
				const newDashboards = dashboards.data.dashboardList;

				yield put(
					actions.deleteSession.success(newDashboardGroups, newDashboards, mutationRes.data.deleteDashboardGroup)
				);
			} else {
				throw mutationRes.errors;
			}
		}
	} catch (error) {
		yield put(actions.deleteSession.failure(error));
	}
}

// ----- Public Shared -----
function* loadPublicShared(action) {
	try {
		// Extract the dashid from the action payload
		const { dashid } = action.payload;

		// Make an asynchronous API call to retrieve the public shared data
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PUBLIC_SHARED, { dashid }));

		// If there are any errors, throw them
		if (errors) throw errors;

		// If there is at least one item in the data array, dispatch the success action with the first item
		if (data.simpleSharingList.length > 0) {
			yield put(actions.loadPublicShared.success(data.simpleSharingList[0]));
		} else {
			// If there are no items in the data array, create a new public shared item
			const createShared = yield call(async () => await apolloMutation(CREATE_PUBLIC_SHARED, { dashid }));

			// If there are any errors while creating the item, throw them
			if (createShared.errors) throw createShared.errors;

			// Dispatch the success action with the newly created item
			yield put(actions.loadPublicShared.success(createShared.data.createSimpleSharing));
		}
	} catch (error) {
		// If any error occurs during the process, dispatch the failure action with the error
		yield put(actions.loadPublicShared.failure(error));
	}
}
function* updatePublicShared(action) {
	try {
		// Destructure the 'dashid' and 'config' properties from the 'payload' object of the 'action' parameter
		const { dashid, config } = action.payload;

		// Make an asynchronous call to the 'apolloMutation' function, passing in the 'UPDATE_PUBLIC_SHARED' mutation and the 'dashid' and 'config' variables
		const { data, errors } = yield call(async () => await apolloMutation(UPDATE_PUBLIC_SHARED, { dashid, ...config }));

		// If there are any errors, throw them
		if (errors) throw errors;

		// If the 'data.updateSimpleSharing' property exists, dispatch the 'updatePublicShared.success' action with the first element of the 'data.updateSimpleSharing' array
		if (data.updateSimpleSharing) {
			yield put(actions.updatePublicShared.success(data.updateSimpleSharing[0]));
		}
	} catch (error) {
		// If there's an error, dispatch the 'updatePublicShared.failure' action with the error
		yield put(actions.updatePublicShared.failure(error));
	}
}
function* regeneratePublicShared(action) {
	try {
		const { dashid } = action.payload;

		const deleteShared = yield call(async () => await apolloMutation(DELETE_PUBLIC_SHARED, { dashid }));
		if (deleteShared.errors) throw deleteShared.errors;

		if (deleteShared.data) {
			const { data, errors } = yield call(async () => await apolloMutation(CREATE_PUBLIC_SHARED, { dashid, level: 1 }));
			if (errors) throw errors;

			if (data.createSimpleSharing) {
				yield put(actions.regeneratePublicShared.success(data.createSimpleSharing));
			}
		}
	} catch (error) {
		yield put(actions.regeneratePublicShared.failure(error));
	}
}

// ----- Private shared -----
function* loadPrivateShared(action) {
	try {
		const { dashid } = action.payload;

		const { data, errors } = yield call(async () => await apolloQuery(QUERY_PRIVATE_SHARED, { dashid }));
		if (errors) throw errors;

		yield put(actions.loadPrivateShared.success(data.dashUserShare));
	} catch (error) {
		yield put(actions.loadPrivateShared.failure(error));
	}
}
function* inviteToDash(action) {
	try {
		const { dashid, username, level } = action.payload;
		const { data, errors } = yield call(
			async () => await apolloMutation(CREATE_USER_SHARE, { dashid, username, level })
		);
		if (errors) throw errors;
		const prevUserShare = yield select(({ dashboardReducer }) => dashboardReducer.privateShared);
		const newUserShare = [...prevUserShare, data.createDashUserShare];

		yield put(actions.inviteToDash.success(newUserShare));
	} catch (error) {
		yield put(actions.inviteToDash.failure(error));
	}
}
function* updateShareLevel(action) {
	try {
		const { dashid, username, level } = action.payload;
		const { errors } = yield call(async () => await apolloMutation(UPDATE_USER_SHARE, { dashid, username, level }));
		if (errors) throw errors;

		const prevUserShare = yield select(({ dashboardReducer }) => dashboardReducer.privateShared);
		const newUserShare = prevUserShare.map((user) => {
			if (user.username === username) {
				user.level = level;
			}
			return user;
		});
		yield put(actions.updateShareLvl.success(newUserShare));
	} catch (error) {
		yield put(actions.updateShareLvl.failure(error));
	}
}
function* removeShared(action) {
	try {
		const { dashid, username } = action.payload;
		const { errors } = yield call(async () => await apolloMutation(DELETE_USER_SHARE, { dashid, username }));
		if (errors) throw errors;

		const prevUserShare = yield select(({ dashboardReducer }) => dashboardReducer.privateShared);
		const newUserShare = prevUserShare.filter((user) => user.username !== username);

		yield put(actions.removeShared.success(newUserShare));
	} catch (error) {
		yield put(actions.removeShared.failure(error));
	}
}

export default function* watchDashboards() {
	yield all([
		takeLatest(actions.LOAD_DASHBOARDS.REQUEST, loadDashboards),
		takeLatest(actions.LOAD_DASHBOARD.REQUEST, loadDashboard),
		takeLatest(actions.CREATE_DASHBOARD.REQUEST, createDashboard),
		takeLatest(actions.UPDATE_DASHBOARD.REQUEST, updateDashboard),
		takeLatest(actions.DELETE_DASHBOARD.REQUEST, deleteDashboard),
		takeLatest(actions.CREATE_SESSION.REQUEST, createSession),
		takeLatest(actions.UPDATE_SESSION.REQUEST, updateSession),
		takeLatest(actions.DELETE_SESSION.REQUEST, deleteSession),

		takeLatest(actions.GET_PUBLIC_SHARED.REQUEST, loadPublicShared),
		takeLatest(actions.UPDATE_PUBLIC_SHARED.REQUEST, updatePublicShared),
		takeLatest(actions.REGENERATE_PUBLIC_SHARED.REQUEST, regeneratePublicShared),

		takeLatest(actions.GET_PRIVATE_SHARED.REQUEST, loadPrivateShared),
		takeLatest(actions.INVITE_TO_DASH.REQUEST, inviteToDash),
		takeLatest(actions.UPDATE_SHARE_LVL.REQUEST, updateShareLevel),
		takeLatest(actions.REMOVE_SHARED.REQUEST, removeShared),
	]);
}
