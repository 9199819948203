import { createTypes, createAction } from 'util/createActionHelper';

const LOAD_DEVICE_GROUPS = createTypes('get', 'Groups');
const LOAD_DEVICE_GROUP = createTypes('get', 'Group');
const CREATE_DEVICE_GROUP = createTypes('create', 'Group');
const UPDATE_DEVICE_GROUP = createTypes('edit', 'Group');
const DELETE_DEVICE_GROUP = createTypes('delete', 'Group');

const loadDeviceGroups = {
	request: projectId => createAction(LOAD_DEVICE_GROUPS.REQUEST, { projectId }),
	success: deviceGroups => createAction(LOAD_DEVICE_GROUPS.SUCCESS, { deviceGroups }),
	failure: error => createAction(LOAD_DEVICE_GROUPS.FAILURE, { error }),
};

const loadDeviceGroup = {
	request: (projectId, deviceGroupId) => createAction(LOAD_DEVICE_GROUP.REQUEST, { projectId, deviceGroupId }),
	success: devices => createAction(LOAD_DEVICE_GROUP.SUCCESS, { devices }),
	failure: error => createAction(LOAD_DEVICE_GROUP.FAILURE, { error }),
};

const createDeviceGroup = {
	request: deviceGroup => createAction(CREATE_DEVICE_GROUP.REQUEST, { deviceGroup }),
	success: (deviceGroups, deviceGroup) => createAction(CREATE_DEVICE_GROUP.SUCCESS, { deviceGroups, deviceGroup }),
	failure: error => createAction(CREATE_DEVICE_GROUP.FAILURE, { error }),
};

const updateDeviceGroup = {
	request: (deviceGroupId, deviceGroup) => createAction(UPDATE_DEVICE_GROUP.REQUEST, { deviceGroupId, deviceGroup }),
	success: (deviceGroups, deviceGroup) => createAction(UPDATE_DEVICE_GROUP.SUCCESS, { deviceGroups, deviceGroup }),
	failure: error => createAction(UPDATE_DEVICE_GROUP.FAILURE, { error }),
};

const deleteDeviceGroup = {
	request: (projectId, deviceGroupId) => createAction(DELETE_DEVICE_GROUP.REQUEST, { projectId, deviceGroupId }),
	success: (deviceGroups, deviceGroup) => createAction(DELETE_DEVICE_GROUP.SUCCESS, { deviceGroups, deviceGroup }),
	failure: error => createAction(DELETE_DEVICE_GROUP.FAILURE, { error }),
};

export {
	LOAD_DEVICE_GROUPS,
	loadDeviceGroups,
	LOAD_DEVICE_GROUP,
	loadDeviceGroup,
	CREATE_DEVICE_GROUP,
	createDeviceGroup,
	UPDATE_DEVICE_GROUP,
	updateDeviceGroup,
	DELETE_DEVICE_GROUP,
	deleteDeviceGroup,
};
