import gql from 'graphql-tag';

export const FREEBOARD_FRAGMENT = gql`
	fragment freeboard on Freeboard {
		dashboardid
		name
		type
		shared
		description
		createdtime
	}
`;

// QUERY
const QUERY_FREEBOARDS = gql`
	query ($projectid: String) {
		freeboardList(filter: { projectid: $projectid }) {
			...freeboard
		}
	}
	${FREEBOARD_FRAGMENT}
`;
const QUERY_FREEBOARD = gql`
	query ($dashboardid: String!) {
		freeboard(dashboardid: $dashboardid) {
			...freeboard
		}
	}
	${FREEBOARD_FRAGMENT}
`;

// CRUD
const CREATE_FREEBOARD = gql`
	mutation ($projectid: String!, $name: String!, $type: String, $shared: Boolean, $description: String) {
		createFreeboard(projectid: $projectid, name: $name, type: $type, shared: $shared, description: $description) {
			...freeboard
		}
	}
	${FREEBOARD_FRAGMENT}
`;
const UPDATE_FREEBOARD = gql`
	mutation ($dashboardid: String!, $name: String!, $type: String, $shared: Boolean, $description: String) {
		updateFreeboard(dashboardid: $dashboardid, name: $name, type: $type, shared: $shared, description: $description) {
			...freeboard
		}
	}
	${FREEBOARD_FRAGMENT}
`;
const DELETE_FREEBOARD = gql`
	mutation ($dashboardid: String!) {
		deleteFreeboard(dashboardid: $dashboardid) {
			...freeboard
		}
	}
	${FREEBOARD_FRAGMENT}
`;

export { QUERY_FREEBOARDS, QUERY_FREEBOARD, CREATE_FREEBOARD, UPDATE_FREEBOARD, DELETE_FREEBOARD };
