import {
	LOAD_DASH,
	LOAD_DASH_LIST,
	CREATE_DASH,
	DELETE_DASH,
	LOAD_DASH_TOKEN,
	CREATE_DASH_TOKEN,
	LOAD_DASH_RESOURCE,
	CREATE_DASH_RESOURCE,
	UPDATE_DASH_RESOURCE,
	DELETE_DASH_RESOURCE,
	DELETE_ALL_DASH_RESOURCE,
	UPDATE_DASH,
	CLEAR_DASH_MSG,
} from 'reduxStore/actions/freeboardNewActions';
import { shapeMessage } from 'util/openNotification';

const initState = {
	// dash
	dash: null,
	dashList: [],
	loading: false,
	msg: null,

	// dash token
	dashTokenList: [],
	tokenLoading: false,
	tokenMsg: null,

	// dash resource
	dashResource: [],
	deviceList: [],
	resourceLoading: false,
	resourceMsg: null,

	crudLoading: false,
};

export default (state = initState, action) => {
	switch (action.type) {
		// dash
		case LOAD_DASH.REQUEST:
		case LOAD_DASH_LIST.REQUEST:
			return {
				...state,
				loading: true,
				msg: null,
			};
		case LOAD_DASH.SUCCESS:
			return {
				...state,
				loading: false,
				dash: action.payload.dash,
			};
		case LOAD_DASH_LIST.SUCCESS:
			return {
				...state,
				loading: false,
				dashList: action.payload.dashList,
			};
		case CREATE_DASH.REQUEST:
		case UPDATE_DASH.REQUEST:
		case DELETE_DASH.REQUEST:
			return {
				...state,
				crudLoading: true,
				msg: null,
			};
		case CREATE_DASH.SUCCESS:
		case UPDATE_DASH.SUCCESS:
		case DELETE_DASH.SUCCESS:
			return {
				...state,
				crudLoading: false,
				dashList: action.payload.dashList,
				msg: shapeMessage(action.type),
			};
		case LOAD_DASH.FAILURE:
		case LOAD_DASH_LIST.FAILURE:
			return {
				...state,
				loading: false,
				msg: shapeMessage(action.type, action.payload.error),
			};
		case CREATE_DASH.FAILURE:
		case UPDATE_DASH.FAILURE:
		case DELETE_DASH.FAILURE:
			return {
				...state,
				crudLoading: false,
				msg: shapeMessage(action.type, action.payload.error),
			};

		// dash token
		case LOAD_DASH_TOKEN.REQUEST:
			return {
				...state,
				tokenLoading: true,
			};
		case LOAD_DASH_TOKEN.SUCCESS:
			return {
				...state,
				tokenLoading: false,
				dashTokenList: action.payload.dashTokenList,
			};
		case LOAD_DASH_TOKEN.FAILURE:
			return {
				...state,
				tokenLoading: false,
				tokenMsg: shapeMessage(action.type, action.payload.error),
			};
		case CREATE_DASH_TOKEN.REQUEST:
			return {
				...state,
				crudLoading: true,
				tokenMsg: null,
			};
		case CREATE_DASH_TOKEN.SUCCESS:
			return {
				...state,
				crudLoading: false,
				dashTokenList: action.payload.dashTokenList,
			};
		case CREATE_DASH_TOKEN.FAILURE:
			return {
				...state,
				crudLoading: false,
				tokenMsg: shapeMessage(action.type, action.payload.error),
			};

		// dash resource
		case LOAD_DASH_RESOURCE.REQUEST:
			return {
				...state,
				resourceLoading: true,
				resourceMsg: null,
			};
		case LOAD_DASH_RESOURCE.SUCCESS:
			return {
				...state,
				resourceLoading: false,
				dashResource: action.payload.dashResource,
				deviceList: action.payload.deviceList,
			};
		case LOAD_DASH_RESOURCE.FAILURE:
			return {
				...state,
				resourceLoading: false,
				resourceMsg: shapeMessage(action.type, action.payload.error),
			};
		case CREATE_DASH_RESOURCE.REQUEST:
		case UPDATE_DASH_RESOURCE.REQUEST:
		case DELETE_DASH_RESOURCE.REQUEST:
		case DELETE_ALL_DASH_RESOURCE.REQUEST:
			return {
				...state,
				crudLoading: true,
				resourceMsg: null,
			};
		case CREATE_DASH_RESOURCE.SUCCESS:
		case UPDATE_DASH_RESOURCE.SUCCESS:
		case DELETE_DASH_RESOURCE.SUCCESS:
		case DELETE_ALL_DASH_RESOURCE.SUCCESS:
			return {
				...state,
				crudLoading: false,
				dashResource: action.payload.dashResource,
				resourceMsg: shapeMessage(action.type),
			};
		case CREATE_DASH_RESOURCE.FAILURE:
		case UPDATE_DASH_RESOURCE.FAILURE:
		case DELETE_DASH_RESOURCE.FAILURE:
		case DELETE_ALL_DASH_RESOURCE.FAILURE:
			return {
				...state,
				crudLoading: false,
				resourceMsg: shapeMessage(action.type, action.payload.error),
			};

		case CLEAR_DASH_MSG:
			return {
				...state,
				msg: null,
				resourceMsg: null,
				tokenMsg: null,
			};
		default:
			return state;
	}
};
