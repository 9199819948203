/* eslint-disable no-unused-vars */
import { all, put, call, takeLatest, select } from 'redux-saga/effects';
import * as actions from '../actions/logActions';
import { apolloQuery } from 'util/apollo';
import { QUERY_BILLING_LOG } from 'util/apollo/nexpieGraphQL/log';
import { QUERY_MY_BILLING_ACCOUNT } from 'util/apollo/nexpieGraphQL/billingAccount';

function* loadBillingLog(action) {
	const {
		logData: { billingid, begintime, endtime, direction, filter, isLoadMore },
	} = action.payload;
	try {
		// get billing id
		const mybilling = yield call(async () => await apolloQuery(QUERY_MY_BILLING_ACCOUNT));
		const { billingaccountid } = mybilling.data.MyBillingAccount[0];

		const prevLog = yield select(({ logReducer }) => logReducer.log);
		const { data, errors } = yield call(
			async () =>
				await apolloQuery(QUERY_BILLING_LOG, {
					billingaccountid: billingid ? billingid : billingaccountid,
					begintime,
					endtime,
					direction,
					limit: 50,
					offset: isLoadMore ? prevLog.length : 0,
					filter,
				})
		);

		if (errors) {
			throw errors;
		} else {
			const LOG = isLoadMore ? prevLog.concat(data.BillingLog) : data.BillingLog;
			yield put(actions.loadBillingLog.success(LOG));
		}
	} catch (error) {
		yield put(actions.loadBillingLog.failure(error));
	}
}

export default function* watchLog() {
	yield all([takeLatest(actions.LOAD_BILLING_LOG.REQUEST, loadBillingLog)]);
}
