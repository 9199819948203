import gql from 'graphql-tag';

const DASHBOARD_GROUP_FRAGMENT = gql`
	fragment dashboardGroup on DashboardGroup {
		dashboardgroupid
		projectid
		flowagentid
		name
		description
		createdtime
	}
`;

const QUERY_DASHBOARD_GROUP_LIST = gql`
	query _dashboardGroupList($projectid: String!) {
		dashboardGroupList(filter: { projectid: $projectid }, limit: "100", orderBy: createdtime, direction: DESC) {
			...dashboardGroup
		}
	}
	${DASHBOARD_GROUP_FRAGMENT}
`;

const QUERY_DASHBOARD_GROUP = gql`
	query _dashboardGroup($dashboardgroupid: String!) {
		dashboardGroup(dashboardgroupid: $dashboardgroupid) {
			...dashboardGroup
		}
	}
	${DASHBOARD_GROUP_FRAGMENT}
`;

const CREATE_DASHBOARD_GROUP = gql`
	mutation _createDashboardGroup($projectid: String!, $name: String!, $description: String, $instance: Int!) {
		createDashboardGroup(projectid: $projectid, name: $name, instance: $instance, description: $description) {
			...dashboardGroup
		}
	}
	${DASHBOARD_GROUP_FRAGMENT}
`;

const UPDATE_DASHBOARD_GROUP = gql`
	mutation _updateDashboardGroup($dashboardgroupid: String!, $name: String!, $description: String, $instance: Int) {
		updateDashboardGroup(
			dashboardgroupid: $dashboardgroupid
			name: $name
			description: $description
			instance: $instance
		) {
			...dashboardGroup
		}
	}
	${DASHBOARD_GROUP_FRAGMENT}
`;

const DELETE_DASHBOARD_GROUP = gql`
	mutation _deleteDashboardGroup($dashboardgroupid: String!) {
		deleteDashboardGroup(dashboardgroupid: $dashboardgroupid) {
			...dashboardGroup
		}
	}
	${DASHBOARD_GROUP_FRAGMENT}
`;

export {
	QUERY_DASHBOARD_GROUP_LIST,
	QUERY_DASHBOARD_GROUP,
	CREATE_DASHBOARD_GROUP,
	UPDATE_DASHBOARD_GROUP,
	DELETE_DASHBOARD_GROUP,
};
