import { all, put, call, takeLatest, select, takeEvery } from 'redux-saga/effects';
import * as actions from 'reduxStore/actions/freeboardActions';
import { apolloQuery, apolloMutation } from '../../util/apollo';
import {
	CREATE_FREEBOARD,
	DELETE_FREEBOARD,
	QUERY_FREEBOARD,
	QUERY_FREEBOARDS,
	UPDATE_FREEBOARD,
} from 'util/apollo/nexpieGraphQL/freeboard';

function* loadFreeboards(action) {
	const { projectId } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_FREEBOARDS, { projectid: projectId }));
		if (errors) throw errors;

		if (data) {
			yield put(actions.loadFreeboards.success(data.freeboardList));
		}
	} catch (error) {
		yield put(actions.loadFreeboards.failure(error));
	}
}
function* loadFreeboard(action) {
	const { freeboardid } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_FREEBOARD, { dashboardid: freeboardid }));
		if (errors) throw errors;

		if (data) {
			yield put(actions.loadFreeboard.success(data.freeboard));
		}
	} catch (error) {
		yield put(actions.loadFreeboard.failure(error));
	}
}

// CRUD
function* createFreeboard(action) {
	const { projectid, freeboard } = action.payload;
	try {
		const { data, errors } = yield call(
			async () => await apolloMutation(CREATE_FREEBOARD, { projectid, ...freeboard })
		);
		if (errors) throw errors;

		if (data) {
			const prevFreeboards = yield select(({ freeboardReducer }) => freeboardReducer.freeboards);
			const newFreeboards = [...prevFreeboards, data.createFreeboard];
			yield put(actions.createFreeboard.success(newFreeboards));
		}
	} catch (error) {
		yield put(actions.createFreeboard.failure(error));
	}
}
function* updateFreeboard(action) {
	const { freeboard } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloMutation(UPDATE_FREEBOARD, { ...freeboard }));
		if (errors) throw errors;

		if (data) {
			const prevFreeboards = yield select(({ freeboardReducer }) => freeboardReducer.freeboards);
			const newFreeboards = prevFreeboards.map((board) =>
				board.dashboardid === freeboard.dashboardid ? data.updateFreeboard : board
			);
			yield put(actions.updateFreeboard.success(newFreeboards));
		}
	} catch (error) {
		yield put(actions.updateFreeboard.failure(error));
	}
}
function* deleteFreeboard(action) {
	const { freeboardid } = action.payload;
	try {
		const { data, errors } = yield call(
			async () => await apolloMutation(DELETE_FREEBOARD, { dashboardid: freeboardid })
		);
		if (errors) throw errors;

		if (data) {
			const prevFreeboards = yield select(({ freeboardReducer }) => freeboardReducer.freeboards);
			const newFreeboards = prevFreeboards.filter((freeboard) => freeboard.dashboardid !== freeboardid);
			yield put(actions.deleteFreeboard.success(newFreeboards));
		}
	} catch (error) {
		yield put(actions.deleteFreeboard.failure(error));
	}
}

export default function* watchFreeboards() {
	yield all([
		takeLatest(actions.LOAD_FREEBOARDS.REQUEST, loadFreeboards),
		takeLatest(actions.LOAD_FREEBOARD.REQUEST, loadFreeboard),
		takeEvery(actions.CREATE_FREEBOARD.REQUEST, createFreeboard),
		takeEvery(actions.UPDATE_FREEBOARD.REQUEST, updateFreeboard),
		takeEvery(actions.DELETE_FREEBOARD.REQUEST, deleteFreeboard),
	]);
}
