import { createTypes, createAction } from 'util/createActionHelper';

const LOAD_FLOWAGENTS = createTypes('get', 'Flowagents');
const LOAD_FLOWAGENT = createTypes('get', 'Flowagent');
const CREATE_FLOWAGENT = createTypes('create', 'Flowagent');
const UPDATE_FLOWAGENT = createTypes('update', 'Flowagent');
const DELETE_FLOWAGENT = createTypes('delete', 'Flowagent');

const loadFlowagents = {
	request: id => createAction(LOAD_FLOWAGENTS.REQUEST, { id }),
	success: flowagents => createAction(LOAD_FLOWAGENTS.SUCCESS, { flowagents }),
	failure: error => createAction(LOAD_FLOWAGENTS.FAILURE, { error }),
};

const loadFlowagent = {
	request: flowagentId => createAction(LOAD_FLOWAGENT.REQUEST, { flowagentId }),
	success: flowagent => createAction(LOAD_FLOWAGENT.SUCCESS, { flowagent }),
	failure: error => createAction(LOAD_FLOWAGENT.FAILURE, { error }),
};

const createFlowagent = {
	request: flowagent => createAction(CREATE_FLOWAGENT.REQUEST, { flowagent }),
	success: flowagent => createAction(CREATE_FLOWAGENT.SUCCESS, { flowagent }),
	failure: error => createAction(CREATE_FLOWAGENT.FAILURE, { error }),
};

const updateFlowagent = {
	request: (flowagentId, flowagent) => createAction(UPDATE_FLOWAGENT.REQUEST, { flowagentId, flowagent }),
	success: flowagent => createAction(UPDATE_FLOWAGENT.SUCCESS, { flowagent }),
	failure: error => createAction(UPDATE_FLOWAGENT.FAILURE, { error }),
};

const deleteFlowagent = {
	request: flowagentId => createAction(DELETE_FLOWAGENT.REQUEST, { flowagentId }),
	success: flowagent => createAction(DELETE_FLOWAGENT.SUCCESS, { flowagent }),
	failure: error => createAction(DELETE_FLOWAGENT.FAILURE, { error }),
};

export {
	LOAD_FLOWAGENTS,
	loadFlowagents,
	LOAD_FLOWAGENT,
	loadFlowagent,
	CREATE_FLOWAGENT,
	createFlowagent,
	UPDATE_FLOWAGENT,
	updateFlowagent,
	DELETE_FLOWAGENT,
	deleteFlowagent,
};
