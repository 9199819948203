import gql from 'graphql-tag';

const DEVICE_GROUP_FRAGMENT = gql`
	fragment deviceGroup on Group {
		groupid
		groupname
		projectid
		createdtime
		description
		tag
		tags
		hashtag
	}
`;

const DEVICE_FRAGMENT = gql`
	fragment deviceInfo on Device {
		alias
		createdtime
		credential {
			secret
		}
		description
		deviceid
		groupid
		groupname
		projectid
		status
		tag
		tags
		enabled
		banned
		hashtag
	}
`;

const QUERY_DEVICE_GROUP_LIST = gql`
	query _deviceGroupList($projectid: String!) {
		groupList(filter: { projectid: $projectid }) {
			...deviceGroup
		}
		deviceList(filter: { projectid: $projectid }) {
			...deviceInfo
		}
	}
	${DEVICE_GROUP_FRAGMENT}
	${DEVICE_FRAGMENT}
`;

const CREATE_DEVICE_GROUP = gql`
	mutation _createGroup($projectid: String!, $groupname: String, $description: String, $tag: String, $hashtag: [String], $tags: JSON) {
		createGroup(projectid: $projectid, groupname: $groupname, description: $description, tag: $tag, hashtag: $hashtag, tags: $tags) {
			...deviceGroup
		}
	}
	${DEVICE_GROUP_FRAGMENT}
`;

const UPDATE_DEVICE_GROUP = gql`
	mutation _updateGroup($groupid: String!, $groupname: String, $description: String, $tag: String, $hashtag: [String], $tags: JSON) {
		updateGroup(groupid: $groupid, groupname: $groupname, description: $description, tag: $tag, hashtag: $hashtag, tags: $tags) {
			...deviceGroup
		}
	}
	${DEVICE_GROUP_FRAGMENT}
`;

const DELETE_DEVICE_GROUP = gql`
	mutation _deleteGroup($groupid: String!) {
		deleteGroup(groupid: $groupid) {
			...deviceGroup
		}
	}
	${DEVICE_GROUP_FRAGMENT}
`;

export { QUERY_DEVICE_GROUP_LIST, CREATE_DEVICE_GROUP, UPDATE_DEVICE_GROUP, DELETE_DEVICE_GROUP };
