/* eslint-disable no-unused-vars */
import { put, takeEvery, delay } from 'redux-saga/effects';
import { AUTHENTICATED, UNAUTHENTICATED, SIGN_OUT_REQUEST } from '../actions/authActions';
import cookie from 'util/cookie';

export function* appInitialize() {
	const access_token = cookie.get('access_token');
	const user_id = cookie.get('user_id');

	if ((access_token && access_token !== undefined && access_token !== 'undefined') || user_id) {
		yield put({ type: AUTHENTICATED });
	} else {
		yield put({ type: SIGN_OUT_REQUEST });
	}
}

const clearCookie = () => {
	const allCookieName = Object.keys(cookie.getAll());
	allCookieName.map((name) => cookie.remove(name, { path: '/' }));
};

function* signOut() {
	yield clearCookie();
	yield delay(500);
	yield put({ type: UNAUTHENTICATED });
	yield delay(500);
}

export function* watchSignOut() {
	yield takeEvery(SIGN_OUT_REQUEST, signOut);
}
