import { createTypes, createAction } from 'util/createActionHelper';

const GET_DATA_TAG = createTypes('get', 'DataTag');
const CREATE_DATA_TAG = createTypes('create', 'DataTag');
const UPDATE_DATA_TAG = createTypes('update', 'DataTag');
const DELETE_DATA_TAG = createTypes('delete', 'DataTag');
const TOGGLE_DATATAG = createTypes('toggle', 'DataTag');

const SEARCH_DATA_TAG = 'SEARCH_DATA_TAG';

const getDataTag = {
	request: (objectid) => createAction(GET_DATA_TAG.REQUEST, { objectid }),
	success: (dataTags) => createAction(GET_DATA_TAG.SUCCESS, { dataTags }),
	failure: (error) => createAction(GET_DATA_TAG.FAILURE, { error }),
};

const createDataTag = {
	request: (objectid, config) => createAction(CREATE_DATA_TAG.REQUEST, { objectid, config }),
	success: (dataTags) => createAction(CREATE_DATA_TAG.SUCCESS, { dataTags }),
	failure: (error) => createAction(CREATE_DATA_TAG.FAILURE, { error }),
};

const updateDataTag = {
	request: (config) => createAction(UPDATE_DATA_TAG.REQUEST, { config }),
	success: (dataTags) => createAction(UPDATE_DATA_TAG.SUCCESS, { dataTags }),
	failure: (error) => createAction(UPDATE_DATA_TAG.FAILURE, { error }),
};

const deleteDataTag = {
	request: (objectid, tagid) => createAction(DELETE_DATA_TAG.REQUEST, { objectid, tagid }),
	success: (dataTags) => createAction(DELETE_DATA_TAG.SUCCESS, { dataTags }),
	failure: (error) => createAction(DELETE_DATA_TAG.FAILURE, { error }),
};

const toggleDataTag = {
	request: (tagid, checked) => createAction(TOGGLE_DATATAG.REQUEST, { tagid, checked }),
	success: (dataTags) => createAction(TOGGLE_DATATAG.SUCCESS, { dataTags }),
	failure: (error) => createAction(TOGGLE_DATATAG.FAILURE, { error }),
};

const searchDataTag = (searchValue) => ({
	type: SEARCH_DATA_TAG,
	searchValue,
});

export {
	getDataTag,
	GET_DATA_TAG,
	createDataTag,
	CREATE_DATA_TAG,
	updateDataTag,
	UPDATE_DATA_TAG,
	deleteDataTag,
	DELETE_DATA_TAG,
	searchDataTag,
	SEARCH_DATA_TAG,
	toggleDataTag,
	TOGGLE_DATATAG,
};
