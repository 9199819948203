import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { routerMiddleware } from 'connected-react-router';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers/rootReducers';
import rootSagas from 'reduxStore/sagas/rootSagas';
import history from 'util/history';
// import { appInitialize } from 'reduxStore/sagas/authSagas';

const configureStore = (initialState) => {
	const sagaMiddleware = createSagaMiddleware();
	const middlewares = [routerMiddleware(history), sagaMiddleware];

	const store = createStore(rootReducer(history), initialState, composeWithDevTools(applyMiddleware(...middlewares)));

	// sagaMiddleware.run(appInitialize);
	sagaMiddleware.run(rootSagas);

	return store;
};

export const store = configureStore();

