import { createTypes, createAction } from 'util/createActionHelper';

const LOAD_PROJECT_PANEL = createTypes('get', 'Panel');
const ENABLE_PANEL = createTypes('enable', 'Panel');
const DISABLE_PANEL = createTypes('disable', 'Panel');
const RESYNC_PANEL_MEMBER = createTypes('update', 'Panel member');
const CREATE_PANEL_DASHBOARD = createTypes('create', 'Panel dashboard');
const UPDATE_PANEL_DASHBOARD = createTypes('update', 'Panel dashboard');
const DELETE_PANEL_DASHBOARD = createTypes('delete', 'Panel dashboard');

const loadProjectPanel = {
	request: (projectid) => createAction(LOAD_PROJECT_PANEL.REQUEST, { projectid }),
	success: (panel) => createAction(LOAD_PROJECT_PANEL.SUCCESS, { panel }),
	failure: (error) => createAction(LOAD_PROJECT_PANEL.FAILURE, { error }),
};

const enableProjectPanel = {
	request: (projectid) => createAction(ENABLE_PANEL.REQUEST, { projectid }),
	success: (panel) => createAction(ENABLE_PANEL.SUCCESS, { panel }),
	failure: (error) => createAction(ENABLE_PANEL.FAILURE, { error }),
};

const disableProjectPanel = {
	request: (projectid) => createAction(DISABLE_PANEL.REQUEST, { projectid }),
	success: (panel) => createAction(DISABLE_PANEL.SUCCESS, { panel }),
	failure: (error) => createAction(DISABLE_PANEL.FAILURE, { error }),
};

const resyncPanelMembership = {
	request: (projectid) => createAction(RESYNC_PANEL_MEMBER.REQUEST, { projectid }),
	success: () => createAction(RESYNC_PANEL_MEMBER.SUCCESS),
	failure: (error) => createAction(RESYNC_PANEL_MEMBER.FAILURE, { error }),
};

const createPanelDashboard = {
	request: (projectid, name) => createAction(CREATE_PANEL_DASHBOARD.REQUEST, { projectid, name }),
	success: (panel) => createAction(CREATE_PANEL_DASHBOARD.SUCCESS, { panel }),
	failure: (error) => createAction(CREATE_PANEL_DASHBOARD.FAILURE, { error }),
};
const updatePanelDashboard = {
	request: (projectid, dashboardid, name) =>
		createAction(UPDATE_PANEL_DASHBOARD.REQUEST, { projectid, dashboardid, name }),
	success: (panel) => createAction(UPDATE_PANEL_DASHBOARD.SUCCESS, { panel }),
	failure: (error) => createAction(UPDATE_PANEL_DASHBOARD.FAILURE, { error }),
};
const deletePanelDashboard = {
	request: (projectid, dashboardid) => createAction(DELETE_PANEL_DASHBOARD.REQUEST, { projectid, dashboardid }),
	success: (panel) => createAction(DELETE_PANEL_DASHBOARD.SUCCESS, { panel }),
	failure: (error) => createAction(DELETE_PANEL_DASHBOARD.FAILURE, { error }),
};

export {
	LOAD_PROJECT_PANEL,
	loadProjectPanel,
	ENABLE_PANEL,
	enableProjectPanel,
	DISABLE_PANEL,
	disableProjectPanel,
	RESYNC_PANEL_MEMBER,
	resyncPanelMembership,
	CREATE_PANEL_DASHBOARD,
	createPanelDashboard,
	UPDATE_PANEL_DASHBOARD,
	updatePanelDashboard,
	DELETE_PANEL_DASHBOARD,
	deletePanelDashboard,
};
