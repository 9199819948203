/* eslint-disable no-unused-vars */
import { all, put, call, select, takeLatest } from 'redux-saga/effects';
import * as actions from '../actions/deviceActions';
import { apolloQuery, apolloMutation } from '../../util/apollo';
import {
	QUERY_DEVICE_LIST,
	QUERY_DEVICE,
	CREATE_DEVICE,
	UPDATE_DEVICE,
	DELETE_DEVICE,
	QUERY_DEVICE_WITH_DEVICE_TOKEN,
	GROUP_DEVICE,
	UNGROUP_DEVICE,
	RESYNC_DEVICE_STATUS,
	ENABLE_DEVICE,
	DISABLE_DEVICE,
} from '../../util/apollo/nexpieGraphQL/device';
import { QUERY_PROJECT } from '../../util/apollo/nexpieGraphQL/project';

function* loadDevices(action) {
	try {
		const { filter } = action.payload;

		// Retrieve project information using the project ID from the filter
		const project = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: filter.projectid }));
		if (project.errors) throw project.errors;

		// Obtain the total number of devices in the project
		const total = project.data.project.numberdevice;

		let devices = [];
		let offset = 0;
		const limit = 100; // Set the limit value

		// Loop until all devices are fetched by incrementing the offset
		while (offset < total) {
			const { data, errors } = yield call(async () => await apolloQuery(QUERY_DEVICE_LIST, { filter, limit, offset }));

			if (errors) {
				throw errors;
			} else {
				const newDevices = devices.concat(data.deviceList);
				devices = newDevices;
			}
			offset += limit; // Increase offset by the limit value
		}

		// Dispatch success action with the loaded devices
		yield put(actions.loadDevices.success(devices));
	} catch (error) {
		// Dispatch failure action if an error occurs during the process
		yield put(actions.loadDevices.failure(error));
	}
}

function* loadDevice(action) {
	try {
		const { deviceId } = action.payload;
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_DEVICE, { deviceid: deviceId }));

		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadDevice.success(data.device));
		}
	} catch (error) {
		yield put(actions.loadDevice.failure(error));
	}
}

function* loadDeviceWithToken(action) {
	try {
		const { deviceId } = action.payload;
		const { data, errors } = yield call(
			async () => await apolloQuery(QUERY_DEVICE_WITH_DEVICE_TOKEN, { deviceid: deviceId })
		);
		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadDeviceWithToken.success(data));
		}
	} catch (error) {
		yield put(actions.loadDeviceWithToken.failure(error));
	}
}

function* createDevice(action) {
	try {
		const { device } = action.payload;
		const mutationRes = yield call(
			async () =>
				await apolloMutation(CREATE_DEVICE, {
					projectid: device.projectid,
					deviceid: device.deviceid,
					deviceinfo: {
						alias: device.alias,
						description: device.description,
						tag: device.tag,
						tags: device.tags,
						hashtag: device.hashtag,
					},
				})
		);

		if (device.groupid && mutationRes.data.createDevice) {
			const mutationGroupDevice = yield call(
				async () =>
					await apolloMutation(GROUP_DEVICE, {
						groupid: device.groupid,
						deviceid: [mutationRes.data.createDevice.deviceid],
					})
			);
			if (mutationGroupDevice.errors) {
				throw mutationGroupDevice.errors;
			}
		}

		//-----device list
		const project = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: device.projectid }));
		if (project.errors) throw project.errors;

		const total = project.data.project.numberdevice;

		let devices = [];
		let offset = 0;
		while (offset < total) {
			const limit = offset + 100;
			const { data, errors } = yield call(
				async () => await apolloQuery(QUERY_DEVICE_LIST, { filter: { projectid: device.projectid }, limit, offset })
			);

			if (errors) {
				throw errors;
			} else {
				const newDevices = devices.concat(data.deviceList);
				devices = newDevices;
			}
			offset = limit;
		}

		if (mutationRes.errors) {
			throw mutationRes.errors;
		} else {
			// const prevDevices = yield select(({ deviceReducer }) => deviceReducer.devices);
			// const newDevices = [...prevDevices, data.createDevice];

			yield put(actions.createDevice.success(devices, mutationRes.data.createDevice));
		}
	} catch (error) {
		yield put(actions.createDevice.failure(error));
	}
}

function* updateDevice(action) {
	try {
		const { projectId, deviceId, device } = action.payload;

		if (device.groupid) {
			const mutationGroupDevice = yield call(
				async () =>
					await apolloMutation(GROUP_DEVICE, {
						groupid: device.groupid,
						deviceid: [deviceId],
					})
			);
			if (mutationGroupDevice.errors) {
				throw mutationGroupDevice.errors;
			}
		} else {
			const mutationUnGroupDevice = yield call(
				async () =>
					await apolloMutation(UNGROUP_DEVICE, {
						deviceid: [deviceId],
					})
			);
			if (mutationUnGroupDevice.errors) {
				throw mutationUnGroupDevice.errors;
			}
		}

		const mutationRes = yield call(
			async () =>
				await apolloMutation(UPDATE_DEVICE, {
					deviceid: deviceId,
					deviceinfo: {
						alias: device.alias,
						description: device.description,
						tag: device.tag,
						tags: device.tags,
						hashtag: device.hashtag,
					},
				})
		);

		//-----device list
		const project = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: projectId }));
		if (project.errors) throw project.errors;

		const total = project.data.project.numberdevice;

		let devices = [];
		let offset = 0;
		while (offset < total) {
			const limit = offset + 100;
			const { data, errors } = yield call(
				async () => await apolloQuery(QUERY_DEVICE_LIST, { filter: { projectid: projectId }, limit, offset })
			);

			if (errors) {
				throw errors;
			} else {
				const newDevices = devices.concat(data.deviceList);
				devices = newDevices;
			}
			offset = limit;
		}
		const oldGroupId = device.oldGroupId ? device.oldGroupId : null;
		const devicesIngroup = devices.filter((device) => device.groupid === oldGroupId);

		// --- device ---
		const prevDevice = yield select(({ deviceReducer }) => deviceReducer.device);
		const UPDATED_DEVICE = { ...prevDevice, ...device };

		if (mutationRes.errors) {
			throw mutationRes.errors;
		} else {
			yield put(actions.updateDevice.success(devices, UPDATED_DEVICE, devicesIngroup));
		}
	} catch (error) {
		yield put(actions.updateDevice.failure(error));
	}
}

function* deleteDevice(action) {
	try {
		const { projectId, deviceId, groupId } = action.payload;

		const mutationRes = yield call(async () => await apolloMutation(DELETE_DEVICE, { deviceid: deviceId, groupId }));

		//-----device list
		const project = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: projectId }));
		if (project.errors) throw project.errors;

		const total = project.data.project.numberdevice;

		let devices = [];
		let offset = 0;
		while (offset < total) {
			const limit = offset + 100;
			const { data, errors } = yield call(
				async () => await apolloQuery(QUERY_DEVICE_LIST, { filter: { projectid: projectId }, limit, offset })
			);

			if (errors) {
				throw errors;
			} else {
				const newDevices = devices.concat(data.deviceList);
				devices = newDevices;
			}
			offset = limit;
		}
		const devicesIngroup = devices.filter((device) => device.groupid === groupId);

		if (mutationRes.errors) {
			throw mutationRes.errors;
		} else {
			yield put(actions.deleteDevice.success(devices, mutationRes.data.deleteDevice, devicesIngroup));
		}
	} catch (error) {
		yield put(actions.deleteDevice.failure(error));
	}
}

function* moveDevice(action) {
	try {
		const { projectId, deviceList, groupId, oldGroupId } = action.payload;
		const mutationRes = yield call(
			async () => await apolloMutation(GROUP_DEVICE, { groupid: groupId, deviceid: deviceList })
		);

		const project = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: projectId }));
		if (project.errors) throw project.errors;

		const total = project.data.project.numberdevice;

		let devices = [];
		let offset = 0;
		while (offset < total) {
			const limit = offset + 100;
			const { data, errors } = yield call(
				async () => await apolloQuery(QUERY_DEVICE_LIST, { filter: { projectid: projectId }, limit, offset })
			);

			if (errors) {
				throw errors;
			} else {
				const newDevices = devices.concat(data.deviceList);
				devices = newDevices;
			}
			offset = limit;
		}
		const devicesIngroup = devices.filter((device) => device.groupid === oldGroupId);

		if (mutationRes.errors) {
			throw mutationRes.errors;
		} else {
			yield put(actions.moveDevice.success(devices, devicesIngroup));
		}
	} catch (error) {
		yield put(actions.moveDevice.failure(error));
	}
}

function* ungroupDevice(action) {
	try {
		const { projectId, deviceList, groupId } = action.payload;
		const mutationRes = yield call(async () => await apolloMutation(UNGROUP_DEVICE, { deviceid: deviceList }));

		const project = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: projectId }));
		if (project.errors) throw project.errors;

		const total = project.data.project.numberdevice;

		let devices = [];
		let offset = 0;
		while (offset < total) {
			const limit = offset + 100;
			const { data, errors } = yield call(
				async () => await apolloQuery(QUERY_DEVICE_LIST, { filter: { projectid: projectId }, limit, offset })
			);

			if (errors) {
				throw errors;
			} else {
				const newDevices = devices.concat(data.deviceList);
				devices = newDevices;
			}
			offset = limit;
		}
		const devicesIngroup = devices.filter((device) => device.groupid === groupId);

		if (mutationRes.errors) {
			throw mutationRes.errors;
		} else {
			yield put(actions.ungroupDevice.success(devices, devicesIngroup));
		}
	} catch (error) {
		yield put(actions.ungroupDevice.failure(error));
	}
}

function* manageDeviceGroup(action) {
	try {
		const { projectId, queueList, lastDevicesInGroup, groupId } = action.payload;
		const groupDevices = queueList.filter((item) => item.action === 'group').map((item) => item.deviceid);
		const ungroupDevices = [];
		queueList
			.filter((item) => item.action === 'ungroup')
			.map((item) => {
				if (lastDevicesInGroup.find((device) => device.deviceid === item.deviceid)) {
					ungroupDevices.push(item.deviceid);
				}
			});

		if (groupDevices?.length > 0) {
			const mutationResGroup = yield call(
				async () => await apolloMutation(GROUP_DEVICE, { groupid: groupId, deviceid: groupDevices })
			);
			if (mutationResGroup.errors) {
				throw mutationResGroup.errors;
			}
		}
		if (ungroupDevices?.length > 0) {
			const mutationResUnGroup = yield call(
				async () => await apolloMutation(UNGROUP_DEVICE, { deviceid: ungroupDevices })
			);
			if (mutationResUnGroup.errors) {
				throw mutationResUnGroup.errors;
			}
		}

		const project = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: projectId }));
		if (project.errors) throw project.errors;

		const total = project.data.project.numberdevice;

		let devices = [];
		let offset = 0;
		while (offset < total) {
			const limit = offset + 100;
			const { data, errors } = yield call(
				async () => await apolloQuery(QUERY_DEVICE_LIST, { filter: { projectid: projectId }, limit, offset })
			);

			if (errors) {
				throw errors;
			} else {
				const newDevices = devices.concat(data.deviceList);
				devices = newDevices;
			}
			offset = limit;
		}
		const devicesIngroup = devices.filter((device) => device.groupid === groupId);

		yield put(actions.manageDeviceGroup.success(devices, devicesIngroup));
	} catch (error) {
		yield put(actions.manageDeviceGroup.failure(error));
	}
}

function* loadDevicesByGroup(action) {
	try {
		const { projectId, groupId } = action.payload;

		const project = yield call(async () => await apolloQuery(QUERY_PROJECT, { projectid: projectId }));
		if (project.errors) throw project.errors;

		const total = project.data.project.numberdevice;

		let devices = [];
		let offset = 0;
		while (offset < total) {
			const limit = offset + 100;
			const { data, errors } = yield call(
				async () => await apolloQuery(QUERY_DEVICE_LIST, { filter: { projectid: projectId }, limit, offset })
			);

			if (errors) {
				throw errors;
			} else {
				const newDevices = devices.concat(data.deviceList);
				devices = newDevices;
			}
			offset = limit;
		}
		const devicesIngroup = devices.filter((device) => device.groupid === groupId);
		yield put(actions.loadDevicesByGroup.success(devices, devicesIngroup));
	} catch (error) {
		yield put(actions.loadDevicesByGroup.failure(error));
	}
}

function* resyncDeviceStatus(action) {
	try {
		// Extracting deviceId from action payload
		const { deviceId } = action.payload;

		// Performing mutation to resync device status
		const resyncResult = yield call(async () => await apolloMutation(RESYNC_DEVICE_STATUS, { deviceid: deviceId }));
		const { errors, data } = resyncResult;

		// Handling mutation errors
		if (errors) {
			throw errors;
		}

		// If resync successful, query device
		if (data.resyncDeviceStatus.code === 200) {
			const statusResult = yield call(async () => await apolloQuery(QUERY_DEVICE, { deviceid: deviceId }));

			// Handling query errors
			if (statusResult.errors) {
				throw statusResult.errors;
			}

			// Dispatching success action with device
			yield put(actions.resyncDeviceStatus.success(statusResult.data.device));
		}
	} catch (error) {
		// Dispatching failure action with error
		yield put(actions.resyncDeviceStatus.failure(error));
	}
}

function* enableDevice(action) {
	try {
		const { deviceId } = action.payload;

		const { errors, data } = yield call(async () => await apolloMutation(ENABLE_DEVICE, { deviceid: deviceId }));

		if (errors) throw errors;
		if (data.enableDevice) {
			yield put(actions.enableDevice.success(data.enableDevice));
		} else {
			yield put(actions.enableDevice.failure({ message: 'Something wrong' }));
		}
	} catch (error) {
		yield put(actions.enableDevice.failure(error));
	}
}

function* disableDevice(action) {
	try {
		const { deviceId } = action.payload;

		const { errors, data } = yield call(async () => await apolloMutation(DISABLE_DEVICE, { deviceid: deviceId }));

		if (errors) throw errors;
		if (data.disableDevice) {
			yield put(actions.disableDevice.success(data.disableDevice));
		} else {
			yield put(actions.disableDevice.failure({ message: 'Something wrong' }));
		}
	} catch (error) {
		yield put(actions.disableDevice.failure(error));
	}
}

export default function* watchDevices() {
	yield all([
		takeLatest(actions.LOAD_DEVICES.REQUEST, loadDevices),
		takeLatest(actions.LOAD_DEVICE.REQUEST, loadDevice),
		takeLatest(actions.LOAD_DEVICE_TOKEN.REQUEST, loadDeviceWithToken),
		takeLatest(actions.CREATE_DEVICE.REQUEST, createDevice),
		takeLatest(actions.UPDATE_DEVICE.REQUEST, updateDevice),
		takeLatest(actions.DELETE_DEVICE.REQUEST, deleteDevice),
		takeLatest(actions.MOVE_DEVICE.REQUEST, moveDevice),
		takeLatest(actions.LOAD_DEVICES_BY_GROUP.REQUEST, loadDevicesByGroup),
		takeLatest(actions.UNGROUP_DEVICE.REQUEST, ungroupDevice),
		takeLatest(actions.MANAGE_DEVICE_GROUP.REQUEST, manageDeviceGroup),
		takeLatest(actions.RESYNC_DEVICE_STATUS.REQUEST, resyncDeviceStatus),
		takeLatest(actions.ENABLE_DEVICE.REQUEST, enableDevice),
		takeLatest(actions.DISABLE_DEVICE.REQUEST, disableDevice),
	]);
}
