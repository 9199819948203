import { createTypes, createAction } from 'util/createActionHelper';

const LOAD_DEVICES = createTypes('get', 'Devices');
const LOAD_DEVICE = createTypes('get', 'Devicee');
const LOAD_DEVICE_TOKEN = createTypes('get', 'device');
const CREATE_DEVICE = createTypes('create', 'Device');
const UPDATE_DEVICE = createTypes('update', 'Device');
const DELETE_DEVICE = createTypes('delete', 'Device');
const MOVE_DEVICE = createTypes('move', 'Device');
const UNGROUP_DEVICE = createTypes('ungroup', 'Device');
const MANAGE_DEVICE_GROUP = createTypes('manage group', 'Device');
const LOAD_DEVICES_BY_GROUP = createTypes('get', 'DevicesByGroup');
const RESYNC_DEVICE_STATUS = createTypes('resync', 'Status');
const ENABLE_DEVICE = createTypes('enable', 'Device');
const DISABLE_DEVICE = createTypes('disable', 'Device');

const loadDevices = {
	request: (filter) => createAction(LOAD_DEVICES.REQUEST, { filter }),
	success: (devices) => createAction(LOAD_DEVICES.SUCCESS, { devices }),
	failure: (error) => createAction(LOAD_DEVICES.FAILURE, { error }),
};

const loadDevice = {
	request: (deviceId) => createAction(LOAD_DEVICE.REQUEST, { deviceId }),
	success: (device) => createAction(LOAD_DEVICE.SUCCESS, { device }),
	failure: (error) => createAction(LOAD_DEVICE.FAILURE, { error }),
};

const loadDeviceWithToken = {
	request: (deviceId) => createAction(LOAD_DEVICE_TOKEN.REQUEST, { deviceId }),
	success: (device) => createAction(LOAD_DEVICE_TOKEN.SUCCESS, { device }),
	failure: (error) => createAction(LOAD_DEVICE_TOKEN.FAILURE, { error }),
};

const createDevice = {
	request: (device) => createAction(CREATE_DEVICE.REQUEST, { device }),
	success: (devices, device) => createAction(CREATE_DEVICE.SUCCESS, { devices, device }),
	failure: (error) => createAction(CREATE_DEVICE.FAILURE, { error }),
};

const updateDevice = {
	request: (projectId, deviceId, device) => createAction(UPDATE_DEVICE.REQUEST, { projectId, deviceId, device }),
	success: (devices, device, devicesInGroup) =>
		createAction(UPDATE_DEVICE.SUCCESS, { devices, device, devicesInGroup }),
	failure: (error) => createAction(UPDATE_DEVICE.FAILURE, { error }),
};

const deleteDevice = {
	request: (projectId, deviceId, groupId) => createAction(DELETE_DEVICE.REQUEST, { projectId, deviceId, groupId }),
	success: (devices, device, devicesInGroup) =>
		createAction(DELETE_DEVICE.SUCCESS, { devices, device, devicesInGroup }),
	failure: (error) => createAction(DELETE_DEVICE.FAILURE, { error }),
};

const loadDevicesByGroup = {
	request: (projectId, groupId) => createAction(LOAD_DEVICES_BY_GROUP.REQUEST, { projectId, groupId }),
	success: (devices, devicesInGroup) => createAction(LOAD_DEVICES_BY_GROUP.SUCCESS, { devices, devicesInGroup }),
	failure: (error) => createAction(LOAD_DEVICES_BY_GROUP.FAILURE, { error }),
};

const moveDevice = {
	request: (projectId, deviceList, groupId, oldGroupId) =>
		createAction(MOVE_DEVICE.REQUEST, { projectId, deviceList, groupId, oldGroupId }),
	success: (devices, devicesInGroup) => createAction(MOVE_DEVICE.SUCCESS, { devices, devicesInGroup }),
	failure: (error) => createAction(MOVE_DEVICE.FAILURE, { error }),
};

const ungroupDevice = {
	request: (projectId, deviceList, groupId) => createAction(UNGROUP_DEVICE.REQUEST, { projectId, deviceList, groupId }),
	success: (devices, devicesInGroup) => createAction(UNGROUP_DEVICE.SUCCESS, { devices, devicesInGroup }),
	failure: (error) => createAction(UNGROUP_DEVICE.FAILURE, { error }),
};

const manageDeviceGroup = {
	request: (projectId, queueList, lastDevicesInGroup, groupId) =>
		createAction(MANAGE_DEVICE_GROUP.REQUEST, { projectId, queueList, lastDevicesInGroup, groupId }),
	success: (devices, devicesInGroup) => createAction(MANAGE_DEVICE_GROUP.SUCCESS, { devices, devicesInGroup }),
	failure: (error) => createAction(MANAGE_DEVICE_GROUP.FAILURE, { error }),
};

const resyncDeviceStatus = {
	request: (deviceId) => createAction(RESYNC_DEVICE_STATUS.REQUEST, { deviceId }),
	success: (device) => createAction(RESYNC_DEVICE_STATUS.SUCCESS, { device }),
	failure: (error) => createAction(RESYNC_DEVICE_STATUS.FAILURE, { error }),
};

const enableDevice = {
	request: (deviceId) => createAction(ENABLE_DEVICE.REQUEST, { deviceId }),
	success: (device) => createAction(ENABLE_DEVICE.SUCCESS, { device }),
	failure: (error) => createAction(ENABLE_DEVICE.FAILURE, { error }),
};

const disableDevice = {
	request: (deviceId) => createAction(DISABLE_DEVICE.REQUEST, { deviceId }),
	success: (device) => createAction(DISABLE_DEVICE.SUCCESS, { device }),
	failure: (error) => createAction(DISABLE_DEVICE.FAILURE, { error }),
};

export {
	LOAD_DEVICES,
	loadDevices,
	LOAD_DEVICE,
	loadDevice,
	LOAD_DEVICE_TOKEN,
	loadDeviceWithToken,
	CREATE_DEVICE,
	createDevice,
	UPDATE_DEVICE,
	updateDevice,
	DELETE_DEVICE,
	deleteDevice,
	MOVE_DEVICE,
	moveDevice,
	LOAD_DEVICES_BY_GROUP,
	loadDevicesByGroup,
	UNGROUP_DEVICE,
	ungroupDevice,
	MANAGE_DEVICE_GROUP,
	manageDeviceGroup,
	RESYNC_DEVICE_STATUS,
	resyncDeviceStatus,
	enableDevice,
	ENABLE_DEVICE,
	disableDevice,
	DISABLE_DEVICE,
};
