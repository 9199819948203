import { LOAD_BILLING_LOG } from '../actions/logActions';
import { shapeMessage } from 'util/openNotification';

const initialState = {
	log: [],
	loading: false,
	message: null,
};

export default (state = initialState, action) => {
	switch (action.type) {
		case LOAD_BILLING_LOG.REQUEST:
			return {
				...state,
				loading: true,
			};
		case LOAD_BILLING_LOG.SUCCESS:
			return {
				...state,
				log: action.payload.billingLog,
				message: null,
				loading: false,
			};
		case LOAD_BILLING_LOG.FAILURE:
			return {
				...state,
                log: [],
				message: shapeMessage(action.type, action.payload.error),
				loading: false,
			};

		default:
			return state;
	}
};
