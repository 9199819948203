/* eslint-disable no-unused-vars */
import { all, put, call, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import * as actions from '../actions/billingAccountActions';
import { apolloMutation, apolloQuery } from 'util/apollo';
import {
	QUERY_MY_BILLING_ACCOUNT,
	QUERY_PROJECT_BILLING_USAGE,
	QUERY_BILLING_ACCOUNT_RESOURCE_USAGE_PROFILE,
	QUERY_NETPIE_BILLING_ACCOUNT_RESOURCE_USAGE_PROFILE,
	QUERY_BILLING_METRIC_USAGE_PROFILE,
	QUERY_BILLING_ACCOUNT_SUBSCRIBE_PROFILE,
	QUERY_NON_STARTED_BILLING,
	START_BILLING_SUBSCRIPTION,
} from 'util/apollo/nexpieGraphQL/billingAccount';

function* loadBillingSubscriptionList(action) {
	try {
		const { billingidList } = action.payload;

		const results = yield all(
			billingidList?.map(function* (billing) {
				const queryProfile = yield call(
					async () =>
						await apolloQuery(QUERY_BILLING_ACCOUNT_SUBSCRIBE_PROFILE, { billingaccountid: billing.billingaccountid })
				);
				const bill = queryProfile.data.BillingAccountSubscriptionProfile;
				const cycleLeft = bill.total_cycle - bill.current_cycle;
				const expired =
					cycleLeft <= 0 && dayjs(bill.current_cycle_end_at).diff(dayjs()) < 0
						? 0
						: dayjs().to(dayjs(bill.current_cycle_end_at), true);

				const data = { ...queryProfile.data.BillingAccountSubscriptionProfile, cycleLeft, expired };
				return data;
			})
		);

		yield put(actions.loadBillingSubscriptionList.success(results));
	} catch (error) {
		console.log('err  ' + error);
		yield put(actions.loadBillingSubscriptionList.failure(error));
	}
}

function* loadBillingAccount(action) {
	try {
		const { billingid } = action.payload;
		let billingaccountid = billingid;
		console.log('billingaccountid', billingaccountid);

		if (!billingid) {
			const queryBilling = yield call(async () => await apolloQuery(QUERY_MY_BILLING_ACCOUNT));
			const billing = queryBilling.data.MyBillingAccount[0];
			billingaccountid = billing.billingaccountid;

			if (queryBilling.errors) {
				throw queryBilling.errors;
			}
		}

		const Q =
			`${window._env_.PLATFORM_NAME}`.toLowerCase() === 'netpie'
				? QUERY_NETPIE_BILLING_ACCOUNT_RESOURCE_USAGE_PROFILE
				: QUERY_BILLING_ACCOUNT_RESOURCE_USAGE_PROFILE;

		const queryResource = yield call(
			async () =>
				await apolloQuery(Q, {
					billingaccountid: billingaccountid,
				})
		);
		const queryMetric = yield call(
			async () => await apolloQuery(QUERY_BILLING_METRIC_USAGE_PROFILE, { billingaccountid: billingaccountid })
		);
		const querySubscription = yield call(
			async () => await apolloQuery(QUERY_BILLING_ACCOUNT_SUBSCRIBE_PROFILE, { billingaccountid: billingaccountid })
		);

		if (queryResource.errors || queryMetric.errors || querySubscription.errors) {
			throw queryResource.errors || queryMetric.errors || querySubscription.errors;
		}

		const { BillingAccountResourceUsageProfile } = queryResource.data;
		const { BillingMetricUsageProfile } = queryMetric.data;
		const { BillingAccountSubscriptionProfile } = querySubscription.data;

		if (!BillingAccountResourceUsageProfile.billingaccountid || !BillingMetricUsageProfile.billingaccountid) {
			// throw { message: 'not found' };
			throw new Error('not found');
		}

		const DATA = {
			billingaccountid: billingaccountid,
			billingname: billingaccountid,
			subscriptionData: BillingAccountSubscriptionProfile,
			valid: BillingAccountSubscriptionProfile?.current_cycle_end_at,
			package: BillingAccountSubscriptionProfile?.packagename,
			resourceUsage: [
				{
					label: 'Project',
					suffix: 'projects',
					limit: BillingAccountResourceUsageProfile.max_project_per_billing,
					value: BillingAccountResourceUsageProfile.use_project,
				},
				{
					label: 'Device',
					suffix: 'devices',
					limit: BillingAccountResourceUsageProfile.max_device_per_billing,
					value: BillingAccountResourceUsageProfile.use_device,
				},
				{
					label: 'Dashboard',
					suffix: 'dashboards',
					limit: BillingAccountResourceUsageProfile.max_dashboard_per_billing,
					limit_project: BillingAccountResourceUsageProfile.max_dashboard_per_project,
					value: BillingAccountResourceUsageProfile.use_dashboard,
				},
				{
					label: 'Datatag',
					suffix: 'tags',
					limit: BillingAccountResourceUsageProfile.max_datatag_per_billing,
					limit_project: BillingAccountResourceUsageProfile.max_datatag_per_project,
					limit_device: BillingAccountResourceUsageProfile.max_datatag_per_device,
					value: BillingAccountResourceUsageProfile.use_datatag,
				},
			],
			metricUsage: [
				{
					label: 'Real-time messages',
					suffix: 'messages per month',
					limit: BillingMetricUsageProfile.max_message,
					value: BillingMetricUsageProfile.use_message,
				},
				{
					label: 'Time-series data storage',
					suffix: 'point month',
					limit: BillingMetricUsageProfile.max_store,
					value: BillingMetricUsageProfile.use_store,
				},
				{
					label: 'Shadow read/write',
					suffix: 'operations per month',
					limit: BillingMetricUsageProfile.max_shadowops,
					value: BillingMetricUsageProfile.use_shadowops,
				},
				{
					label: 'API call',
					suffix: 'operations per month',
					limit: BillingMetricUsageProfile.max_apicall,
					value: BillingMetricUsageProfile.use_apicall,
				},
				{
					label: 'Trigger and action',
					suffix: 'operations per month',
					limit: BillingMetricUsageProfile.max_trigger,
					value: BillingMetricUsageProfile.use_trigger,
				},
				{
					label: 'Datasources',
					suffix: 'byte per month',
					limit: BillingMetricUsageProfile.max_datasource,
					value: BillingMetricUsageProfile.use_datasource,
				},
			],
		};
		console.log('billing data', DATA);

		yield put(actions.loadBillingAccount.success(DATA));
	} catch (error) {
		console.log('loadBillingAccount error', error);
		yield put(actions.loadBillingAccount.failure(error));
	}
}

function* loadBillingAccountList() {
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_MY_BILLING_ACCOUNT));

		if (errors) {
			throw errors;
		} else {
			yield put(actions.loadBillingAccountList.success(data.MyBillingAccount));
		}
	} catch (error) {
		yield put(actions.loadBillingAccountList.failure(error));
	}
}

function* loadProjectBillingAccount(action) {
	try {
		if (!action.payload.projectid) {
			yield put(actions.loadProjectBillingAccount.success(null));
		} else {
			const queryUsagePage = yield call(
				async () => await apolloQuery(QUERY_PROJECT_BILLING_USAGE, { projectid: action.payload.projectid })
			);
			const { ProjectBillingAccountSubscriptionProfile, ProjectBillingMetricUsageProfile } = queryUsagePage.data;

			const cycleLeft =
				ProjectBillingAccountSubscriptionProfile.total_cycle - ProjectBillingAccountSubscriptionProfile.current_cycle;
			const expired =
				cycleLeft <= 0 && dayjs(ProjectBillingAccountSubscriptionProfile.current_cycle_end_at).diff(dayjs()) < 0
					? 0
					: dayjs().to(dayjs(ProjectBillingAccountSubscriptionProfile.current_cycle_end_at), true);

			const DATA = {
				startBillDate: ProjectBillingAccountSubscriptionProfile.current_cycle_start_at,
				endBillDate: ProjectBillingAccountSubscriptionProfile.current_cycle_end_at,
				package: ProjectBillingAccountSubscriptionProfile.packagename,
				cycleLeft,
				expired,

				ProjectBillingMetricUsageProfile,
			};
			if (queryUsagePage.errors) {
				throw queryUsagePage.errors;
			} else {
				yield put(actions.loadProjectBillingAccount.success(DATA));
			}
		}
	} catch (error) {
		yield put(actions.loadProjectBillingAccount.failure(error));
	}
}

// Handle non-started billing subscription
function* getNonStartedBilling(action) {
	const { billingid } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloQuery(QUERY_NON_STARTED_BILLING, { billingid }));

		if (errors) throw errors;
		if (data) yield put(actions.loadNonStartedBilling.success(data.getNonStartedBillingSubscription));
	} catch (error) {
		yield put(actions.loadNonStartedBilling.failure(error));
	}
}
function* startBillingSubscription(action) {
	const { billingid } = action.payload;
	try {
		const { data, errors } = yield call(async () => await apolloMutation(START_BILLING_SUBSCRIPTION, { billingid }));

		if (errors) throw errors;
		if (data) yield put(actions.startBillingSubscription.success(data.startBillingSubscription));
	} catch (error) {
		yield put(actions.startBillingSubscription.failure(error));
	}
}

export default function* watchBillingAccount() {
	yield all([
		takeLatest(actions.LOAD_BILLING_ACCOUNT.REQUEST, loadBillingAccount),
		takeLatest(actions.LOAD_BILLING_ACCOUNT_LIST.REQUEST, loadBillingAccountList),
		takeLatest(actions.LOAD_PROJECT_BILLING_ACCOUNT.REQUEST, loadProjectBillingAccount),
		takeLatest(actions.LOAD_BILLING_SUBSCRIPTION_LIST.REQUEST, loadBillingSubscriptionList),
		takeLatest(actions.GET_NON_STARTED_BILLING.REQUEST, getNonStartedBilling),
		takeLatest(actions.START_BILLING_SUBSCRIPTION.REQUEST, startBillingSubscription),
	]);
}
