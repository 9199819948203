import React from 'react';
import { Spin } from 'antd';

const AppLoadingPage = ({ children }) => {
	return (
		<React.Suspense
			fallback={
				<div className="centerFullScreen">
					<Spin size="large" delay={1000} />
				</div>
			}
		>
			{children}
		</React.Suspense>
	);
};

export default AppLoadingPage;
